import { IdsTable, IdsTableRow, IdsTableCell, IdsCheckbox, IdsText } from "@emergn-infinity/ids-react";

import { EndUse } from "types";

export const EndUsesTable: React.FC<{
    activeEndUse: EndUse | null;
    selectedEndUses: EndUse[];
    standardizingList: EndUse[];
    onClick: (endUse: EndUse) => void;
    onChange: (endUse: EndUse) => void;
}> = ({ activeEndUse, selectedEndUses, standardizingList, onClick, onChange }) => {
    return (
        <IdsTable spacing="lg">
            {standardizingList.map((endUse) => (
                <IdsTableRow key={`${endUse.endUseNumber}`}>
                    <IdsTableCell
                        style={{
                            backgroundColor:
                                activeEndUse?.endUseNumber === endUse.endUseNumber
                                    ? "var(--ids-table-cell-body-background-color-clear-hover)"
                                    : undefined,
                        }}
                        onClick={() => onClick(endUse)}
                    >
                        <div className="flex-row align-center">
                            <div>
                                <IdsCheckbox
                                    idValue={endUse.endUseNumber}
                                    defaultChecked={selectedEndUses.find((eu) => eu.endUseNumber === endUse.endUseNumber) !== undefined}
                                    changeHandler={() => onChange(endUse)}
                                />
                            </div>
                            <div className="pl-4">
                                <IdsText weight="bold">{`${endUse.trmFamiliarName} > ${endUse.endUseName}`}</IdsText>
                                <IdsText>{`Maps to: ${endUse.sugStdEndUse ? endUse.sugStdEndUse : ""}`}</IdsText>
                            </div>
                        </div>
                    </IdsTableCell>
                </IdsTableRow>
            ))}
        </IdsTable>
    );
};
