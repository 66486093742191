import { isEmpty } from "lodash";

import { IdsDropdown } from "@emergn-infinity/ids-react";

// NOTE: If stdVintagesList on initial render in an empty array
// initialSelectedItems will be set, but will not show up
// in the dropdown field.
export const StandardVintage: React.FC<{
    stdVintageNumber: string;
    stdVintagesList: {
        label: string;
        value: string;
    }[];
    onSelect: (value: string) => void;
}> = ({ stdVintageNumber, stdVintagesList, onSelect }) => {
    return (
        <IdsDropdown
            idValue="select-standard-vintage"
            isSearchable
            items={stdVintagesList}
            placeholder="Select Standard Vintage"
            initialSelectedItems={isEmpty(stdVintageNumber) ? null : [stdVintageNumber]}
            changeHandler={onSelect}
            clearHandler={() => onSelect("")}
        />
    );
};
