import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useGetSavingsBenchmarksQuery } from "store/apiSlice";

import { IdsTable } from "@emergn-infinity/ids-react";

import { SavingsCalculationsGridHeader } from "./SavingsCalculationsGridHeader";
import { SavingsCalculationsGridRows } from "./SavingsCalculationsGridRows";

import { IndexSignature, RelevantAlgorithm, SavingsBenchmark } from "types";

const defaultStyles: GridStyle = {
    variableDescription: {
        width: "11.25rem",
    },
    relAlgorithm: {
        minWidth: "18rem",
    },
};

export const SavingsCalculationsGrid: React.FC<{
    selectedTrmName: string;
    selectedMeasureName: string;
    items: RelevantAlgorithm[];
}> = ({ selectedTrmName, selectedMeasureName, items }) => {
    const relAlgorithmNumbers = items.map((item) => item.algorithmNumber);
    const trmsAndMeasures = items.map((item) => ({ trmFamiliarName: item.trmFamiliarName, measureName: item.measure }));

    const { data: savingsBenchmarks } = useGetSavingsBenchmarksQuery({ relevantAlgorithmNumbers: relAlgorithmNumbers });

    const headers = useMemo(() => {
        const headers: Header[] = [];

        if (savingsBenchmarks !== undefined) {
            trmsAndMeasures.forEach((trmAndMeasure) => {
                const formattedAlgorithm =
                    savingsBenchmarks.find(
                        (savings) =>
                            savings.trmFamiliarName === trmAndMeasure.trmFamiliarName && savings.measureName === trmAndMeasure.measureName
                    )?.formattedAlgorithm ?? "";

                headers.push({
                    trmFamiliarName: trmAndMeasure.trmFamiliarName,
                    measureName: trmAndMeasure.measureName,
                    formattedAlgorithm,
                });
            });
        }

        return headers;
    }, [savingsBenchmarks, trmsAndMeasures]);

    // Row items are sorted alphabetically, but first items
    // are savings related to selected TRM and measure.
    //
    // Following structure:
    //
    //  {
    //      "Quantity installed": {
    //          "Arkansas Statewide TRM V9.1 (2022)-Electric Vehicle Charging Systems (EV Chargers)": {
    //              formattedAlgorithm: "kWh = <DeemedSavingsValue> * <Chargers>",
    //              ...
    //          },
    //      },
    //      ...
    //  }
    const rowItems = useMemo(() => {
        let rowItems: IndexSignature<IndexSignature<SavingsBenchmark>> = {};

        if (savingsBenchmarks !== undefined) {
            const sortedSavingsByVar = [...savingsBenchmarks].sort((a, b) => a.displayVarAs.localeCompare(b.displayVarAs));

            const selectedSavings = sortedSavingsByVar.filter(
                (savings) => savings.trmFamiliarName === selectedTrmName && savings.measureName === selectedMeasureName
            );

            const relevantSavings = sortedSavingsByVar.filter(
                (savings) => savings.trmFamiliarName !== selectedTrmName || savings.measureName !== selectedMeasureName
            );

            const sortedSavings = selectedSavings.concat(relevantSavings);

            rowItems = sortedSavings.reduce((acc, cv) => {
                if (acc === undefined || acc?.[cv.displayVarAs] === undefined) {
                    const newAccumulator = { ...acc };

                    newAccumulator[cv.displayVarAs] = {};

                    // NOTE: TRMs and measures can be the same,
                    // so using index here to make each column unique
                    trmsAndMeasures.forEach((trmAndMeasure, index) => {
                        newAccumulator[cv.displayVarAs][`${trmAndMeasure.trmFamiliarName}-${trmAndMeasure.measureName}-${index}`] = {};
                    });

                    acc = newAccumulator;
                }

                for (let index = 0; index < trmsAndMeasures.length; index++) {
                    const trmAndMeasure = trmsAndMeasures[index];

                    if (trmAndMeasure.trmFamiliarName === cv.trmFamiliarName && trmAndMeasure.measureName === cv.measureName) {
                        acc[cv.displayVarAs][`${trmAndMeasure.trmFamiliarName}-${trmAndMeasure.measureName}-${index}`] = cv;

                        break;
                    }
                }

                return acc;
            }, {});
        }

        return rowItems;
    }, [selectedTrmName, selectedMeasureName, savingsBenchmarks, trmsAndMeasures]);

    return (
        <IdsTable key={`savings-calculations-${items.length}`} variant="alternate" spacing="sm">
            <SavingsCalculationsGridHeader headers={headers} styles={defaultStyles} />
            {!isEmpty(rowItems) && <SavingsCalculationsGridRows rowItems={rowItems} styles={defaultStyles} />}
        </IdsTable>
    );
};

export type Header = {
    trmFamiliarName: string;
    measureName: string;
    formattedAlgorithm: string;
};

export type GridStyle = {
    variableDescription?: React.CSSProperties;
    relAlgorithm?: React.CSSProperties;
};
