import { memo } from "react";

import Copyright from "components/Copyright";

import { FormWrapProps } from "./types";

import "./style.scss";

const FormWrap = memo(({ children }: FormWrapProps) => {
    return (
        <div className="with-scroll fill-height form-wrap">
            <div className="flex-column align-center fill-width form-wrap__wrapper">
                <div className="flex-column justify-center form-wrap__main-container">
                    <div>
                        <div className="logo" />
                        <div>{children}</div>
                    </div>
                </div>
                <div className="form-wrap__copyright-container">
                    <Copyright />
                </div>
            </div>
        </div>
    );
});

export default FormWrap;
