import {
    WINDOW_CONTAINER_ADD,
    WINDOW_CONTAINER_REMOVE,
    WINDOW_ADD,
    WINDOW_UPDATE,
    WINDOW_REMOVE,
    WINDOW_ACTIVATE,
} from "store/actionTypes";

import { windowContainerTypes, getWindowState } from "utils/window";

import { WindowState, WindowAction } from "./types";

const window: WindowState = {
    views: [],
    previousActive: null,
};

const initialState = {
    [windowContainerTypes.Root]: window,
    [windowContainerTypes.Login]: window,
    [windowContainerTypes.Home]: window,
};

export const reducer = (state = initialState, action: WindowAction) => {
    state = state || getWindowState();

    switch (action.type) {
        case WINDOW_CONTAINER_ADD:
            state = {
                ...state,
                [action.payload.container]: window,
            };

            break;

        case WINDOW_CONTAINER_REMOVE:
            const { [action.payload.container]: _remove, ...updatedState } = state;

            state = {
                ...updatedState,
            };

            break;

        case WINDOW_ADD:
        case WINDOW_UPDATE:
        case WINDOW_REMOVE:
            state = {
                ...state,
                [action.payload.container]: {
                    ...state[action.payload.container],
                    views: action.payload.views,
                },
            };

            break;

        case WINDOW_ACTIVATE:
            state = {
                ...state,
                [action.payload.container]: {
                    ...state[action.payload.container],
                    views: action.payload.views,
                    previousActive: action.payload.previousActive || null,
                },
            };

            break;

        default:
            break;
    }

    return state;
};
