import { isEmpty } from "lodash";

import { RelevantAlgorithm } from "types";

export const sortRelAlgorithms = (
    relAlgorithms: RelevantAlgorithm[],
    trmNumber: string,
    trmFamiliarName: string,
    measureNumber: string,
    measure: string,
    algorithmNumber?: string,
) => {
    const sortedRelAlgorithms = [...relAlgorithms].sort((a, b) => {
        // Compare trmFamiliarName
        if (a.trmFamiliarName.localeCompare(b.trmFamiliarName) === 0) {
            // If same trmFamiliarName, compare measure
            if (a.measure.localeCompare(b.measure) === 0) {
                // If same measure, compare sector
                if (a.sector.localeCompare(b.sector) === 0) {
                    // If same sector, sort alphabetically by vintage
                    return a.vintage.localeCompare(b.vintage);
                } else {
                    // If sector differs, sort alphabetically by sector
                    return a.sector.localeCompare(b.sector);
                }
            } else {
                // If mesaure differs, sort alphabetically by measure
                return a.measure.localeCompare(b.measure);
            }
        }

        // If trmFamiliarName differs, sort alphabetically by trmFamiliarName
        return a.trmFamiliarName.localeCompare(b.trmFamiliarName);
    });

    if (!isEmpty(relAlgorithms)) {
        // Add selected TRM at the start of the list
        sortedRelAlgorithms.unshift({
            algorithmNumber: algorithmNumber ?? "",
            trmNumber,
            trmFamiliarName,
            algorithmDescr: "",
            isSameClimateZone: true,
            sector: "",
            measure,
            vintage: "",
            endUse: "",
            fuelType: "",
            measureNumber,
            sectorNumber: "",
            vintageNumber: "",
            fuelTypeNumber: "",
        });
    }

    return sortedRelAlgorithms;
};
