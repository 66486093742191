import { combineReducers } from "@reduxjs/toolkit";

import { USER_DELETE } from "./actionTypes";
import { api } from "./apiSlice";

import benchmarkTrmReducer from "pages/BenchmarkTrm/benchmarkTrmSlice";
import exploreTrmsReducer from "pages/ExploreTrms/exploreTrmsSlice";
import { reducer as loginReducer } from "./login/reducer";
import { reducer as systemReducer } from "./system/reducer";
import tourReducer from "components/TourCard/tourSlice";
import { reducer as userReducer } from "./user/reducer";
import { reducer as windowReducer } from "./window/reducer";

const appReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    benchmarkTrm: benchmarkTrmReducer,
    exploreTrms: exploreTrmsReducer,
    login: loginReducer,
    system: systemReducer,
    tour: tourReducer,
    user: userReducer,
    window: windowReducer,
});

const rootReducer = (state, action) => {
    if (action.type === USER_DELETE) {
        const tourState = { ...state.tour };

        state = {};
        state.tour = tourState; // persist tour state
    }

    return appReducer(state, action);
};

export default rootReducer;
