import { useCallback, useState } from "react";

import { IdsContainer, IdsRow, IdsCol, IdsCard } from "@emergn-infinity/ids-react";

import { Sidebar } from "./Sidebar";
import { MainPanel } from "./MainPanel";

import { TrmAttributes } from "./utils";

export const StandardizeData = () => {
    const [activeAttribute, setActiveAttribute] = useState<TrmAttributeType>(TrmAttributes.Sectors.id);

    const onAttributeClick = useCallback((value: TrmAttributeType) => {
        setActiveAttribute(value);
    }, []);

    return (
        <IdsContainer fullHeight customClasses="p-4">
            <IdsRow>
                <IdsCol md="3" lg="2">
                    <IdsCard customClasses="fill-height">
                        <div slot="slot1">
                            <Sidebar attribute={activeAttribute} onClick={onAttributeClick} />
                        </div>
                    </IdsCard>
                </IdsCol>
                <IdsCol md="9" lg="10">
                    <IdsCard customClasses="fill-height p-0">
                        <div slot="slot1" className="fill-height pl-4 py-4">
                            <MainPanel attribute={activeAttribute} />
                        </div>
                    </IdsCard>
                </IdsCol>
            </IdsRow>
        </IdsContainer>
    );
};

export type TrmAttributeType = (typeof TrmAttributes)[keyof typeof TrmAttributes]["id"];
