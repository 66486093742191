import { isEmpty } from "lodash";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IdsTableRow, IdsTableCell, IdsText, IdsTag, IdsDropdown } from "@emergn-infinity/ids-react";

import { Tooltip } from "components/Tooltip";

import { VariableText } from "./VariableText";

import { GridStyle } from "pages/BenchmarkTrm/MainPanel/SavingsCalculationsGrid";

import { linkIcon } from "utils/icons";

import { IndexSignature, SavingsBenchmark } from "types";

export const SavingsCalculationsGridRows: React.FC<{
    rowItems: IndexSignature<IndexSignature<SavingsBenchmark>>;
    styles: GridStyle;
}> = ({ rowItems, styles }) => {
    // Following structure:
    //
    //  {
    //      "Quantity installed": {
    //          "Arkansas Statewide TRM V9.1 (2022)-Electric Vehicle Charging Systems (EV Chargers)": "5.5",
    //          ...
    //      },
    //      ...
    //  }
    const [additionalDetails, setAdditionalDetails] = useState<IndexSignature<IndexSignature<string>>>({});

    /**
     * Changes lookup value by bulk.
     *
     * @param k1 - (key1) assumption's key
     * @param k2 - (key2) algorithm's key (needed for clearing dropdowns)
     * @param lookupValue - lookup value to bulk change
     */
    const onAdditionalDetailsChange = (k1: string, k2: string, lookupValue?: string) => {
        setAdditionalDetails((prev) => {
            const prevState = { ...prev };

            // Clear dropdowns
            if (!lookupValue) {
                const value = prevState[k1][k2]; // get value from user interacted dropdown

                // Loop through row dropdown values
                Object.keys(prevState[k1]).forEach((algorithmKey) => {
                    // If row dropdown value is the same as
                    // value from user interacted dropdown,
                    // clear row dropdown value
                    if (prevState[k1][algorithmKey] === value) {
                        delete prevState[k1][algorithmKey];
                    }
                });

                return prevState;
            }

            // Select values for dropdowns
            //
            // Loop through TRMs & Measures
            Object.keys(rowItems[k1]).forEach((algorithmKey) => {
                const additionalDetailItems = rowItems[k1][algorithmKey].additionalDetails ?? []; // get TRM & Measure additional details array (if there is)

                // If additional details array includes lookup value
                // from user interacted dropdown, select that lookup
                if (additionalDetailItems.find((lookup) => lookup.lookupValue.toString() === lookupValue)) {
                    prevState[k1] = {
                        ...prevState[k1],
                        [algorithmKey]: lookupValue,
                    };
                }
            });

            return prevState;
        });
    };

    return Object.keys(rowItems).map((assumptionKey) => (
        <IdsTableRow key={`assumption-row-${assumptionKey}`}>
            <IdsTableCell style={{ ...styles.variableDescription, position: "sticky", left: 0, zIndex: 2 }}>
                <IdsText size="md">{assumptionKey}</IdsText>
            </IdsTableCell>
            {Object.keys(rowItems[assumptionKey]).map((algorithmKey, index) => (
                <IdsTableCell
                    key={`assumption-data-${index}-${rowItems[assumptionKey][algorithmKey].assumptionNumber}`}
                    style={styles.relAlgorithm}
                >
                    <div className="flex-column gap-2">
                        <div className="flex-row justify-space-between">
                            {rowItems[assumptionKey][algorithmKey].formattedVariable && (
                                <IdsText size="md">
                                    <VariableText
                                        text={rowItems[assumptionKey][algorithmKey].formattedVariable}
                                        lookupValue={additionalDetails?.[assumptionKey]?.[algorithmKey]}
                                    />
                                </IdsText>
                            )}
                            {rowItems[assumptionKey][algorithmKey].variableSource && (
                                <div className="align-self-end">
                                    <Tooltip message={rowItems[assumptionKey][algorithmKey].variableSource}>
                                        <IdsTag size="sm">
                                            <div className="flex-row gap-2 align-center">
                                                <FontAwesomeIcon icon={linkIcon} fixedWidth />
                                                {rowItems[assumptionKey][algorithmKey].variableSourceYear ?? "-"}
                                            </div>
                                        </IdsTag>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        <div>
                            {!isEmpty(rowItems[assumptionKey][algorithmKey].additionalDetails) && (
                                <IdsDropdown
                                    size="sm"
                                    idValue="savings-calculations-additional-details"
                                    placeholder=" "
                                    items={rowItems[assumptionKey][algorithmKey].additionalDetails.map((detail) => ({
                                        value: detail.lookupValue.toString(),
                                        label: detail.lookupCriteria,
                                    }))}
                                    initialSelectedItems={
                                        !isEmpty(additionalDetails?.[assumptionKey]?.[algorithmKey])
                                            ? [additionalDetails[assumptionKey][algorithmKey]]
                                            : undefined
                                    }
                                    changeHandler={(lookupValue) => onAdditionalDetailsChange(assumptionKey, algorithmKey, lookupValue)}
                                    clearHandler={() => onAdditionalDetailsChange(assumptionKey, algorithmKey)}
                                    style={{ width: "auto" }}
                                />
                            )}
                        </div>
                    </div>
                </IdsTableCell>
            ))}
        </IdsTableRow>
    ));
};
