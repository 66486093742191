import { IdsButton, IdsButtonGroup, IdsModal, IdsText } from "@emergn-infinity/ids-react";

import { useDeleteAlgorithmMutation } from "store/apiSlice";

import { EditFormTags } from "pages/ExploreTrms/EditFormTags";

import { DELETE_MODAL_STYLE } from "pages/ExploreTrms/utils";

import { formatAlgorithmText } from "utils/string";

import { Measure, MeasureAlgorithm } from "types";

export const AlgorithmDelete: React.FC<{
    measure: Measure;
    algorithm: MeasureAlgorithm;
    onClose: () => void;
}> = ({ measure, algorithm, onClose }) => {
    const [deleteAlgorithm, deleteAlgorithmStatus] = useDeleteAlgorithmMutation();

    const onDelete = async () => {
        // Do nothing if deleting is in progress
        if (deleteAlgorithmStatus.isLoading) {
            return;
        }

        try {
            await deleteAlgorithm({ algorithmNumber: algorithm.algorithmNumber }).unwrap();
            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Delete Algorithm
                </IdsText>
            </div>
            <div slot="main" style={DELETE_MODAL_STYLE}>
                <EditFormTags
                    trmName={measure.trmFamiliarName}
                    measureName={measure.measureName}
                    sector={algorithm.sector}
                    endUse={algorithm.endUse}
                    vintage={algorithm.vintage}
                />
                <IdsText customClasses="pb-4">Are you sure you want to delete this algorithm?</IdsText>
                <div dangerouslySetInnerHTML={{ __html: formatAlgorithmText(algorithm.algorithm) }} />
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onDelete} color="system-critical">
                        <>{deleteAlgorithmStatus.isLoading ? "Deleting..." : "Delete"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};
