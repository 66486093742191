import { SET_SERVER_TIMEZONE_OFFSET } from "store/actionTypes";
import { saveServerTimezone } from "utils/date";

export const setServerTimezoneOffset =
    ({ serverTimezoneOffset }) =>
    (dispatch, getState) => {
        saveServerTimezone(serverTimezoneOffset);

        dispatch({
            type: SET_SERVER_TIMEZONE_OFFSET,
            serverTimezoneOffset,
        });
    };
