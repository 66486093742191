import { Provider } from "react-redux";
import store from "store";
import ErrorBoundary from "components/ErrorBoundary";
import { ToastContainer } from "react-toastify";
import WindowManager from "components/WindowManager";

import "react-toastify/dist/ReactToastify.css";
import "./style.scss";

const App = () => (
    <Provider store={store}>
        <ErrorBoundary>
            <div className="app no-scroll layout-full-screen">
                <WindowManager />
                <ToastContainer theme="colored" autoClose={5000} position="bottom-left" />
            </div>
        </ErrorBoundary>
    </Provider>
);

export default App;
