import { IdsTable, IdsTableRow, IdsTableCell, IdsCheckbox, IdsText } from "@emergn-infinity/ids-react";

import { Vintage } from "types";

export const VintagesTable: React.FC<{
    activeVintage: Vintage | null;
    selectedVintages: Vintage[];
    standardizingList: Vintage[];
    onClick: (vintage: Vintage) => void;
    onChange: (vintage: Vintage) => void;
}> = ({ activeVintage, selectedVintages, standardizingList, onClick, onChange }) => {
    return (
        <IdsTable spacing="lg">
            {standardizingList.map((vintage) => (
                <IdsTableRow key={`${vintage.vintageNumber}`}>
                    <IdsTableCell
                        style={{
                            backgroundColor:
                                activeVintage?.vintageNumber === vintage.vintageNumber
                                    ? "var(--ids-table-cell-body-background-color-clear-hover)"
                                    : undefined,
                        }}
                        onClick={() => onClick(vintage)}
                    >
                        <div className="flex-row align-center">
                            <div>
                                <IdsCheckbox
                                    idValue={vintage.vintageNumber}
                                    defaultChecked={selectedVintages.find((v) => v.vintageNumber === vintage.vintageNumber) !== undefined}
                                    changeHandler={() => onChange(vintage)}
                                />
                            </div>
                            <div className="pl-4">
                                <IdsText weight="bold">{`${vintage.trmFamiliarName} > ${vintage.vintageName}`}</IdsText>
                                <IdsText>{`Maps to: ${vintage.sugStdVintage ? vintage.sugStdVintage : ""}`}</IdsText>
                            </div>
                        </div>
                    </IdsTableCell>
                </IdsTableRow>
            ))}
        </IdsTable>
    );
};
