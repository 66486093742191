import { IdsList, IdsListItem, IdsText, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import type { SubAction } from "components/Menu";

export const SubMenu: React.FC<{
    isOpen: boolean;
    items: React.ReactElement[];
    heading?: string;
    actions?: SubAction[];
    onClose: () => void;
}> = ({ isOpen, items, heading, actions, onClose }) => {
    const onClick = (action: SubAction) => {
        action.onClick?.();

        onClose();
    };

    return (
        <IdsList customClasses="border gap-0 p-0 sub-menu-list-component" isOpen={isOpen}>
            {heading && (
                <IdsText customClasses="pt-3 pb-1 px-3 menu-heading" size="md" weight="bold">
                    {heading}
                </IdsText>
            )}
            <div className="px-3 with-scroll">
                {items.map((item, index) => (
                    <IdsListItem key={`sub-menu-item-${index}`}>{item}</IdsListItem>
                ))}
            </div>
            {actions && (
                <>
                    <IdsListItem customClasses="no-margin" variant="divider" />
                    <IdsListItem customClasses="p-3">
                        <IdsButtonGroup spaceBetween="md">
                            {actions.map((action, index) => (
                                <IdsButton
                                    key={`sub-menu-action-${index}`}
                                    variant={action.variant}
                                    padding="sm"
                                    onClick={() => onClick(action)}
                                >
                                    {action.label}
                                </IdsButton>
                            ))}
                        </IdsButtonGroup>
                    </IdsListItem>
                </>
            )}
        </IdsList>
    );
};
