export const syncSessionStorage = () => {
    // Source from: https://stackoverflow.com/a/32766809
    // transfers sessionStorage from one tab to another
    const sessionStorageTransfer = function (event: StorageEvent | undefined) {
        if (!event) {
            event = window.event as StorageEvent;
        }

        // do nothing if no value to work with
        if (!event || !event.newValue) {
            return;
        }

        if (event.key === "getSessionStorage") {
            // another tab asked for the sessionStorage -> send it
            triggerSessionStorageSync();
        } else if (event.key === "sessionStorage") {
            // another tab sent data <- get it
            const data = JSON.parse(event.newValue);

            if (!sessionStorage.getItem("user") && data["user"]) {
                sessionStorage.setItem("user", data["user"]);
            }
        } else if (event.key?.startsWith("sessionStorage.")) {
            // another tab sent data with key <- get it
            const itemKey = event.key.split(".")[1];
            const data = JSON.parse(event.newValue);

            if (data[itemKey]) {
                sessionStorage.setItem(itemKey, data[itemKey]);
            }
        }
    };

    // listen for changes to localStorage
    window.addEventListener("storage", sessionStorageTransfer, { passive: true });

    // Ask other tabs for session storage (this is ONLY to trigger event)
    localStorage.setItem("getSessionStorage", "true");
    localStorage.removeItem("getSessionStorage");
};

export const triggerSessionStorageSync = (itemKey?: string) => {
    let key = "sessionStorage";
    if (itemKey) {
        key = [key, itemKey].join(".");
    }

    // send sessionStorage
    localStorage.setItem(key, JSON.stringify(sessionStorage));
    // the other tab should now have it, so we're done with it.
    localStorage.removeItem(key); // <- could do short timeout as well.
};
