import { isEmpty } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
    useGetMeasuresForStandardizingQuery,
    useGetStdMeasuresQuery,
    useUpdateStandardizingForMeasureMutation,
    useCreateStdMeasureMutation,
} from "store/apiSlice";

import { Standardize } from "pages/StandardizeData/MainPanel/common/Standardize";
import { SuggestedStandard } from "pages/StandardizeData/MainPanel/common/SuggestedStandard";

import { MeasuresTable } from "./MeasuresTable";
import { StandardMeasures } from "./StandardMeasures";

import { MeasureStandardizingListItem } from "types";

const addStdMeasureFields = [
    {
        idValue: "name",
        defaultValue: "",
        placeholder: "Enter name",
        label: "Name",
    },
    {
        idValue: "description",
        defaultValue: "",
        placeholder: "Enter description",
        label: "Description",
    },
];

export const Measures: React.FC<{
    id: string;
    name: string;
    fullName: string;
    showAll: boolean;
    hideInactive: boolean;
    search: string;
    pageSize: number;
    pageNumber: number;
    totalPages: number;
    onChange: (e: any, name: string) => void;
}> = ({ id, name, fullName, showAll, hideInactive, search, pageSize, pageNumber, totalPages, onChange }) => {
    const [activeMeasure, setActiveMeasure] = useState<MeasureStandardizingListItem | null>(null);
    const [selectedMeasures, setSelectedMeasures] = useState<MeasureStandardizingListItem[]>([]);

    const [stdMeasureNumbers, setStdMeasureNumbers] = useState<StandardMeasureNumbers>({
        stdMeasureNumberA: "",
        stdMeasureNumberB: "",
        stdMeasureNumberC: "",
        stdMeasureNumberD: "",
        stdMeasureNumberE: "",
    });

    const fetchStdMeasures = activeMeasure !== null || !isEmpty(selectedMeasures); // only fetch standard measures when a measure is selected
    const noStdMeasuresSelected = Object.keys(stdMeasureNumbers).every((key) => stdMeasureNumbers[key] === "");
    const standardizeDisabled = isEmpty(selectedMeasures) || noStdMeasuresSelected;

    const { data: measuresForStandardizing } = useGetMeasuresForStandardizingQuery({
        includeSuggested: showAll,
        hideInactive,
        pageSize,
        pageNumber,
        totalPages,
        measureNameSearch: search,
    });

    const {
        data: stdMeasures,
        isLoading: isLoadingStdMeasures,
        isFetching: isFetchingStdMeasures,
    } = useGetStdMeasuresQuery(undefined, { skip: !fetchStdMeasures });

    const [updateMeasureStandardizing, updateMeasureStandardizingStatus] = useUpdateStandardizingForMeasureMutation();
    const [createStdMeasure, createStdMeasureStatus] = useCreateStdMeasureMutation();

    const allSelected =
        selectedMeasures.length === measuresForStandardizing?.measureStandardizingList.length &&
        !isEmpty(measuresForStandardizing?.measureStandardizingList);

    const stdMeasuresList = useMemo(() => {
        return (stdMeasures ?? []).map((measure) => ({
            label: measure.stdMeasureName,
            value: measure.stdMeasureNumber,
        }));
    }, [stdMeasures]);

    const onSelectAll = () => {
        let newSelectedMeasures: MeasureStandardizingListItem[] = [];

        // Select all measures
        if (measuresForStandardizing !== undefined && !allSelected) {
            newSelectedMeasures = measuresForStandardizing.measureStandardizingList.map((measure) => measure);
        }

        setSelectedMeasures(newSelectedMeasures);
    };

    const onPageChange = (pageNumber: number) => {
        setSelectedMeasures([]);

        onChange(pageNumber, "pageNumber");
    };

    const onMeasureClick = (measure: MeasureStandardizingListItem) => {
        setActiveMeasure(measure);

        const newStdMeasureNumbers = { ...stdMeasureNumbers };

        newStdMeasureNumbers.stdMeasureNumberA = measure.sugMeasureNumberA ?? "";
        newStdMeasureNumbers.stdMeasureNumberB = measure.sugMeasureNumberB ?? "";
        newStdMeasureNumbers.stdMeasureNumberC = measure.sugMeasureNumberC ?? "";
        newStdMeasureNumbers.stdMeasureNumberD = measure.sugMeasureNumberD ?? "";
        newStdMeasureNumbers.stdMeasureNumberE = measure.sugMeasureNumberE ?? "";

        setStdMeasureNumbers(newStdMeasureNumbers);
    };

    const onMeasureChecked = (measure: MeasureStandardizingListItem) => {
        let newSelectedMeasures: MeasureStandardizingListItem[] = [];

        // Uncheck measure
        if (selectedMeasures.find((m) => m.measureNumber === measure.measureNumber)) {
            newSelectedMeasures = selectedMeasures.filter((m) => m.measureNumber !== measure.measureNumber);
        }
        // Check measure
        else {
            newSelectedMeasures = [...selectedMeasures];

            newSelectedMeasures.push(measure);
        }

        setSelectedMeasures(newSelectedMeasures);
    };

    const onSelectStandardMeasure = (value: string, name: string) => {
        const newStdMeasureNumbers = { ...stdMeasureNumbers };

        newStdMeasureNumbers[name] = value;

        setStdMeasureNumbers(newStdMeasureNumbers);
    };

    const onStandardizeClick = async () => {
        // Do nothing if saving is in progress
        if (updateMeasureStandardizingStatus.isLoading) {
            return;
        }

        // To update standardizing for measure,
        // a measure has to be selected
        // and at least one standard measure has to be selected
        if (!isEmpty(selectedMeasures) && !noStdMeasuresSelected) {
            const dateActivated = new Date(Date.now()).toISOString();

            const measures = selectedMeasures.map((measure) => ({
                measureNumber: measure.measureNumber,
                stdMeasureNumberA: stdMeasureNumbers.stdMeasureNumberA,
                stdMeasureNumberB: stdMeasureNumbers.stdMeasureNumberB,
                stdMeasureNumberC: stdMeasureNumbers.stdMeasureNumberC,
                stdMeasureNumberD: stdMeasureNumbers.stdMeasureNumberD,
                stdMeasureNumberE: stdMeasureNumbers.stdMeasureNumberE,
                dateActivated,
            }));

            try {
                await updateMeasureStandardizing({ measures }).unwrap();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onSaveClick = useCallback(
        async ({ name, description }: { name: string; description: string }) => {
            // Do nothing if saving is in progress
            if (createStdMeasureStatus.isLoading) {
                return;
            }

            // To create new standard measure,
            // name and description need to be provided
            if (!isEmpty(name) && !isEmpty(description)) {
                const stdMeasure = {
                    stdMeasureName: name,
                    stdMeasureDesc: description,
                };

                try {
                    const response = await createStdMeasure({ stdMeasure }).unwrap();

                    // On success, automatically set newly created
                    // standard measure
                    if (response.responseStatus === "success") {
                        for (const key in stdMeasureNumbers) {
                            // If standard measure A is not mapped,
                            // set newly created standard measure to that.
                            // If standard measure A is mapped, but
                            // standard measure B is not mapped,
                            // set newly created standard measure to that
                            // and so on.
                            // If all standard measures are mapped,
                            // do nothing
                            if (isEmpty(stdMeasureNumbers[key])) {
                                const newStdMeasureNumbers = { ...stdMeasureNumbers };

                                newStdMeasureNumbers[key] = response.stdMeasureNumber;

                                setStdMeasureNumbers(newStdMeasureNumbers);

                                break;
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
        [stdMeasureNumbers, createStdMeasure, createStdMeasureStatus],
    );

    return (
        <div className="flex-row fill-height">
            <div className="w-70 flex-grow pr-4">
                {measuresForStandardizing !== undefined && (
                    <Standardize
                        name={name}
                        fullName={fullName}
                        showAll={showAll}
                        hideInactive={hideInactive}
                        search={search}
                        allSelected={allSelected}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        tableComponent={
                            <MeasuresTable
                                activeMeasure={activeMeasure}
                                selectedMeasures={selectedMeasures}
                                standardizingList={measuresForStandardizing.measureStandardizingList}
                                onClick={onMeasureClick}
                                onChange={onMeasureChecked}
                            />
                        }
                        totalRows={measuresForStandardizing?.totalRows}
                        totalPages={measuresForStandardizing?.totalPages || 1} // 0 causes issues for IdsTablePagination
                        onSelectAll={onSelectAll}
                        onChange={onChange}
                        onPageChange={onPageChange}
                    />
                )}
            </div>
            <div className="vertical-divider" />
            {/* NOTE: Key here causes small freeze up and errors in console,
                      but otherwise dropdown default values do not get updated */}
            <div
                key={
                    activeMeasure === null
                        ? "measure-none"
                        : `measure-${activeMeasure.measureNumber}-${selectedMeasures.length}-${isFetchingStdMeasures}`
                }
                className="w-30 flex-grow pl-4"
            >
                {fetchStdMeasures && !isLoadingStdMeasures && (
                    <SuggestedStandard
                        id={id}
                        name={name}
                        standardComponent={
                            <StandardMeasures
                                stdMeasureNumbers={stdMeasureNumbers}
                                stdMeasuresList={stdMeasuresList}
                                onSelect={onSelectStandardMeasure}
                            />
                        }
                        modalFields={addStdMeasureFields}
                        standardizeDisabled={standardizeDisabled}
                        isLoading={createStdMeasureStatus.isLoading}
                        isSuccess={createStdMeasureStatus.isSuccess}
                        onStandardizeClick={onStandardizeClick}
                        onSaveClick={onSaveClick}
                    />
                )}
            </div>
        </div>
    );
};

export type StandardMeasureNumbers = {
    stdMeasureNumberA: string;
    stdMeasureNumberB: string;
    stdMeasureNumberC: string;
    stdMeasureNumberD: string;
    stdMeasureNumberE: string;
};
