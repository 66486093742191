import { isEmpty } from "lodash";
import { useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    useCreateEulMutation,
    useGetEulQuery,
    useGetLookupByPremisesQuery,
    useGetSectorsQuery,
    useGetVintagesQuery,
    useUpdateEulMutation,
} from "store/apiSlice";

import {
    IdsFieldWrapper,
    IdsText,
    IdsButton,
    IdsButtonGroup,
    IdsTextInput,
    IdsModal,
    IdsDropdown,
    IdsRadioButtonGroup,
    IdsRadioButton,
} from "@emergn-infinity/ids-react";

import { ManageLookupsModal } from "../ManageLookups";
import { EditFormTags } from "../EditFormTags";

import { EulStrategy as Strategy } from "utils/constants";
import { addIcon, infoIcon } from "utils/icons";
import { sanitizeAndSetNumericInput } from "utils/string";

import { EulUpdateModel, Measure } from "types";

const EUL_MODAL_STYLE = {
    width: "640px",
    maxWidth: "100%",
};

const initialManageLookups = {
    add: false,
    view: false,
};

export const MeasureLifeEdit: React.FC<{
    measure: Measure;
    eulNumber?: string;
    onClose: () => void;
}> = ({ measure, eulNumber, onClose }) => {
    const [manageLookups, setManageLookups] = useState(initialManageLookups);

    const [eulModel, setEulModel] = useState<EulUpdateModel>();
    const sourceYearInputRef = useRef<HTMLInputElement>(null);
    const eulInputRef = useRef<HTMLInputElement>(null);
    const rulInputRef = useRef<HTMLInputElement>(null);

    const { data: eul, isLoading, isError: isEulError } = useGetEulQuery({ eulNumber: eulNumber! }, { skip: !eulNumber });
    const isLoadingEul = isLoading && !isEmpty(eulNumber);

    const [create, createStatus] = useCreateEulMutation();
    const [update, updateStatus] = useUpdateEulMutation();

    const { data: sectors, isLoading: isLoadingSectors } = useGetSectorsQuery({ trmNumber: measure.trmNumber, isVerified: true });
    const sectorsList = useMemo(
        () => sectors?.map((sector) => ({ label: sector.sectorName, value: sector.sectorNumber })) || [],
        [sectors]
    );

    const { data: vintages, isLoading: isLoadingVintages } = useGetVintagesQuery({ trmNumber: measure.trmNumber, vintageVerified: true });
    const vintagesList = useMemo(
        () => vintages?.map((vintage) => ({ label: vintage.vintageName, value: vintage.vintageNumber })) || [],
        [vintages]
    );

    const { data: lookups, isLoading: isLoadingLookups } = useGetLookupByPremisesQuery({ trmNumber: measure.trmNumber, lookupType: "EUL" });
    const lookupsList = useMemo(() => lookups?.map((lookup) => ({ label: lookup, value: lookup })) || [], [lookups]);

    if (isEmpty(eulModel)) {
        if (!isEmpty(eulNumber) && !isEmpty(eul)) {
            setEulModel(eul);
        }

        if (isEmpty(eulNumber)) {
            setEulModel({
                eulStrategy: Strategy.Deemed,
                measureNumber: measure.measureNumber,
                eulVerified: true,
                eulUnits: Units.Years,
            });
        }
    }

    const onSave = async () => {
        // Do nothing if saving is in progress
        if (updateStatus.isLoading || createStatus.isLoading) {
            return;
        }

        if (eulModel) {
            try {
                if (eulNumber) {
                    await update({ eulNumber, eul: eulModel }).unwrap();
                } else {
                    await create({ eul: eulModel }).unwrap();
                }
                onClose();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const title = isEmpty(eulNumber) ? "Add Measure Life" : "Edit Measure Life";

    if (manageLookups.add || manageLookups.view) {
        return (
            <ManageLookupsModal
                measure={measure}
                lookupType="EUL"
                title={title}
                lookupByPremise={manageLookups.view && eulModel?.eulLookupBy ? eulModel.eulLookupBy : undefined}
                onClose={() => setManageLookups(initialManageLookups)}
            />
        );
    }

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {title}
                </IdsText>
            </div>
            <div slot="main" style={EUL_MODAL_STYLE}>
                {(isLoadingEul || isLoadingSectors || isLoadingVintages || isLoadingLookups) && <IdsText size="md">Loading...</IdsText>}
                {isEulError && <IdsText size="md">Error loading Measure Life</IdsText>}
                {!isLoadingEul && !isLoadingSectors && !isLoadingVintages && !isLoadingLookups && !isEulError && eulModel && (
                    <>
                        <EditFormTags trmName={measure.trmFamiliarName} measureName={measure.measureName} />
                        <IdsFieldWrapper htmlFor="eul-sector" wrapperLabel="Sector" isRequired>
                            <IdsDropdown
                                idValue="eul-sector"
                                isSearchable
                                items={sectorsList}
                                placeholder={isLoadingSectors ? "Loading" : "Type to Search"}
                                initialSelectedItems={eulModel.sectorNumber ? [eulModel.sectorNumber] : []}
                                changeHandler={(value) => setEulModel({ ...eulModel, sectorNumber: value })}
                                clearHandler={() => setEulModel({ ...eulModel, sectorNumber: undefined })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="eul-vintage" wrapperLabel="Vintage" isRequired>
                            <IdsDropdown
                                idValue="eul-vintage"
                                isSearchable
                                items={vintagesList}
                                placeholder={isLoadingVintages ? "Loading" : "Type to Search"}
                                initialSelectedItems={eulModel.vintageNumber ? [eulModel.vintageNumber] : []}
                                changeHandler={(value) => setEulModel({ ...eulModel, vintageNumber: value })}
                                clearHandler={() => setEulModel({ ...eulModel, vintageNumber: undefined })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="eul-strategy" wrapperLabel="EUL Assigned by">
                            <IdsRadioButtonGroup horizontalOrientation>
                                <IdsRadioButton
                                    idValue={Strategy.Deemed}
                                    name="eul-strategy"
                                    label={Strategy.Deemed}
                                    defaultChecked={eulModel.eulStrategy === Strategy.Deemed}
                                    changeHandler={(e: any) =>
                                        setEulModel({ ...eulModel, eulStrategy: e.target.id, eulLookupBy: undefined })
                                    }
                                />
                                <IdsRadioButton
                                    idValue={Strategy.Lookup}
                                    name="eul-strategy"
                                    label={Strategy.Lookup}
                                    defaultChecked={eulModel.eulStrategy === Strategy.Lookup}
                                    changeHandler={(e: any) =>
                                        setEulModel({ ...eulModel, eulStrategy: e.target.id, eul: undefined, rul: undefined })
                                    }
                                />
                                <IdsRadioButton
                                    idValue={Strategy.UserDefined}
                                    name="eul-strategy"
                                    label={Strategy.UserDefined}
                                    defaultChecked={eulModel.eulStrategy === Strategy.UserDefined}
                                    changeHandler={(e: any) =>
                                        setEulModel({
                                            ...eulModel,
                                            eulStrategy: e.target.id,
                                            eulLookupBy: undefined,
                                            eul: undefined,
                                            rul: undefined,
                                        })
                                    }
                                />
                                <IdsRadioButton
                                    idValue={Strategy.Equation}
                                    name="eul-strategy"
                                    label={Strategy.Equation}
                                    defaultChecked={eulModel.eulStrategy === Strategy.Equation}
                                    changeHandler={(e: any) =>
                                        setEulModel({
                                            ...eulModel,
                                            eulStrategy: e.target.id,
                                            eulLookupBy: undefined,
                                            eul: undefined,
                                            rul: undefined,
                                        })
                                    }
                                />
                            </IdsRadioButtonGroup>
                        </IdsFieldWrapper>
                        {eulModel.eulStrategy === Strategy.Deemed && (
                            <div className="flex-row gap-3">
                                <IdsFieldWrapper
                                    htmlFor="eul"
                                    wrapperLabel="Effective Useful Life (EUL)"
                                    isRequired
                                    customClasses="flex-one"
                                >
                                    <IdsTextInput
                                        innerRef={eulInputRef}
                                        idValue="eul"
                                        defaultValue={String(eulModel.eul ?? "")}
                                        changeHandler={(value) =>
                                            setEulModel({ ...eulModel, eul: sanitizeAndSetNumericInput(value, eulInputRef) })
                                        }
                                    />
                                </IdsFieldWrapper>
                                <IdsFieldWrapper htmlFor="eul-rul" wrapperLabel="Remaining Useful Life (RUL)" customClasses="flex-one">
                                    <IdsTextInput
                                        innerRef={rulInputRef}
                                        idValue="eul-rul"
                                        defaultValue={String(eulModel.rul ?? "")}
                                        changeHandler={(value) =>
                                            setEulModel({
                                                ...eulModel,
                                                rul: sanitizeAndSetNumericInput(value, rulInputRef),
                                            })
                                        }
                                    />
                                </IdsFieldWrapper>
                            </div>
                        )}
                        {eulModel.eulStrategy === Strategy.Lookup && (
                            <IdsFieldWrapper htmlFor="eul-lookup" wrapperLabel="Lookup Table" isRequired>
                                <IdsDropdown
                                    idValue="eul-lookup"
                                    isSearchable
                                    items={lookupsList}
                                    placeholder={isLoadingLookups ? "Loading" : "Type to Search"}
                                    initialSelectedItems={eulModel.eulLookupBy ? [eulModel.eulLookupBy] : []}
                                    changeHandler={(value) => setEulModel({ ...eulModel, eulLookupBy: value })}
                                    clearHandler={() => setEulModel({ ...eulModel, eulLookupBy: "" })}
                                />
                                <IdsButtonGroup position="justify" customClasses="pt-1">
                                    <IdsButton variant="tertiary" clickHandler={() => setManageLookups({ add: true, view: false })}>
                                        <div className="flex-row gap-2 align-center">
                                            <FontAwesomeIcon icon={addIcon} fixedWidth />
                                            Add new
                                        </div>
                                    </IdsButton>
                                    <IdsButton
                                        variant="tertiary"
                                        clickHandler={() => setManageLookups({ add: false, view: true })}
                                        isDisabled={isEmpty(eulModel.eulLookupBy)}
                                    >
                                        <div className="flex-row gap-2 align-center">
                                            <FontAwesomeIcon icon={infoIcon} fixedWidth />
                                            View details
                                        </div>
                                    </IdsButton>
                                </IdsButtonGroup>
                            </IdsFieldWrapper>
                        )}
                        <IdsFieldWrapper htmlFor="eul-units" wrapperLabel="Units" isRequired>
                            <IdsDropdown
                                idValue="eul-units"
                                isSearchable
                                items={[
                                    {
                                        label: Units.Hours,
                                        value: Units.Hours,
                                    },
                                    {
                                        label: Units.Years,
                                        value: Units.Years,
                                    },
                                ]}
                                placeholder="Type to Search"
                                initialSelectedItems={eulModel.eulUnits ? [eulModel.eulUnits] : []}
                                changeHandler={(value) => setEulModel({ ...eulModel, eulUnits: value })}
                                clearHandler={() => setEulModel({ ...eulModel, eulUnits: undefined })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="eul-source" wrapperLabel="Source">
                            <IdsTextInput
                                idValue="eul-source"
                                defaultValue={eulModel.eulSource}
                                changeHandler={(value) => setEulModel({ ...eulModel, eulSource: value })}
                            />
                        </IdsFieldWrapper>
                        <IdsFieldWrapper htmlFor="eul-source-year" wrapperLabel="Source Year">
                            <IdsTextInput
                                innerRef={sourceYearInputRef}
                                idValue="eul-source-year"
                                defaultValue={String(eulModel.eulSourceYear ?? "")}
                                changeHandler={(value) =>
                                    setEulModel({
                                        ...eulModel,
                                        eulSourceYear: sanitizeAndSetNumericInput(value, sourceYearInputRef, {
                                            maxLength: 4,
                                            allowDecimals: false,
                                        }),
                                    })
                                }
                            />
                        </IdsFieldWrapper>
                    </>
                )}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onSave}>
                        <>{updateStatus.isLoading || createStatus.isLoading ? "Saving..." : "Save"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

const Units = {
    Hours: "Hours",
    Years: "Years",
} as const;
