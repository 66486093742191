import { isEmpty } from "lodash";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IdsButton } from "@emergn-infinity/ids-react";

import { Tile } from "components/Tile";

import { MeasureEdit } from "./MeasureEdit";
import { InfoItem } from "./InfoItem";

import { useMeasureDetails } from "./utils";

import { MeasuresRights } from "utils/constants";
import { formatJsonDate } from "utils/date";
import { editIcon } from "utils/icons";
import { hasRights } from "utils/user";

export const MeasureDetailsTile: React.FC<{
    selectedMeasure: string;
}> = ({ selectedMeasure }) => {
    const [isEditEnabled, setIsEditEnabled] = useState(false);

    const editRights = hasRights(MeasuresRights);
    const measureDetails = useMeasureDetails(selectedMeasure);

    return (
        <Tile
            className="flex-one-in-row"
            title="Measure Details"
            action={
                editRights && (
                    <IdsButton padding="sm" variant="tertiary" onClick={() => setIsEditEnabled(true)}>
                        <div className="flex-row gap-2 align-center">
                            <FontAwesomeIcon icon={editIcon} />
                            Edit
                        </div>
                    </IdsButton>
                )
            }
        >
            {isEditEnabled && measureDetails && <MeasureEdit measure={measureDetails} onClose={() => setIsEditEnabled(false)} />}
            <div className="flex-row p-3">
                <div className="flex-column w-50 gap-3">
                    <div>
                        <InfoItem
                            className="flex-one-in-row"
                            label="Code"
                            value={isEmpty(measureDetails?.measureCode) ? "-" : measureDetails?.measureCode}
                        />
                    </div>
                    <div className="flex-row">
                        <InfoItem
                            className="flex-one-in-row"
                            label="Effective date"
                            value={formatJsonDate(measureDetails?.effectiveDate, "-")}
                        />
                        <InfoItem className="flex-one-in-row" label="Sunset date" value={formatJsonDate(measureDetails?.sunsetDate, "-")} />
                    </div>
                </div>
                <div className="w-50">
                    <InfoItem
                        label="Description"
                        value={isEmpty(measureDetails?.measureDesc) ? "-" : measureDetails?.measureDesc}
                        showMore
                    />
                </div>
            </div>
        </Tile>
    );
};
