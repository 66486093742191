import { isEmpty } from "lodash";

import { useGetRelevantAlgorithmsQuery } from "store/apiSlice";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { selectAllRelAlgoritms, resetTrigger } from "./benchmarkTrmSlice";

import { IdsContainer, IdsRow, IdsCol, IdsCard } from "@emergn-infinity/ids-react";

import { Sidebar } from "./Sidebar";
import { MainPanel } from "./MainPanel";

export const BenchmarkTrm = () => {
    const dispatch = useAppDispatch();

    const { selectedTrm, selectedMeasure, selectedSector, selectedVintage, selectedFuelType, selectedRelAlgorithms, triggerSelect } =
        useAppSelector((state) => state.benchmarkTrm);

    const { data: relAlgorithms, isFetching: isFetchingRelAlgorithms } = useGetRelevantAlgorithmsQuery(
        {
            trmNumber: selectedTrm,
            measureNumber: selectedMeasure,
            sectorNumber: selectedSector,
            vintageNumber: selectedVintage,
            fuelTypeNumber: selectedFuelType,
        },
        { skip: isEmpty(selectedTrm) || isEmpty(selectedMeasure) },
    );

    const showMainContent = !isEmpty(selectedTrm) && !isEmpty(selectedMeasure);

    // Selects all relevant algorithms on measure, sector, vintage or fuel type change
    if (relAlgorithms !== undefined && !isFetchingRelAlgorithms && triggerSelect) {
        dispatch(resetTrigger());

        dispatch(selectAllRelAlgoritms([...relAlgorithms]));
    }

    return (
        <IdsContainer fullHeight customClasses="p-0">
            <IdsRow noGutters>
                <IdsCol xs="3">
                    <IdsCard customClasses="fill-height">
                        <div slot="slot1" className="fill-height">
                            <Sidebar
                                selectedTrm={selectedTrm}
                                selectedMeasure={selectedMeasure}
                                selectedSector={selectedSector}
                                selectedVintage={selectedVintage}
                                selectedFuelType={selectedFuelType}
                                selectedRelAlgorithms={selectedRelAlgorithms}
                                isFetching={isFetchingRelAlgorithms}
                                relAlgorithms={relAlgorithms}
                            />
                        </div>
                    </IdsCard>
                </IdsCol>
                {showMainContent && (
                    <IdsCol xs="9" customClasses="p-4">
                        <IdsCard customClasses="fill-height px-4 py-2">
                            <div slot="slot1" className="fill-height">
                                <MainPanel />
                            </div>
                        </IdsCard>
                    </IdsCol>
                )}
            </IdsRow>
        </IdsContainer>
    );
};
