import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { IdsRadioButton } from "@emergn-infinity/ids-react";

import "./style.scss";

export const CustomRadio: React.FC<{
    className?: string;
    id?: string;
    name?: string;
    label?: string;
    defaultChecked?: boolean;
    icon?: IconProp;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ className, id, name, label, defaultChecked, icon, onClick }) => {
    return (
        <button
            className={cn("flex-column align-center custom-radio gap-1 p-3", className)}
            style={{
                border: defaultChecked ? "2px solid var(--ids-semantic-border-color-brand-a-subtle)" : "1px solid var(--theme-base-border)",
                borderRadius: "var(--ids-button-border-radius)",
                background: defaultChecked ? "var(--ids-semantic-background-color-brand-a-subtlest-hover)" : "none",
                cursor: "pointer",
            }}
            onClick={onClick}
        >
            {icon && (
                <FontAwesomeIcon
                    icon={icon}
                    size="lg"
                    color={
                        defaultChecked
                            ? "var(--ids-semantic-ink-color-brand-a-subtlest)"
                            : "var(--ids-semantic-ink-color-neutral-subtle-onlight)"
                    }
                />
            )}
            <IdsRadioButton idValue={id} name={name} label={label} defaultChecked={defaultChecked} />
        </button>
    );
};
