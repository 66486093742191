export const usersAuthenticationCodeResourceName = "authCode";
export const usersQuickResponseCodeResourceName = "qrCode";

export const availableResources = {
    tokens: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/tokens`,
    password: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/password`,
    resetPassword: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/users/{userNumber}/password`,
    qrCode: `${process.env.REACT_APP_VDSM_USERS_API_BASE_URL}{userNumber}/qrcode`,
    authCode: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/users/authcode`,
};
