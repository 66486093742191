import { isEmpty } from "lodash";

import { IdsDropdown } from "@emergn-infinity/ids-react";

import { StandardMeasureNumbers } from ".";

// NOTE: If stdMeasuresList on initial render in an empty array
// initialSelectedItems will be set, but will not show up
// in the dropdown field.
export const StandardMeasures: React.FC<{
    stdMeasureNumbers: StandardMeasureNumbers;
    stdMeasuresList: {
        label: string;
        value: string;
    }[];
    onSelect: (value: string, name: string) => void;
}> = ({ stdMeasureNumbers, stdMeasuresList, onSelect }) => {
    return (
        <>
            <IdsDropdown
                idValue="select-standard-measure-a"
                isSearchable
                items={stdMeasuresList}
                placeholder="Select Standard Measure"
                initialSelectedItems={isEmpty(stdMeasureNumbers.stdMeasureNumberA) ? null : [stdMeasureNumbers.stdMeasureNumberA]}
                changeHandler={(value) => onSelect(value, "stdMeasureNumberA")}
                clearHandler={() => onSelect("", "stdMeasureNumberA")}
            />
            <IdsDropdown
                idValue="select-standard-measure-b"
                isSearchable
                items={stdMeasuresList}
                placeholder="Select Standard Measure"
                initialSelectedItems={isEmpty(stdMeasureNumbers.stdMeasureNumberB) ? null : [stdMeasureNumbers.stdMeasureNumberB]}
                changeHandler={(value) => onSelect(value, "stdMeasureNumberB")}
                clearHandler={() => onSelect("", "stdMeasureNumberB")}
            />
            <IdsDropdown
                idValue="select-standard-measure-c"
                isSearchable
                items={stdMeasuresList}
                placeholder="Select Standard Measure"
                initialSelectedItems={isEmpty(stdMeasureNumbers.stdMeasureNumberC) ? null : [stdMeasureNumbers.stdMeasureNumberC]}
                changeHandler={(value) => onSelect(value, "stdMeasureNumberC")}
                clearHandler={() => onSelect("", "stdMeasureNumberC")}
            />
            <IdsDropdown
                idValue="select-standard-measure-d"
                isSearchable
                items={stdMeasuresList}
                placeholder="Select Standard Measure"
                initialSelectedItems={isEmpty(stdMeasureNumbers.stdMeasureNumberD) ? null : [stdMeasureNumbers.stdMeasureNumberD]}
                changeHandler={(value) => onSelect(value, "stdMeasureNumberD")}
                clearHandler={() => onSelect("", "stdMeasureNumberD")}
            />
            <IdsDropdown
                idValue="select-standard-measure-e"
                isSearchable
                items={stdMeasuresList}
                placeholder="Select Standard Measure"
                initialSelectedItems={isEmpty(stdMeasureNumbers.stdMeasureNumberE) ? null : [stdMeasureNumbers.stdMeasureNumberE]}
                changeHandler={(value) => onSelect(value, "stdMeasureNumberE")}
                clearHandler={() => onSelect("", "stdMeasureNumberE")}
            />
        </>
    );
};
