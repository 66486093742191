import { IdsTag, IdsText } from "@emergn-infinity/ids-react";
import cn from "classnames";
import { isNumber } from "lodash";

export const Tile: React.FC<{
    className?: string;
    bodyClassName?: string;
    title: string;
    counter?: number;
    action?: React.ReactNode;
    children: React.ReactNode;
}> = ({ className, bodyClassName, title, counter, action, children }) => {
    return (
        <div className={cn("flex-column border bg-white", className)}>
            <div className="flex-row align-center py-2 pr-2 gap-2 justify-space-between border-bottom">
                <div className="py-1">
                    <IdsText
                        weight="bold"
                        customClasses="pl-2"
                        style={{
                            borderLeft: "5px solid var(--ids-semantic-border-color-brand-a-accent)",
                        }}
                    >
                        {title}
                    </IdsText>
                </div>
                {action}
                {isNumber(counter) && (
                    <IdsTag variant="information">
                        <>{counter}</>
                    </IdsTag>
                )}
            </div>
            <div className={cn("flex-one", bodyClassName)}>{children}</div>
        </div>
    );
};
