import ClassNames from "classnames";

import "./style.scss";

const StatusMsg = (props: StatusMsgProps) => {
    const classNames = ClassNames("status-msg", props.className, {
        error: props.error,
        success: props.success,
        warning: props.warning,
        "text-center large-msg": props.largeMsg,
    });

    return <div className={classNames}>{props.message}</div>;
};

export interface StatusMsgInterface {
    /**
     * Display large message with a background.
     */
    largeMsg?: boolean;

    /**
     * Message text.
     */
    message: string | JSX.Element;
}

interface StatusMsgProps extends StatusMsgInterface {
    className?: string;

    /**
     * Sets error style to message.
     */
    error?: boolean;

    /**
     * Sets success style to message.
     */
    success?: boolean;

    /**
     * Sets warning style to message.
     */
    warning?: boolean;
}

export default StatusMsg;
