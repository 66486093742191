import { IdsTable, IdsTableRow, IdsTableCell, IdsCheckbox, IdsText } from "@emergn-infinity/ids-react";

import { formatAlgorithmText } from "utils/string";

import { AlgorithmStandardizingListItem } from "types";

export const AlgorithmsTable: React.FC<{
    activeAlgorithm: AlgorithmStandardizingListItem | null;
    selectedAlgorithms: AlgorithmStandardizingListItem[];
    standardizingList: AlgorithmStandardizingListItem[];
    onClick: (algorithm: AlgorithmStandardizingListItem) => void;
    onChange: (algorithm: AlgorithmStandardizingListItem) => void;
}> = ({ activeAlgorithm, selectedAlgorithms, standardizingList, onClick, onChange }) => {
    return (
        <IdsTable spacing="lg">
            {standardizingList.map((algorithm) => (
                <IdsTableRow key={`${algorithm.algorithmNumber}`}>
                    <IdsTableCell
                        style={{
                            backgroundColor:
                                activeAlgorithm?.algorithmNumber === algorithm.algorithmNumber
                                    ? "var(--ids-table-cell-body-background-color-clear-hover)"
                                    : undefined,
                        }}
                        onClick={() => onClick(algorithm)}
                    >
                        <div className="flex-row align-center">
                            <div>
                                <IdsCheckbox
                                    idValue={algorithm.algorithmNumber}
                                    defaultChecked={
                                        selectedAlgorithms.find((a) => a.algorithmNumber === algorithm.algorithmNumber) !== undefined
                                    }
                                    changeHandler={() => onChange(algorithm)}
                                />
                            </div>
                            <div className="pl-4">
                                <IdsText weight="bold">{`${algorithm.trmFamiliarName} > ${algorithm.sectorName} > ${algorithm.endUseName} > ${algorithm.trmMeasureName} > ${algorithm.vintageName}`}</IdsText>
                                <IdsText>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: formatAlgorithmText(`${algorithm.unit} = ${algorithm.algorithm}`),
                                        }}
                                    />
                                </IdsText>
                                <IdsText>{`Maps to: ${algorithm.permutationsCount} Standard Permutations`}</IdsText>
                            </div>
                        </div>
                    </IdsTableCell>
                </IdsTableRow>
            ))}
        </IdsTable>
    );
};
