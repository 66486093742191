import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGetAlgorithmsQuery } from "store/apiSlice";

import { useAppSelector } from "store/hooks";

import { IdsText } from "@emergn-infinity/ids-react";

import { GridFilter } from "./GridFilter";
import { SavingsCalculationsGrid } from "./SavingsCalculationsGrid";

import { sortRelAlgorithms } from "pages/BenchmarkTrm/utils";

import { filtersIcon } from "utils/icons";

export const SavingsCalculations = () => {
    const {
        selectedTrm,
        selectedTrmName,
        selectedMeasure,
        selectedMeasureName,
        selectedSector,
        selectedVintage,
        selectedFuelType,
        selectedRelAlgorithms,
    } = useAppSelector((state) => state.benchmarkTrm);

    const { data } = useGetAlgorithmsQuery(
        {
            measureNumber: selectedMeasure,
            sectorNumber: selectedSector,
            vintageNumber: selectedVintage,
        },
        { skip: isEmpty(selectedMeasure) || isEmpty(selectedSector) || isEmpty(selectedVintage) },
    );

    const selectedAlgorithm = data?.find((algorithm) => algorithm.fuelTypeNumber === selectedFuelType)?.algorithmNumber ?? "";

    const sortedRelAlgorithms = sortRelAlgorithms(
        selectedRelAlgorithms,
        selectedTrm,
        selectedTrmName,
        selectedMeasure,
        selectedMeasureName,
        selectedAlgorithm,
    );

    const filtersSelected = !isEmpty(selectedSector) && !isEmpty(selectedVintage) && !isEmpty(selectedFuelType);

    return (
        <div className="flex-column fill-height">
            <GridFilter
                selectedMeasure={selectedMeasure}
                selectedSector={selectedSector}
                selectedVintage={selectedVintage}
                selectedFuelType={selectedFuelType}
                isSavingsCalculationsBenchmark
            />
            <div className="flex-one-in-column with-scroll">
                {filtersSelected ? (
                    <SavingsCalculationsGrid
                        selectedTrmName={selectedTrmName}
                        selectedMeasureName={selectedMeasureName}
                        items={sortedRelAlgorithms}
                    />
                ) : (
                    <div className="flex-column fill-height align-center justify-center gap-2">
                        <FontAwesomeIcon icon={filtersIcon} color="var(--ids-semantic-ink-color-neutral-subtlest)" size="2xl" />
                        <IdsText
                            size="md"
                            weight="bold"
                            style={{
                                color: "var(--ids-semantic-ink-color-neutral-subtlest)",
                            }}
                        >
                            Select filters to show savings calculations
                        </IdsText>
                    </div>
                )}
            </div>
        </div>
    );
};
