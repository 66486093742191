import { isEmpty } from "lodash";
import { useMemo } from "react";
import { evaluate } from "mathjs";
import { IdsDivider, IdsHelper, IdsText } from "@emergn-infinity/ids-react";
import { formatAlgorithmText, formatNumber } from "utils/string";
import { MeasureAlgorithm, AlgorithmVariable } from "types";
import { Tile } from "components/Tile";
import { useGetAlgorithmAssumptionsQuery } from "store/apiSlice";

export const SavingsCalculationTile: React.FC<{
    algorithm?: MeasureAlgorithm;
    variables: AlgorithmVariable[];
}> = ({ algorithm, variables }) => {
    const { data: savingsCalculation, isLoading } = useGetAlgorithmAssumptionsQuery(
        { algorithmNumber: algorithm?.algorithmNumber! },
        { skip: !algorithm?.algorithmNumber },
    );

    const algorithmWithVariables = applyAlgorithmVariables(savingsCalculation?.assignedSavingsCalculation ?? "", variables);
    const unit = algorithm?.algorithm.split("=")?.[0].trim();

    const { result, errorMessage } = useMemo(() => {
        if (isEmpty(algorithmWithVariables) || variables.length === 0) {
            return {
                result: "",
                errorMessage: "",
            };
        }

        try {
            return {
                result: evaluate(algorithmWithVariables),
                errorMessage: "",
            };
        } catch (e: any) {
            return {
                result: "",
                errorMessage: variables.every((variable) => !isEmpty(variable.value?.toString())) ? e.message : "Variables not set",
            };
        }
    }, [algorithmWithVariables, variables]);

    if (!algorithm || isLoading) {
        return null;
    }

    return (
        <Tile title="Savings Calculation">
            <div className="flex-row p-3 gap-3">
                <Tile className="flex-one-in-row" title="Calculation">
                    <div className="flex-column p-3">
                        {savingsCalculation?.savingsCalculation !== savingsCalculation?.assignedSavingsCalculation && (
                            <>
                                <IdsText size="md">
                                    <div className="flex-row gap-1">
                                        <span>{unit}</span>
                                        <span>=</span>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: formatAlgorithmText(savingsCalculation?.savingsCalculation ?? ""),
                                            }}
                                        />
                                    </div>
                                </IdsText>
                                <IdsDivider spacing="sm" />
                            </>
                        )}
                        <IdsText size="md">
                            <div className="flex-row gap-1">
                                <span>{unit}</span>
                                <span>=</span>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: formatAlgorithmText(savingsCalculation?.assignedSavingsCalculation ?? ""),
                                    }}
                                />
                            </div>
                        </IdsText>
                        <IdsDivider spacing="sm" />
                        <IdsText size="md">
                            <div className="flex-row gap-1">
                                <span>{unit}</span>
                                <span>=</span>
                                <span dangerouslySetInnerHTML={{ __html: formatAlgorithmText(algorithmWithVariables) }} />
                            </div>
                        </IdsText>
                        {!isEmpty(errorMessage) && <IdsHelper helperIcon="ui-form-error_circle" isInvalid helperText={errorMessage} />}
                    </div>
                </Tile>
                <div className="flex-column align-center justify-center">
                    <IdsText weight="bold">=</IdsText>
                </div>
                <Tile className="flex-one-in-row" title="Result">
                    <div className="flex-column fill-height justify-center p-3">
                        <IdsText>
                            <strong>{`${unit} = ${formatNumber(result)}`}</strong>
                        </IdsText>
                    </div>
                </Tile>
            </div>
        </Tile>
    );
};

const applyAlgorithmVariables = (algorithm: string, variables: AlgorithmVariable[]) => {
    let result = algorithm;

    variables.forEach((variable) => {
        // Determine if variable is part of equation
        const equation = variables.find((v) => v.equation?.includes(`<${variable.name}>`));
        // Determine if equation is overridden with user input
        const isEquationOverridden = equation ? equation.value !== "" : false;

        if (variable.value !== undefined && variable.value !== "") {
            // If equation is overriden with user input
            // then equation variables will not be substituted
            // and therefore equation can be replaced with user input
            if (variable.equation) {
                result = result.replaceAll(`(${variable.equation})`, `${variable.value}`);
            }
            // If equation is not overriden with user input
            // substitute equation variables with assigned values
            else if (!isEquationOverridden) {
                result = result.replaceAll(`<${variable.name}>`, `${variable.value}`);
            }
        }
    });

    // remove text surrounded by []
    result = result.replaceAll(/\[.*?\]/g, "");

    return result;
};
