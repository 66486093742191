import { IdsText, IdsButton } from "@emergn-infinity/ids-react";

import { IconButton } from "components/IconButton";

import { closeIcon } from "utils/icons";

export const TourContent: React.FC<{
    header: string;
    text: string;
    variant: "primary" | "secondary";
    currentPage?: number;
    totalPages?: number;
    onClick: () => void;
    onClose?: () => void;
}> = ({ header, text, variant, currentPage, totalPages, onClick, onClose }) => {
    return (
        <div className="flex-column fill-height justify-space-between gap-4">
            <div className="flex-column gap-3">
                <div>
                    {variant === "primary" && (
                        <IdsText size="md" weight="bold" style={{ color: "var(--ids-semantic-ink-color-brand-a-accent)" }}>
                            Getting started
                        </IdsText>
                    )}
                    <div className="flex-row align-center justify-space-between">
                        <IdsText
                            component="h4"
                            size={variant === "primary" ? "md" : "sm"}
                            weight="bold"
                            style={{ color: "var(--body-text-body-color)" }}
                        >
                            <>{header}</>
                        </IdsText>
                        {variant === "secondary" && (
                            <IconButton
                                icon={closeIcon}
                                color="var(--button-icon-color-neutral-tertiary-default)"
                                size="xl"
                                onClick={onClose}
                            />
                        )}
                    </div>
                </div>
                <div>
                    <IdsText size="md" style={{ color: "var(--theme-secondary)" }}>
                        <>{text}</>
                    </IdsText>
                </div>
            </div>
            <div className="flex-row align-center justify-space-between">
                {variant === "primary" && (
                    <IdsButton variant="primary" clickHandler={onClick}>
                        Start tour
                    </IdsButton>
                )}
                {variant === "secondary" && (
                    <>
                        <IdsText size="md" style={{ color: "var(--theme-secondary)" }}>
                            <>{`${currentPage} of ${totalPages}`}</>
                        </IdsText>
                        <IdsButton variant="primary" clickHandler={currentPage !== totalPages ? onClick : onClose}>
                            <>{currentPage !== totalPages ? "Next" : "Got it"}</>
                        </IdsButton>
                    </>
                )}
            </div>
        </div>
    );
};
