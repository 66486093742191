import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

import { IdsButton } from "@emergn-infinity/ids-react";

import "./style.scss";

// Currently supported sizes are - default, lg, xl
export const IconButton: React.FC<{
    icon: IconProp;
    color?: string;
    size?: SizeProp;
    title?: string;
    onClick?: () => void;
}> = ({ icon, color, size, title, onClick }) => {
    return (
        <IdsButton customClasses={cn("icon-button", size)} variant="tertiary" title={title ?? ""} clickHandler={onClick}>
            <FontAwesomeIcon icon={icon} color={color} size={size} fixedWidth />
        </IdsButton>
    );
};
