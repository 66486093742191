import { isEmpty } from "lodash";

import { IdsDropdown } from "@emergn-infinity/ids-react";

// NOTE: If stdSectorsList on initial render in an empty array
// initialSelectedItems will be set, but will not show up
// in the dropdown field.
export const StandardSector: React.FC<{
    stdSectorNumber: string;
    stdSectorsList: {
        label: string;
        value: string;
    }[];
    onSelect: (value: string) => void;
}> = ({ stdSectorNumber, stdSectorsList, onSelect }) => {
    return (
        <IdsDropdown
            idValue="select-standard-sector"
            isSearchable
            items={stdSectorsList}
            placeholder="Select Standard Sector"
            initialSelectedItems={isEmpty(stdSectorNumber) ? null : [stdSectorNumber]}
            changeHandler={onSelect}
            clearHandler={() => onSelect("")}
        />
    );
};
