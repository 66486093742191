import { createSlice } from "@reduxjs/toolkit";

import { TourItems } from "./utils";

import type { PayloadAction } from "@reduxjs/toolkit";

export const TOUR_STORAGE_KEY = "tour-complete";

const enabled = localStorage.getItem(TOUR_STORAGE_KEY) === null;

// Preload images
if (enabled) {
    Object.keys(TourItems).forEach((key) => {
        new Image().src = TourItems[key].imageUrl;
    });
}

const initialState: TourState = {
    enabled,
    page: "Welcome",
};

export const tourSlice = createSlice({
    name: "tour",
    initialState,
    reducers: {
        tourNextPage: (state, action: PayloadAction<TourNextPagePayload>) => {
            state.page = action.payload.page;
        },
        tourComplete: (state) => {
            state.enabled = false;

            localStorage.setItem(TOUR_STORAGE_KEY, "true");
        },
    },
});

export type TourPage = keyof typeof TourItems;

type TourState = {
    enabled: boolean;
    page: TourPage;
};

type TourNextPagePayload = {
    page: TourPage;
};

export const { tourNextPage, tourComplete } = tourSlice.actions;

export default tourSlice.reducer;
