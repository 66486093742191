import { USER_SAVE, USER_DELETE } from "store/actionTypes";

import { deleteUser, getUser } from "utils/user";

import { User } from "types";

const initialState = null;

export const reducer = (state: UserState | null = initialState, action: UserAction) => {
    state = state || getUser();

    switch (action.type) {
        case USER_SAVE:
            state = action.user;

            break;
        case USER_DELETE:
            deleteUser();

            state = null;

            break;
        default:
            break;
    }

    return state;
};

interface SaveUserAction {
    type: "USER_SAVE";
    user: User;
}

interface DeleteUserAction {
    type: "USER_DELETE";
}

type UserState = User;

type UserAction = SaveUserAction | DeleteUserAction;
