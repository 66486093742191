import { memo, useCallback, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { forgotPassword, resetLogin } from "store/login/actions";

import { IdsButton } from "@emergn-infinity/ids-react";

import IconLoading from "components/IconLoading";
import Input from "components/Input";
import Captcha from "components/Captcha";
import FieldGroup from "components/FieldGroup";
import ErrorMsg from "components/StatusMsg/ErrorMsg";

import FormWrap from "pages/Login/FormWrap";

import { openWindowLogin } from "store/window/actions";

import "./style.scss";

const ForgotPassword = memo(() => {
    const dispatch = useAppDispatch();

    const login = useAppSelector((state) => state.login);

    const [username, setUsername] = useState("");
    const [recaptcha, setRecaptcha] = useState<string | null>(null);

    const captchaRef = useRef<ReCAPTCHA>(null);

    const isValid = useCallback(() => {
        return username.trim().length > 0 && recaptcha !== null;
    }, [username, recaptcha]);

    const onChange = useCallback((event, handler) => {
        handler(event.target.value);
    }, []);

    const onSubmit = useCallback(
        (event) => {
            event.preventDefault();

            if (isValid()) {
                // @ts-ignore
                dispatch(forgotPassword({ userName: username, recaptcha }));
            }
        },
        [username, recaptcha, isValid, dispatch],
    );

    const onRedirect = useCallback(() => {
        // If e-mail with password has been sent
        if (login.infoMessage) {
            // @ts-ignore
            dispatch(resetLogin());
        } else {
            // @ts-ignore
            dispatch(openWindowLogin());
        }
    }, [login.infoMessage, dispatch]);

    return (
        <FormWrap>
            <form className="flex-column forgot-password-form" onSubmit={onSubmit}>
                {login.infoMessage ? (
                    <>
                        <h3 className="text-center">Check in your e-mail!</h3>
                        <p className="text-center forgot-password-form__info-message">
                            We just e-mailed you with the instructions to reset your password.
                        </p>
                        <div className="text-center forgot-password-form__redirect" onClick={onRedirect}>
                            Return to login page
                        </div>
                    </>
                ) : (
                    <>
                        {login.isLoading ? (
                            <div className="flex-column flex-one align-center justify-center">
                                <IconLoading />
                            </div>
                        ) : (
                            <>
                                {login.errorMessage && (
                                    <div className="forgot-password-form__error-message">
                                        <ErrorMsg largeMsg message={login.errorMessage} />
                                    </div>
                                )}
                                <div className="text-center forgot-password-form__header">Forgot your password?</div>
                                <FieldGroup className="forgot-password-form__inputs">
                                    <div>
                                        <p>No problem. Just enter your username below and we'll send you an e-mail with instructions.</p>
                                        <Input
                                            label="Username"
                                            name="userName"
                                            placeholder="Enter your username"
                                            value={username}
                                            autoFocus
                                            onChange={(event) => onChange(event, setUsername)}
                                        />
                                    </div>
                                    <div className="flex-column align-center">
                                        <Captcha captchaRef={captchaRef} onChange={(token) => setRecaptcha(token)} />
                                    </div>
                                </FieldGroup>
                            </>
                        )}
                        <div className="flex-row align-center justify-space-between">
                            <span className="forgot-password-form__redirect" onClick={onRedirect}>
                                Return to login page
                            </span>
                            <IdsButton
                                type="submit"
                                variant="primary"
                                isDisabled={login.isLoading || !isValid()}
                                isLoading={login.isLoading}
                            >
                                <>{login.isLoading ? "Loading..." : "Request password"}</>
                            </IdsButton>
                        </div>
                    </>
                )}
            </form>
        </FormWrap>
    );
});

export default ForgotPassword;
