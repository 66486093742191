import { isEmpty } from "lodash";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IdsTableRow, IdsTableCell, IdsText, IdsTag, IdsDropdown, IdsButtonGroup } from "@emergn-infinity/ids-react";

import { useGridMeasureLivesQuery } from "store/apiSlice";

import { GridType, GridStyle } from "components/Grids/MeasureLivesGrid";
import { IconButton } from "components/IconButton";
import { Tooltip } from "components/Tooltip";

import { EulCell } from "./EulCell";

import { Tabs } from "pages/Home/utils";

import { deleteIcon, editIcon, linkIcon } from "utils/icons";
import { formatNumber } from "utils/string";

export const MeasureLivesGridRows: React.FC<{
    gridType: GridType;
    styles: GridStyle;
    measureNumber?: string;
    trmFamiliarName?: string;
    measure?: string;
    sector?: string;
    editRights?: boolean;
    onEdit?: (eulNumber: string) => void;
    onDelete?: (eulNumber: string) => void;
}> = ({ gridType, styles, measureNumber, trmFamiliarName, measure, sector, editRights, onEdit, onDelete }) => {
    const [additionalDetails, setAdditionalDetails] = useState<
        {
            id: number;
            lookupNumber: string;
        }[]
    >([]);

    const { data } = useGridMeasureLivesQuery({ measureNumber: measureNumber! }, { skip: !measureNumber });
    let tableData = isEmpty(measureNumber) ? [] : data;

    if (gridType === Tabs.BenchmarkTrm.id) {
        const benchmarkData = data?.find((measureLife) => measureLife.sector === sector) ?? data?.[0];

        tableData = benchmarkData ? [benchmarkData] : [];
    }

    const onAdditionalDetailsChange = (id: number, lookupNumber?: string) => {
        setAdditionalDetails((prev) => {
            if (!lookupNumber) {
                return prev.filter((detail) => detail.id !== id);
            }

            const index = prev.findIndex((detail) => detail.id === id);

            if (index === -1) {
                return [...prev, { id, lookupNumber }];
            }

            return prev.map((detail, i) => (i === index ? { id, lookupNumber } : detail));
        });
    };

    return tableData?.map((item) => (
        <IdsTableRow key={item.id} rowType="table-body-row">
            {gridType === Tabs.BenchmarkTrm.id && (
                <IdsTableCell style={styles.trmAndMeasure}>
                    <IdsText size="md">{`${trmFamiliarName} > ${measure}`}</IdsText>
                </IdsTableCell>
            )}
            <IdsTableCell style={styles.sector}>
                <IdsTag variant="brand-c" size="sm">
                    {item.sector}
                </IdsTag>
            </IdsTableCell>
            <IdsTableCell style={styles.vintage}>
                <IdsTag size="sm">{item.vintage}</IdsTag>
            </IdsTableCell>
            <IdsTableCell style={styles.lookupSelection}>
                <div>
                    {!isEmpty(item.additionalDetails) && (
                        <IdsDropdown
                            size="sm"
                            idValue="measure-lives-additional-details"
                            placeholder=" "
                            items={item.additionalDetails.map((detail) => ({
                                value: detail.lookupNumber,
                                label: detail.lookupCriteria,
                            }))}
                            changeHandler={(lookupNumber) => onAdditionalDetailsChange(item.id, lookupNumber)}
                            clearHandler={() => onAdditionalDetailsChange(item.id)}
                            style={{ width: "auto" }}
                        />
                    )}
                </div>
            </IdsTableCell>
            <IdsTableCell style={styles.eul}>
                <IdsText size="md">
                    <EulCell item={item} lookupNumber={additionalDetails.find((detail) => detail.id === item.id)?.lookupNumber} />
                </IdsText>
            </IdsTableCell>
            <IdsTableCell style={styles.rul}>
                <IdsText size="md">{formatNumber(item.rul)}</IdsText>
            </IdsTableCell>
            <IdsTableCell style={styles.source}>
                {!isEmpty(item.source) && (
                    <Tooltip message={item.source}>
                        <IdsTag size="sm">
                            <div className="flex-row gap-2 align-center">
                                <FontAwesomeIcon icon={linkIcon} fixedWidth />
                                {item.sourceYear ? item.sourceYear : "-"}
                            </div>
                        </IdsTag>
                    </Tooltip>
                )}
            </IdsTableCell>
            {gridType === Tabs.ExploreTrms.id && editRights && (
                <IdsTableCell style={{ ...styles.action, verticalAlign: "middle" }}>
                    <IdsButtonGroup spaceBetween="sm" position="right" customClasses="flex-no-wrap">
                        {onDelete && (
                            <IconButton icon={deleteIcon} size="lg" title="Delete Measure Life" onClick={() => onDelete(item.eulNumber)} />
                        )}
                        {onEdit && (
                            <IconButton icon={editIcon} size="lg" title="Edit Measure Life" onClick={() => onEdit(item.eulNumber)} />
                        )}
                    </IdsButtonGroup>
                </IdsTableCell>
            )}
        </IdsTableRow>
    ));
};
