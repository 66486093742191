import { useAppSelector } from "store/hooks";

import { MeasureCostsGrid } from "components/Grids/MeasureCostsGrid";

import { Tabs } from "pages/Home/utils";
import { sortRelAlgorithms } from "pages/BenchmarkTrm/utils";

import { RelevantAlgorithm } from "types";
import { GridFilter } from "./GridFilter";
import { useMemo } from "react";

export const MeasureCosts = () => {
    const {
        selectedTrm,
        selectedTrmName,
        selectedMeasure,
        selectedMeasureName,
        selectedSector,
        selectedVintage,
        selectedFuelType,
        selectedRelAlgorithms,
    } = useAppSelector((state) => state.benchmarkTrm);

    const sortedRelAlgorithms = useMemo(() => {
        // take items with unique measureNumber
        const relAlgorithmsWithUniqueMeasure = selectedRelAlgorithms.reduce((acc, item) => {
            if (!acc.find((a) => a.measureNumber === item.measureNumber)) {
                acc.push(item);
            }

            return acc;
        }, [] as RelevantAlgorithm[]);

        return sortRelAlgorithms(relAlgorithmsWithUniqueMeasure, selectedTrm, selectedTrmName, selectedMeasure, selectedMeasureName);
    }, [selectedMeasure, selectedMeasureName, selectedRelAlgorithms, selectedTrm, selectedTrmName]);

    return (
        <div className="flex-column fill-height">
            <GridFilter
                selectedMeasure={selectedMeasure}
                selectedSector={selectedSector}
                selectedVintage={selectedVintage}
                selectedFuelType={selectedFuelType}
            />
            <div className="flex-one-in-column with-scroll">
                <MeasureCostsGrid gridType={Tabs.BenchmarkTrm.id} items={sortedRelAlgorithms} />
            </div>
        </div>
    );
};
