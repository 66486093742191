import { IdsTableRow, IdsTableCell, IdsText } from "@emergn-infinity/ids-react";

import { formatAlgorithmText } from "utils/string";

import { GridStyle, Header } from "pages/BenchmarkTrm/MainPanel/SavingsCalculationsGrid";

export const SavingsCalculationsGridHeader: React.FC<{
    headers: Header[];
    styles: GridStyle;
}> = ({ headers, styles }) => {
    return (
        <IdsTableRow rowType="table-heading-row" customClasses="sticky-top">
            <IdsTableCell
                cellType="table-heading-cell"
                heading="Variable Description"
                style={{ ...styles.variableDescription, verticalAlign: "bottom", position: "sticky", left: 0 }}
            />
            {headers.map((header, index) => (
                <IdsTableCell key={`${header.trmFamiliarName}-${header.measureName}-${index}`} style={styles.relAlgorithm}>
                    <IdsText weight="bold" size="md">
                        {header.trmFamiliarName}
                    </IdsText>
                    <IdsText weight="bold" size="md">
                        {header.measureName}
                    </IdsText>
                    <IdsText size="sm">
                        <div dangerouslySetInnerHTML={{ __html: formatAlgorithmText(header.formattedAlgorithm) }} />
                    </IdsText>
                </IdsTableCell>
            ))}
        </IdsTableRow>
    );
};
