import { useEffect, useState } from "react";

// Taken from src/assets/sass/_mixins
export const XS_BREAKPOINT = "(max-width: 768px)";
export const SM_BREAKPOINT = "(min-width: 768px)";
export const MD_BREAKPOINT = "(min-width: 1024px)";
export const LG_BREAKPOINT = "(min-width: 1440px)";
export const XL_BREAKPOINT = "(min-width: 1920px)";

// Source from: https://usehooks-ts.com/react-hook/use-media-query
export function useMediaQuery(breakpoint: Breakpoint): boolean {
    let query = XS_BREAKPOINT;

    if (breakpoint === "sm") {
        query = SM_BREAKPOINT;
    } else if (breakpoint === "md") {
        query = MD_BREAKPOINT;
    } else if (breakpoint === "lg") {
        query = LG_BREAKPOINT;
    } else if (breakpoint === "xl") {
        query = XL_BREAKPOINT;
    }

    const getMatches = (query: string): boolean => {
        // Prevents SSR issues
        if (typeof window !== "undefined") {
            return window.matchMedia(query).matches;
        }
        return false;
    };

    const [matches, setMatches] = useState<boolean>(getMatches(query));

    function handleChange() {
        setMatches(getMatches(query));
    }

    useEffect(() => {
        const matchMedia = window.matchMedia(query);

        // Triggered at the first client-side load and if query changes
        handleChange();

        // Listen matchMedia
        // Use deprecated `addListener` and `removeListener` to support Safari < 14
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange);
        } else {
            matchMedia.addEventListener("change", handleChange);
        }

        return () => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange);
            } else {
                matchMedia.removeEventListener("change", handleChange);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return matches;
}

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";
