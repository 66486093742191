import { IdsButton, IdsButtonGroup, IdsModal, IdsText } from "@emergn-infinity/ids-react";
import { useDeleteCostMutation } from "store/apiSlice";
import { Measure } from "types";
import { EditFormTags } from "../EditFormTags";
import { DELETE_MODAL_STYLE } from "../utils";

export const MeasureCostDelete: React.FC<{
    costNumber: string;
    measure: Measure;
    onClose: () => void;
}> = ({ costNumber, measure, onClose }) => {
    const [deleteCost, deleteStatus] = useDeleteCostMutation();

    const onDelete = async () => {
        // Do nothing if deleting is in progress
        if (deleteStatus.isLoading) {
            return;
        }

        try {
            await deleteCost({ costNumber }).unwrap();
            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Delete Measure Cost
                </IdsText>
            </div>
            <div slot="main" style={DELETE_MODAL_STYLE}>
                <EditFormTags trmName={measure.trmFamiliarName} measureName={measure.measureName} />
                <IdsText component="p">Are you sure you want to delete this measure cost?</IdsText>
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton variant="primary" clickHandler={onDelete} color="system-critical">
                        <>{deleteStatus.isLoading ? "Deleting..." : "Delete"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};
