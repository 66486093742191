import { useState } from "react";
import { Measure, MeasureAlgorithm } from "types";
import { AlgorithmVariablesModal } from "./AlgorithmVariablesModal";
import { AlgorithmModal } from "./AlgorithmModal";
import { isEmpty } from "lodash";

export const AlgorithmEdit: React.FC<{
    measure: Measure;
    algorithmNumber?: string;
    onAdded?: (algorithm: MeasureAlgorithm) => void;
    onClose: () => void;
}> = ({ measure, algorithmNumber, onAdded, onClose }) => {
    const [createdAlgorithmNumber, setCreatedAlgorithmNumber] = useState<string>();
    const [createdAlgorithm, setCreatedAlgorithm] = useState<string>();
    const [editVariables, setEditVariables] = useState(false);

    const onAlgorithmAdded = (algorithm: MeasureAlgorithm) => {
        setCreatedAlgorithmNumber(algorithm.algorithmNumber);
        setCreatedAlgorithm(algorithm.algorithm);
        setEditVariables(true);
        onAdded?.(algorithm);
    };

    const title = isEmpty(algorithmNumber) ? "Add Algorithm" : "Edit Algorithm";

    if (editVariables && createdAlgorithmNumber && createdAlgorithm) {
        return (
            <AlgorithmVariablesModal
                title={title}
                algorithmNumber={createdAlgorithmNumber}
                algorithm={createdAlgorithm}
                measure={measure}
                onClose={onClose}
            />
        );
    }

    return (
        <AlgorithmModal title={title} measure={measure} algorithmNumber={algorithmNumber} onClose={onClose} onAdded={onAlgorithmAdded} />
    );
};
