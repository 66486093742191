export const STANDARDIZE_HEADER_HEIGHT = 64;

export const TrmAttributes = {
    Sectors: {
        id: "sectors",
        name: "Sector",
        fullName: "TRM Sectors",
    },
    EndUses: {
        id: "endUses",
        name: "End Use",
        fullName: "TRM End Uses",
    },
    Measures: {
        id: "measures",
        name: "Measures",
        fullName: "TRM Measures",
    },
    Vintages: {
        id: "vintages",
        name: "Vintage",
        fullName: "TRM Vintages",
    },
    Algorithms: {
        id: "algorithms",
        name: "Algorithms",
        fullName: "TRM Algorithms",
    },
    Variables: {
        id: "variables",
        name: "Variable",
        fullName: "TRM Variables",
    },
} as const;

export const getFilteredItems = <T>(items: T[], showAll: boolean, search: string, nameKey: string, sugStdKey: string) => {
    let filteredItems = [...items];

    if (!showAll) {
        filteredItems = filteredItems.filter((item) => item[sugStdKey] === null);
    }

    // TODO: Add later
    // if (hideInactive) {
    //     //
    // }

    if (search.length > 0) {
        filteredItems = filteredItems.filter((item) => item[nameKey].toLowerCase().includes(search.toLowerCase()));
    }

    return filteredItems;
};
