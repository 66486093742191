import { isEmpty } from "lodash";
import { useMemo, useState } from "react";

import { useGetSectorsQuery, useGetStdSectorsQuery, useUpdateStandardizingForSectorMutation } from "store/apiSlice";

import { Standardize } from "pages/StandardizeData/MainPanel/common/Standardize";
import { SuggestedStandard } from "pages/StandardizeData/MainPanel/common/SuggestedStandard";

import { SectorsTable } from "./SectorsTable";
import { StandardSector } from "./StandardSector";

import { getFilteredItems } from "pages/StandardizeData/utils";

import { usePagination } from "utils/usePagination";

import { Sector } from "types";

export const Sectors: React.FC<{
    id: string;
    name: string;
    fullName: string;
    showAll: boolean;
    hideInactive: boolean;
    search: string;
    pageSize: number;
    pageNumber: number;
    onChange: (e: any, name: string) => void;
}> = ({ id, name, fullName, showAll, hideInactive, search, pageSize, pageNumber, onChange }) => {
    const [activeSector, setActiveSector] = useState<Sector | null>(null);
    const [selectedSectors, setSelectedSectors] = useState<Sector[]>([]);

    const [stdSectorNumber, setStdSectorNumber] = useState("");

    const fetchStdSectors = activeSector !== null || !isEmpty(selectedSectors); // only fetch standard sectors when a sector is selected
    const standardizeDisabled = isEmpty(selectedSectors) || stdSectorNumber === "";

    const { data: sectors } = useGetSectorsQuery({});

    const { data: stdSectors, isLoading: isLoadingStdSectors } = useGetStdSectorsQuery(undefined, { skip: !fetchStdSectors });

    const [updateSectorStandardizing, updateSectorStandardizingStatus] = useUpdateStandardizingForSectorMutation();

    const filteredSectors = useMemo(() => {
        let filteredSectors: Sector[] = [];

        if (sectors !== undefined) {
            filteredSectors = getFilteredItems(sectors, showAll, search, "sectorName", "sugStdSectorNumber");
        }

        return filteredSectors;
    }, [showAll, search, sectors]);

    const stdSectorsList = useMemo(() => {
        return (stdSectors ?? []).map((sector) => ({
            label: sector.stdSectorName,
            value: sector.stdSectorNumber,
        }));
    }, [stdSectors]);

    const sectorsForStandardizing = usePagination(filteredSectors, { pageSize, pageNumber });

    const allSelected = selectedSectors.length === sectorsForStandardizing.items.length && !isEmpty(sectorsForStandardizing.items);

    const onSelectAll = () => {
        let newSelectedSectors: Sector[] = [];

        // Select all sectors
        if (sectors !== undefined && !allSelected) {
            newSelectedSectors = sectorsForStandardizing.items.map((sector) => sector);
        }

        setSelectedSectors(newSelectedSectors);
    };

    const onPageChange = (pageNumber: number) => {
        setSelectedSectors([]);

        onChange(pageNumber, "pageNumber");
    };

    const onSectorClick = (sector: Sector) => {
        setActiveSector(sector);

        setStdSectorNumber(sector.sugStdSectorNumber);
    };

    const onSectorChecked = (sector: Sector) => {
        let newSelectedSectors: Sector[] = [];

        // Uncheck sector
        if (selectedSectors.find((s) => s.sectorNumber === sector.sectorNumber)) {
            newSelectedSectors = selectedSectors.filter((s) => s.sectorNumber !== sector.sectorNumber);
        }
        // Check sector
        else {
            newSelectedSectors = [...selectedSectors];

            newSelectedSectors.push(sector);
        }

        setSelectedSectors(newSelectedSectors);
    };

    const onSelectStandardSector = (value: string) => {
        setStdSectorNumber(value);
    };

    const onStandardizeClick = async () => {
        // Do nothing if saving is in progress
        if (updateSectorStandardizingStatus.isLoading) {
            return;
        }

        // To update standardizing for sector,
        // a sector has to be selected
        // and standard sector has to be selected
        if (!isEmpty(selectedSectors) && stdSectorNumber !== "") {
            const dateActivated = new Date(Date.now()).toISOString();

            const sectors = selectedSectors.map((sector) => ({
                sectorNumber: sector.sectorNumber,
                stdSectorNumber,
                dateActivated,
            }));
            try {
                await updateSectorStandardizing({ sectors }).unwrap();
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className="flex-row fill-height">
            <div className="w-70 flex-grow pr-4">
                {sectors !== undefined && (
                    <Standardize
                        name={name}
                        fullName={fullName}
                        showAll={showAll}
                        hideInactive={hideInactive}
                        search={search}
                        allSelected={allSelected}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        tableComponent={
                            <SectorsTable
                                activeSector={activeSector}
                                selectedSectors={selectedSectors}
                                standardizingList={sectorsForStandardizing.items}
                                onClick={onSectorClick}
                                onChange={onSectorChecked}
                            />
                        }
                        totalRows={sectorsForStandardizing.totalRows}
                        totalPages={sectorsForStandardizing.totalPages || 1} // 0 causes issues for IdsTablePagination
                        onSelectAll={onSelectAll}
                        onChange={onChange}
                        onPageChange={onPageChange}
                    />
                )}
            </div>
            <div className="vertical-divider" />
            {/* NOTE: Key here causes small freeze up and errors in console,
                      but otherwise dropdown default values do not get updated */}
            <div
                key={activeSector === null ? "sector-none" : `sector-${activeSector.sectorNumber}-${selectedSectors.length}`}
                className="w-30 flex-grow pl-4"
            >
                {fetchStdSectors && !isLoadingStdSectors && (
                    <SuggestedStandard
                        id={id}
                        name={name}
                        standardComponent={
                            <StandardSector
                                stdSectorNumber={stdSectorNumber}
                                stdSectorsList={stdSectorsList}
                                onSelect={onSelectStandardSector}
                            />
                        }
                        standardizeDisabled={standardizeDisabled}
                        onStandardizeClick={onStandardizeClick}
                    />
                )}
            </div>
        </div>
    );
};
