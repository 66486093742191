import { isEmpty } from "lodash";
import { useMemo, useState } from "react";

import { useGetVintagesQuery, useGetStdVintagesQuery, useUpdateStandardizingForVintageMutation } from "store/apiSlice";

import { Standardize } from "pages/StandardizeData/MainPanel/common/Standardize";
import { SuggestedStandard } from "pages/StandardizeData/MainPanel/common/SuggestedStandard";

import { VintagesTable } from "./VintagesTable";
import { StandardVintage } from "./StandardVintage";

import { getFilteredItems } from "pages/StandardizeData/utils";

import { usePagination } from "utils/usePagination";

import { Vintage } from "types";

export const Vintages: React.FC<{
    id: string;
    name: string;
    fullName: string;
    showAll: boolean;
    hideInactive: boolean;
    search: string;
    pageSize: number;
    pageNumber: number;
    onChange: (e: any, name: string) => void;
}> = ({ id, name, fullName, showAll, hideInactive, search, pageSize, pageNumber, onChange }) => {
    const [activeVintage, setActiveVintage] = useState<Vintage | null>(null);
    const [selectedVintages, setSelectedVintages] = useState<Vintage[]>([]);

    const [stdVintageNumber, setStdVintageNumber] = useState("");

    const fetchStdVintages = activeVintage !== null || !isEmpty(selectedVintages); // only fetch standard vintages when a vintage is selected
    const standardizeDisabled = isEmpty(selectedVintages) || stdVintageNumber === "";

    const { data: vintages } = useGetVintagesQuery({});

    const { data: stdVintages, isLoading: isLoadingStdVintages } = useGetStdVintagesQuery(undefined, { skip: !fetchStdVintages });

    const [updateVintageStandardizing, updateVintageStandardizingStatus] = useUpdateStandardizingForVintageMutation();

    const filteredVintages = useMemo(() => {
        let filteredVintages: Vintage[] = [];

        if (vintages !== undefined) {
            filteredVintages = getFilteredItems(vintages, showAll, search, "vintageName", "sugVintageNumber");
        }

        return filteredVintages;
    }, [showAll, search, vintages]);

    const stdVintagesList = useMemo(() => {
        return (stdVintages ?? []).map((vintage) => ({
            label: vintage.stdVintageName,
            value: vintage.stdVintageNumber,
        }));
    }, [stdVintages]);

    const vintagesForStandardizing = usePagination(filteredVintages, { pageSize, pageNumber });

    const allSelected = selectedVintages.length === vintagesForStandardizing.items.length && !isEmpty(vintagesForStandardizing.items);

    const onSelectAll = () => {
        let newSelectedVintages: Vintage[] = [];

        // Select all vintages
        if (vintages !== undefined && !allSelected) {
            newSelectedVintages = vintagesForStandardizing.items.map((vintage) => vintage);
        }

        setSelectedVintages(newSelectedVintages);
    };

    const onPageChange = (pageNumber: number) => {
        setSelectedVintages([]);

        onChange(pageNumber, "pageNumber");
    };

    const onVintageClick = (vintage: Vintage) => {
        setActiveVintage(vintage);

        setStdVintageNumber(vintage.sugVintageNumber);
    };

    const onVintageChecked = (vintage: Vintage) => {
        let newSelectedVintages: Vintage[] = [];

        // Uncheck vintage
        if (selectedVintages.find((v) => v.vintageNumber === vintage.vintageNumber)) {
            newSelectedVintages = selectedVintages.filter((v) => v.vintageNumber !== vintage.vintageNumber);
        }
        // Check vintage
        else {
            newSelectedVintages = [...selectedVintages];

            newSelectedVintages.push(vintage);
        }

        setSelectedVintages(newSelectedVintages);
    };

    const onSelectStandardVintage = (value: string) => {
        setStdVintageNumber(value);
    };

    const onStandardizeClick = async () => {
        // Do nothing if saving is in progress
        if (updateVintageStandardizingStatus.isLoading) {
            return;
        }

        // To update standardizing for vintage,
        // a vintage has to be selected
        // and standard vintage has to be selected
        if (!isEmpty(selectedVintages) && stdVintageNumber !== "") {
            const dateActivated = new Date(Date.now()).toISOString();

            const vintages = selectedVintages.map((vintage) => ({
                vintageNumber: vintage.vintageNumber,
                stdVintageNumber,
                dateActivated,
            }));
            try {
                await updateVintageStandardizing({ vintages }).unwrap();
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className="flex-row fill-height">
            <div className="w-70 flex-grow pr-4">
                {vintages !== undefined && (
                    <Standardize
                        name={name}
                        fullName={fullName}
                        showAll={showAll}
                        hideInactive={hideInactive}
                        search={search}
                        allSelected={allSelected}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        tableComponent={
                            <VintagesTable
                                activeVintage={activeVintage}
                                selectedVintages={selectedVintages}
                                standardizingList={vintagesForStandardizing.items}
                                onClick={onVintageClick}
                                onChange={onVintageChecked}
                            />
                        }
                        totalRows={vintagesForStandardizing.totalRows}
                        totalPages={vintagesForStandardizing.totalPages || 1} // 0 causes issues for IdsTablePagination
                        onSelectAll={onSelectAll}
                        onChange={onChange}
                        onPageChange={onPageChange}
                    />
                )}
            </div>
            <div className="vertical-divider" />
            {/* NOTE: Key here causes small freeze up and errors in console,
                      but otherwise dropdown default values do not get updated */}
            <div
                key={activeVintage === null ? "vintage-none" : `vintage-${activeVintage.vintageNumber}-${selectedVintages.length}`}
                className="w-30 flex-grow pl-4"
            >
                {fetchStdVintages && !isLoadingStdVintages && (
                    <SuggestedStandard
                        id={id}
                        name={name}
                        standardComponent={
                            <StandardVintage
                                stdVintageNumber={stdVintageNumber}
                                stdVintagesList={stdVintagesList}
                                onSelect={onSelectStandardVintage}
                            />
                        }
                        standardizeDisabled={standardizeDisabled}
                        onStandardizeClick={onStandardizeClick}
                    />
                )}
            </div>
        </div>
    );
};
