import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { IdsListItem, IdsButton, IdsText } from "@emergn-infinity/ids-react";

import { chevronRightIcon } from "utils/icons";

export const MenuItem: React.FC<{
    variant?: "option" | "divider" | "action";
    heading?: string;
    iconRight?: boolean;
    customIconRight?: React.ReactElement;
    iconLeft?: IconProp;
    iconLeftStyle?: React.CSSProperties;
    actionDisabled?: boolean;
    itemValue?: string;
    isOpen: boolean;
    onClick?: () => void;
}> = ({ variant = "option", heading, iconRight, customIconRight, iconLeft, iconLeftStyle, actionDisabled, itemValue, isOpen, onClick }) => {
    if (variant === "divider") {
        return <IdsListItem customClasses="no-margin" variant={variant} />;
    } else if (variant === "action") {
        return (
            <IdsListItem customClasses="p-1">
                <IdsButton
                    customClasses={cn("menu-action-btn", { "menu-action-btn--active": isOpen })}
                    variant="tertiary"
                    isDisabled={actionDisabled}
                    padding="sm"
                    fullWidth
                    clickHandler={onClick}
                >
                    <div className="flex-row align-center justify-space-between">
                        <div className="flex-row align-center gap-2">
                            {iconLeft && <FontAwesomeIcon icon={iconLeft} size="lg" />}
                            {itemValue}
                        </div>
                        {iconRight && <FontAwesomeIcon icon={chevronRightIcon} size="lg" />}
                    </div>
                </IdsButton>
            </IdsListItem>
        );
    }

    return (
        <IdsListItem customClasses="px-3">
            <div className="flex-column fill-width">
                {heading && (
                    <IdsText customClasses="menu-heading" size="md" weight="bold">
                        {heading}
                    </IdsText>
                )}
                <div className="flex-row align-center justify-space-between py-3">
                    <div className="flex-row align-center gap-2">
                        {iconLeft && (
                            <FontAwesomeIcon
                                icon={iconLeft}
                                color={iconLeftStyle?.color ?? "var(--ids-semantic-ink-color-brand-b-subtlest)"}
                            />
                        )}
                        <IdsText>{itemValue}</IdsText>
                    </div>
                    {iconRight && !customIconRight && (
                        <FontAwesomeIcon icon={chevronRightIcon} size="lg" color="var(--ids-semantic-ink-color-brand-b-subtlest)" />
                    )}
                    {customIconRight ?? null}
                </div>
            </div>
        </IdsListItem>
    );
};
