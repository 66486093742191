import { useGetPowerBiReportQuery } from "store/apiSlice";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "utils/useMediaQuery";
import * as pbi from "powerbi-client";
import cn from "classnames";
import { IdsHelper } from "@emergn-infinity/ids-react";
import ViewPlaceholder from "components/ViewPlaceholder";

import "./style.scss";

export const PowerBiReport: React.FC<{
    reportId: string;
    navContentPaneEnabled?: boolean;
}> = (props) => {
    const { reportId, navContentPaneEnabled } = props;

    const { data: report, isLoading, error } = useGetPowerBiReportQuery({ reportId });

    if (!report && isLoading) {
        return (
            <ViewPlaceholder>
                <IdsHelper helperText="Loading report..." />
            </ViewPlaceholder>
        );
    }

    if (!report?.token || !report?.url || error) {
        return (
            <ViewPlaceholder>
                <IdsHelper isInvalid helperInvalidText="Failed to load the report." />
            </ViewPlaceholder>
        );
    }

    return (
        <PowerBiReportEmbed
            embedId={reportId}
            embedUrl={report.url}
            accessToken={report.token}
            navContentPaneEnabled={navContentPaneEnabled}
        />
    );
};

export const PowerBiReportEmbed: React.FC<{
    className?: string;
    accessToken: string;
    embedUrl: string;
    embedId: string;
    pageName?: string;
    navContentPaneEnabled?: boolean;
}> = (props) => {
    const { className, accessToken, embedUrl, embedId, pageName, navContentPaneEnabled } = props;

    const reportRef = useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery("xs");

    useEffect(() => {
        let report: any = null;

        if (reportRef.current) {
            const config = {
                type: "report",
                id: embedId,
                tokenType: pbi.models.TokenType.Embed,
                accessToken,
                embedUrl,
                pageName,
                permissions: pbi.models.Permissions.Read,
                settings: {
                    filterPaneEnabled: false,
                    navContentPaneEnabled: navContentPaneEnabled ?? !pageName,
                    hyperlinkClickBehavior: pbi.models.HyperlinkClickBehavior.RaiseEvent,
                    ...(isMobile
                        ? {
                              layoutType: pbi.models.LayoutType.MobilePortrait,
                          }
                        : {
                              layoutType: pbi.models.LayoutType.Custom,
                              customLayout: {
                                  displayOption: pbi.models.DisplayOption.FitToPage,
                              },
                          }),
                },
            };

            /* eslint-disable */ // powerbi object is global
            // @ts-ignore
            report = powerbi.embed(reportRef.current, config);
            /*eslint-enable */

            report.on("loaded", function () {
                const iframe = reportRef.current?.querySelector("iframe");
                if (iframe) {
                    iframe.title = "PowerBI Report " + embedId;
                }
            });
        }

        return () => {
            if (report && reportRef.current) {
                /* eslint-disable */ // powerbi object is global
                // @ts-ignore
                powerbi.reset(reportRef.current);
                /*eslint-enable */
            }
        };
    }, [accessToken, embedId, embedUrl, isMobile, navContentPaneEnabled, pageName]);

    return (
        <div className={cn("powerbi-report flex-column fill-height fill-width no-scroll", className)}>
            <div key={embedId} className="powerbi-report-body flex-grow" ref={reportRef} />
        </div>
    );
};
