import { IdsText } from "@emergn-infinity/ids-react";

import { useGetAssumptionsByStdVariableQuery } from "store/apiSlice";

import { AssumptionsTable } from "./AssumptionsTable";

export const AssumptionsMapTable: React.FC<{
    stdVariableNumber: string;
}> = ({ stdVariableNumber }) => {
    const { data } = useGetAssumptionsByStdVariableQuery({ stdVariableNumber }, { skip: stdVariableNumber === "" });

    if (data === undefined) {
        return null;
    }

    return (
        <>
            <IdsText weight="bold">Selected Standard Variable also maps to:</IdsText>
            <div className="with-scroll">
                <AssumptionsTable standardizingList={data} />
            </div>
        </>
    );
};
