import { isString, padStart } from "lodash";
import { parseISO } from "date-fns";

const storage = sessionStorage;

export const SERVER_TIMEZONE_OFFSET_STORAGE_KEY = "server-timezone";

export const DEFAULT_SERVER_TIMEZONE_OFFSET = -300;
export const DEFAULT_LOCALE = new Intl.DateTimeFormat().resolvedOptions().locale;

export const DATEPICKER_DATE_FORMAT = "MM/dd/yyyy";

let serverTimezoneOffset = DEFAULT_SERVER_TIMEZONE_OFFSET;

/**
 * Compares an input date against a reference date
 * Return 1 if input date > reference date
 * Return -1 if input date < reference date
 * Return 0 if input date = reference date
 * @param inputDate Date
 * @param referenceDate Date
 * @returns int
 */
export const compareDateWithoutTime = (inputDate: Date, referenceDate: any) => {
    const inputDatePart = getDateWithoutTime(inputDate);
    const referenceDatePart = getDateWithoutTime(referenceDate);

    if (inputDatePart > referenceDatePart) {
        return 1;
    } else if (inputDatePart < referenceDatePart) {
        return -1;
    } else {
        return 0;
    }
};

export const dateToJson = (date: Date) => {
    if (date instanceof Date) {
        const yyyy = padStart(String(date.getFullYear()), 4, "0");
        const MM = padStart(String(date.getMonth() + 1), 2, "0");
        const dd = padStart(String(date.getDate()), 2, "0");
        const hh = padStart(String(date.getHours()), 2, "0");
        const mm = padStart(String(date.getMinutes()), 2, "0");
        const ss = padStart(String(date.getSeconds()), 2, "0");
        const SSS = padStart(String(date.getMilliseconds()), 3, "0");

        // Using json date without trailing "Z" to prevent adding timezone offset when converting json date to javascript Date instance with `parseISO`.
        return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}.${SSS}`;
    }

    return null;
};

/**
 * Converts a date string to JSON format.
 * Example input: "05/27/2024" -> "2024-05-27"
 *
 * @param dateStr - The date string to convert.
 * @returns The date in JSON format.
 */
export const dateInputToJson = (dateStr: string) => {
    if (isString(dateStr)) {
        const [month, day, year] = dateStr.split("/");

        if (month && day && year) {
            return `${year}-${month}-${day}`;
        }
    }

    return undefined;
};

export const jsonDateToInputDate = (dateStr: string) => {
    if (isString(dateStr)) {
        const date = jsonDateToDate(dateStr);

        if (!isNaN(date as any)) {
            return (date as Date).toLocaleString(DEFAULT_LOCALE, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        }
    }

    return undefined;
};

export const jsonDateToDate = (dateStr: string) => {
    if (!isString(dateStr)) {
        return dateStr;
    }

    // remove trailing "Z" to prevent adding timezone offset when converting json date to javascript Date instance with `parseISO`.
    const dateStrWithoutZ = dateStr.endsWith("Z") ? dateStr.slice(0, -1) : dateStr;
    const date = parseISO(dateStrWithoutZ);

    return isNaN(date as any) ? NaN : date;
};

export const datePartFromJsonDate = (dateStr: string) => {
    if (isString(dateStr)) {
        return dateStr.slice(0, 10);
    }

    return undefined;
};

export const sortObjectsByDate = (list: any[], dateKey: string) => {
    if (list) {
        let orderedList = list.slice();

        orderedList.sort((a, b) => {
            return jsonDateToDate(b[dateKey]).valueOf() - jsonDateToDate(a[dateKey]).valueOf();
        });

        return orderedList;
    }
};

export const saveServerTimezone = (value: any) => {
    serverTimezoneOffset = value;
    storage.setItem(SERVER_TIMEZONE_OFFSET_STORAGE_KEY, value);
};

export const getInitialServerTimezoneOffset = () => {
    const value = storage.getItem(SERVER_TIMEZONE_OFFSET_STORAGE_KEY);

    return value ? Number(value) : DEFAULT_SERVER_TIMEZONE_OFFSET;
};

export const getTimeDifferenceToString = (started: string, finished: string) => {
    let timeDiff = "";

    const startDate = new Date(started + "Z");
    const endDate = new Date(finished + "Z");

    const diffMs = endDate.getTime() - startDate.getTime(); // milliseconds
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24)); // days
    const diffHrs = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // hours
    const diffMins = Math.floor(((diffMs % (1000 * 60 * 60 * 24)) % (1000 * 60 * 60)) / (1000 * 60)); // minutes
    const diffSecs = Math.round((((diffMs % (1000 * 60 * 60 * 24)) % (1000 * 60 * 60)) % (1000 * 60)) / 1000); // seconds

    if (diffDays > 0) {
        timeDiff += `${diffDays} days, `;
    }
    if (diffHrs > 0) {
        timeDiff += `${diffHrs} hours, `;
    }
    if (diffMins > 0) {
        timeDiff += `${diffMins} minutes, `;
    }
    if (diffSecs > 0) {
        timeDiff += `${diffSecs} seconds`;
    }

    return timeDiff;
};

export const getBrowserTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
};

export const getUserTimezoneOffset = () => {
    return getBrowserTimezoneOffset();
};

export const getServerTimezoneOffset = () => {
    return serverTimezoneOffset;
};

export const formatJsonDate = (dateStr?: string, defaultValue = "") => {
    if (!dateStr) {
        return defaultValue;
    }

    const date = jsonDateToDate(dateStr);

    if (isNaN(date as any)) {
        return dateStr;
    }

    return (date as Date).toLocaleString(DEFAULT_LOCALE, {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
};

/**
 * Format JSON date string to localized date string
 * @param {string} dateStr - JSON date string
 * @param {string | undefined} defaultValue - fallback value if dateStr is empty
 * @param {boolean} showSeconds - show seconds in time
 * @returns formatted date string
 */
export const formatJsonDateTime = (dateStr: string, defaultValue: string | undefined = "", showSeconds: boolean = false) => {
    if (!dateStr) {
        return defaultValue;
    }

    const date = jsonDateToDate(dateStr);

    if (isNaN(date as any)) {
        return dateStr;
    }

    const adjustedDate = serverDateToLocal(date as Date);

    return adjustedDate.toLocaleString(DEFAULT_LOCALE, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: showSeconds ? "numeric" : undefined,
    });
};

/**
 * Convert server date to local.
 */
export const serverDateToLocal = (date: Date) => {
    const localOffset = getUserTimezoneOffset();
    const serverOffset = getServerTimezoneOffset();

    let adjustedDate = addMinutes(date, -serverOffset);
    adjustedDate = addMinutes(adjustedDate, -localOffset);

    return adjustedDate;
};

export const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000);
};

/**
 * Returns beginning of day for a given datetime
 * @param date Date
 * @returns Date
 */
const getDateWithoutTime = (date: number | Date) => {
    let datePart = new Date(date);

    datePart.setHours(0);
    datePart.setMinutes(0);
    datePart.setSeconds(0);
    datePart.setMilliseconds(0);

    return datePart;
};
