import { useCallback, useRef } from "react";

import { IdsText, IdsButton } from "@emergn-infinity/ids-react";

export const ShowMore: React.FC<{
    value: string;
}> = ({ value }) => {
    const ellipsisRef = useRef<HTMLSpanElement>(null);
    const btnContainerRef = useRef<HTMLDivElement>(null);
    const ellipsisClassName = "multi-line-ellipsis-3";

    const onShowHide = useCallback(() => {
        if (!ellipsisRef.current || !btnContainerRef.current) {
            return;
        }

        if (ellipsisRef.current.classList.contains(ellipsisClassName)) {
            ellipsisRef.current.classList.remove(ellipsisClassName);
            btnContainerRef.current.innerText = "Hide";
        } else {
            ellipsisRef.current.classList.add(ellipsisClassName);
            btnContainerRef.current.innerText = "Show more";
        }
    }, []);

    return (
        <div className="flex-column gap-1">
            <IdsText>
                <span ref={ellipsisRef} className={ellipsisClassName} style={{ whiteSpace: "pre-wrap" }}>
                    {value}
                </span>
            </IdsText>
            <IdsButton variant="tertiary" padding="xs" clickHandler={onShowHide}>
                <div ref={btnContainerRef}>Show more</div>
            </IdsButton>
        </div>
    );
};
