import { memo } from "react";
import cn from "classnames";

import TabDetails from "./TabDetails";

import { WindowContainerType } from "store/window/types";

import "./style.scss";

const WindowTab = memo((props: WindowTabProps) => {
    const {
        name,
        containerName,
        active,
        close,
        isSplitViewActive,
        onClose,
        onActivate,
        // onSync
    } = props;

    // const views = useViews({ containerName });

    // const view = views.find((view) => view.name === name)!;

    const tabClassNames = cn("no-scroll window-tab", {
        home: name === "Home",
        active: active,
        "split-view": isSplitViewActive,
    });

    const renderSplitViewTab = () => {
        // const { isSplitViewSync } = view;

        return (
            <div key={name} className={tabClassNames}>
                <div className="flex-row align-center fill-height">
                    <TabDetails containerName={containerName} name={name} side="left" onActivate={() => onActivate(name)} />
                    {/* <div className="fill-height no-shrink split-view-sync-controls">
                        <IconWrap
                            icon={isSplitViewSync ? "locked cut-scissors-filled" : "copy-link"}
                            title={isSplitViewSync ? "Unsynchronize" : ""}
                            noBackground
                            onClick={() => onSync(name)}
                        />
                        <IconWrap
                            icon={isSplitViewSync ? "locked copy-link" : "copy-link"}
                            title={isSplitViewSync ? "" : "Synchronize"}
                            noBackground
                            onClick={() => onSync(name)}
                        />
                    </div> */}
                    <TabDetails
                        containerName={containerName}
                        name={name}
                        side="right"
                        onClose={close ? onClose : undefined}
                        onActivate={() => onActivate(name)}
                    />
                </div>
            </div>
        );
    };

    const renderDefaultTab = () => {
        return (
            <div key={name} className={tabClassNames}>
                <TabDetails containerName={containerName} name={name} onClose={close ? onClose : undefined} onActivate={onActivate} />
            </div>
        );
    };

    return isSplitViewActive ? renderSplitViewTab() : renderDefaultTab();
});

interface WindowTabProps {
    /**
     * Name of the View.
     */
    name: string;

    /**
     * View container type.
     */
    containerName: WindowContainerType;

    /**
     * View is active.
     */
    active?: boolean;

    /**
     * View can be closed.
     */
    close?: boolean;

    /**
     * Split view is active for the view.
     */
    isSplitViewActive?: boolean;

    /**
     * Function that closes a View.
     */
    onClose: (name: string) => void;

    /**
     * Function that activates a View.
     */
    onActivate: (name: string) => void;

    /**
     * Function that enables/disables
     * sync scrolling for Split View.
     */
    onSync: (name: string) => void;
}

export default WindowTab;
