import { memo, useEffect } from "react";
import queryString from "query-string";

import { useAppDispatch } from "store/hooks";

import { USER_SAVE } from "store/actionTypes";

import FormWrap from "pages/Login/FormWrap";

import ViewPlaceholder from "components/ViewPlaceholder";
import IconLoading from "components/IconLoading";

import { openWindowLogin } from "store/window/actions";
import { refreshUser } from "store/apiSlice";

const SamlView = memo(() => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const login = async () => {
            const params = queryString.parse(window.location.search);

            let accessToken = null;
            let refreshToken = null;

            if (params) {
                Object.keys(params).forEach((key) => {
                    const formattedKey = key.toLowerCase();

                    switch (formattedKey) {
                        case "accesstoken":
                            accessToken = params[key];
                            break;
                        case "refreshtoken":
                            refreshToken = params[key];
                            break;
                        default:
                            break;
                    }
                });
            }

            if (accessToken && refreshToken) {
                dispatch({
                    type: USER_SAVE,
                    user: {
                        accessToken,
                        refreshToken,
                    },
                });

                await dispatch(refreshUser());
            } else {
                dispatch(openWindowLogin());
            }

            // Clear url
            setTimeout(() => {
                window.history.pushState("", "", "/");
            }, 100);
        };

        login();
    }, [dispatch]);

    return (
        <FormWrap>
            <ViewPlaceholder>
                <IconLoading />
            </ViewPlaceholder>
        </FormWrap>
    );
});

export default SamlView;
