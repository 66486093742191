import { useMemo, useState } from "react";

import { Sectors } from "./Sectors";
import { EndUses } from "./EndUses";
import { Measures } from "./Measures";
import { Vintages } from "./Vintages";
import { Algorithms } from "./Algorithms";
import { Variables } from "./Variables";

import { TrmAttributes } from "pages/StandardizeData/utils";

import { API_PAGINATION_FETCH_LIMIT, ITEMS_PER_PAGE } from "utils/constants";

import { TrmAttributeType } from "pages/StandardizeData";

export const MainPanel: React.FC<{
    attribute: TrmAttributeType;
}> = ({ attribute }) => {
    const [showAll, setShowAll] = useState(false);
    const [hideInactive, setHideInactive] = useState(false);
    const [search, setSearch] = useState("");
    const [pageSize, setPageSize] = useState(ITEMS_PER_PAGE[0]);
    const [pageNumber, setPageNumber] = useState(1);

    const totalPages = useMemo(() => {
        return API_PAGINATION_FETCH_LIMIT / pageSize; // API has a fetch limit (pageSize * totalPages <= 1000)
    }, [pageSize]);

    const onChange = (e: any, name: string) => {
        switch (name) {
            case "showAll":
                setShowAll(e.target.checked);

                break;

            case "hideInactive":
                setHideInactive(e.target.checked);

                break;

            case "search":
                setSearch(e);

                break;

            case "pageSize":
                setPageSize(e);

                break;

            case "pageNumber":
                setPageNumber(e);

                break;

            default:
        }
    };

    return (
        <>
            {attribute === TrmAttributes.Sectors.id && (
                <Sectors
                    id={attribute}
                    name={TrmAttributes.Sectors.name}
                    fullName={TrmAttributes.Sectors.fullName}
                    showAll={showAll}
                    hideInactive={hideInactive}
                    search={search}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    onChange={onChange}
                />
            )}
            {attribute === TrmAttributes.EndUses.id && (
                <EndUses
                    id={attribute}
                    name={TrmAttributes.EndUses.name}
                    fullName={TrmAttributes.EndUses.fullName}
                    showAll={showAll}
                    hideInactive={hideInactive}
                    search={search}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    onChange={onChange}
                />
            )}
            {attribute === TrmAttributes.Measures.id && (
                <Measures
                    id={attribute}
                    name={TrmAttributes.Measures.name}
                    fullName={TrmAttributes.Measures.fullName}
                    showAll={showAll}
                    hideInactive={hideInactive}
                    search={search}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                    onChange={onChange}
                />
            )}
            {attribute === TrmAttributes.Vintages.id && (
                <Vintages
                    id={attribute}
                    name={TrmAttributes.Vintages.name}
                    fullName={TrmAttributes.Vintages.fullName}
                    showAll={showAll}
                    hideInactive={hideInactive}
                    search={search}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    onChange={onChange}
                />
            )}
            {attribute === TrmAttributes.Algorithms.id && (
                <Algorithms
                    id={attribute}
                    name={TrmAttributes.Algorithms.name}
                    fullName={TrmAttributes.Algorithms.fullName}
                    showAll={showAll}
                    hideInactive={hideInactive}
                    search={search}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                    onChange={onChange}
                />
            )}
            {attribute === TrmAttributes.Variables.id && (
                <Variables
                    id={attribute}
                    name={TrmAttributes.Variables.name}
                    fullName={TrmAttributes.Variables.fullName}
                    showAll={showAll}
                    hideInactive={hideInactive}
                    search={search}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                    onChange={onChange}
                />
            )}
        </>
    );
};
