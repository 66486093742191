import { IdsTag, IdsTagGroup, IdsText } from "@emergn-infinity/ids-react";

export const EditFormTags: React.FC<{
    trmName?: string;
    measureName?: string;
    sector?: string;
    endUse?: string;
    vintage?: string;
}> = ({ trmName, measureName, sector, endUse, vintage }) => {
    return (
        <IdsTagGroup customClasses="pb-4">
            {trmName && (
                <IdsTag size="sm">
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold">TRM:</IdsText>
                        <IdsText>{trmName}</IdsText>
                    </div>
                </IdsTag>
            )}
            {measureName && (
                <IdsTag size="sm">
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold">Measure:</IdsText>
                        <IdsText>{measureName}</IdsText>
                    </div>
                </IdsTag>
            )}
            {sector && (
                <IdsTag size="sm">
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold">Sector:</IdsText>
                        <IdsText>{sector}</IdsText>
                    </div>
                </IdsTag>
            )}
            {endUse && (
                <IdsTag size="sm">
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold">End Use:</IdsText>
                        <IdsText>{endUse}</IdsText>
                    </div>
                </IdsTag>
            )}
            {vintage && (
                <IdsTag size="sm">
                    <div className="flex-row align-center gap-2">
                        <IdsText weight="bold">Vintage:</IdsText>
                        <IdsText>{vintage}</IdsText>
                    </div>
                </IdsTag>
            )}
        </IdsTagGroup>
    );
};
