import Welcome from "assets/img/Tour - Welcome.webp";
import Explore from "assets/img/Tour - Explore TRM.webp";
import Benchmark from "assets/img/Tour - Benchmark TRM.webp";
import Standardize from "assets/img/Tour - Standardize Data.webp";

import { StandardizeRights } from "utils/constants";

// Match keys with Tabs from pages/Home/utils
export const TourItems = {
    Welcome: {
        header: "Welcome to TRMulator",
        text: "The TRMulator serves as a central repository for all available TRMs and provides answers to common research questions as interpreted according to a dedicated AEG standard.",
        imageUrl: Welcome,
        rights: [],
    },
    ExploreTrms: {
        header: "Explore TRMs",
        text: "View details of any TRM measure as written and perform TRM-compliant savings calculations.",
        imageUrl: Explore,
        rights: [],
    },
    BenchmarkTrm: {
        header: "Benchmark TRM",
        text: "Compare measure-level details across TRMs including measure life details, cost details, savings calculations, default assumptions, and sources.",
        imageUrl: Benchmark,
        rights: [],
    },
    StandardizeData: {
        header: "Standardize data",
        text: "Map TRM-specific data to dedicated AEG standards for reuse by all practice areas.",
        imageUrl: Standardize,
        rights: StandardizeRights,
    },
} as const;
