import { isEmpty } from "lodash";

import { useGetLookupQuery } from "store/apiSlice";

import { MeasureLife } from "types";
import { formatNumber } from "utils/string";

export const EulCell: React.FC<{
    item: MeasureLife;
    lookupNumber?: string;
}> = ({ item, lookupNumber }) => {
    const { data: lookupItem, isFetching } = useGetLookupQuery({ lookupNumber: lookupNumber! }, { skip: !lookupNumber });

    let value = formatNumber(item.eul ?? "");

    if (!isEmpty(lookupNumber)) {
        if (isFetching) {
            value = "";
        } else {
            value = lookupItem?.lookupValue ? [formatNumber(lookupItem?.lookupValue), item?.eulUnits].join(" ") : "";
        }
    }

    return <span>{value}</span>;
};
