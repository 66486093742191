import { isEmpty } from "lodash";

import { IdsDropdown } from "@emergn-infinity/ids-react";

import { StandardPermutationValues } from ".";

// NOTE: If fuelTypeList, stdSectorList, stdEndUse, stdMeasureList,
// stdVintageList on initial render in an empty array
// initialSelectedItems will be set, but will not show up
// in the dropdown field.
export const StandardPermutations: React.FC<{
    stdPermutationValues: StandardPermutationValues;
    fuelTypeList: {
        label: string;
        value: string;
    }[];
    stdSectorList: {
        label: string;
        value: string;
    }[];
    stdEndUseList: {
        label: string;
        value: string;
    }[];
    stdMeasureList: {
        label: string;
        value: string;
    }[];
    stdVintageList: {
        label: string;
        value: string;
    }[];
    onSelect: (value: string, name: string) => void;
}> = ({ stdPermutationValues, fuelTypeList, stdSectorList, stdEndUseList, stdMeasureList, stdVintageList, onSelect }) => {
    return (
        <>
            <IdsDropdown
                idValue="select-fuel-type"
                isSearchable
                items={fuelTypeList}
                placeholder="Type to Search Fuel Types"
                initialSelectedItems={isEmpty(stdPermutationValues.fuelTypeNumber) ? null : [stdPermutationValues.fuelTypeNumber]}
                changeHandler={(value) => onSelect(value, "fuelTypeNumber")}
                clearHandler={() => onSelect("", "fuelTypeNumber")}
            />
            <IdsDropdown
                idValue="select-standard-sector"
                isSearchable
                items={stdSectorList}
                placeholder="Type to Search Standard Sectors"
                initialSelectedItems={isEmpty(stdPermutationValues.stdSectorNumber) ? null : [stdPermutationValues.stdSectorNumber]}
                changeHandler={(value) => onSelect(value, "stdSectorNumber")}
                clearHandler={() => onSelect("", "stdSectorNumber")}
            />
            <IdsDropdown
                idValue="select-standard-end-use"
                isSearchable
                items={stdEndUseList}
                placeholder="Type to Search Standard End Uses"
                initialSelectedItems={isEmpty(stdPermutationValues.stdEndUseNumber) ? null : [stdPermutationValues.stdEndUseNumber]}
                changeHandler={(value) => onSelect(value, "stdEndUseNumber")}
                clearHandler={() => onSelect("", "stdEndUseNumber")}
            />
            <IdsDropdown
                idValue="select-standard-measure"
                isSearchable
                items={stdMeasureList}
                placeholder="Type to Search Standard Measures"
                initialSelectedItems={isEmpty(stdPermutationValues.stdMeasureNumber) ? null : [stdPermutationValues.stdMeasureNumber]}
                changeHandler={(value) => onSelect(value, "stdMeasureNumber")}
                clearHandler={() => onSelect("", "stdMeasureNumber")}
            />
            <IdsDropdown
                idValue="select-standard-vintage"
                isSearchable
                items={stdVintageList}
                placeholder="Type to Search Standard Vintages"
                initialSelectedItems={isEmpty(stdPermutationValues.stdVintageNumber) ? null : [stdPermutationValues.stdVintageNumber]}
                changeHandler={(value) => onSelect(value, "stdVintageNumber")}
                clearHandler={() => onSelect("", "stdVintageNumber")}
            />
        </>
    );
};
