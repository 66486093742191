import { isEmpty } from "lodash";
import { useMemo, useState } from "react";

import { useGetEndUsesQuery, useGetStdEndUsesQuery, useUpdateStandardizingForEndUseMutation } from "store/apiSlice";

import { Standardize } from "pages/StandardizeData/MainPanel/common/Standardize";
import { SuggestedStandard } from "pages/StandardizeData/MainPanel/common/SuggestedStandard";

import { EndUsesTable } from "./EndUsesTable";
import { StandardEndUse } from "./StandardEndUse";

import { getFilteredItems } from "pages/StandardizeData/utils";

import { usePagination } from "utils/usePagination";

import { EndUse } from "types";

export const EndUses: React.FC<{
    id: string;
    name: string;
    fullName: string;
    showAll: boolean;
    hideInactive: boolean;
    search: string;
    pageSize: number;
    pageNumber: number;
    onChange: (e: any, name: string) => void;
}> = ({ id, name, fullName, showAll, hideInactive, search, pageSize, pageNumber, onChange }) => {
    const [activeEndUse, setActiveEndUse] = useState<EndUse | null>(null);
    const [selectedEndUses, setSelectedEndUses] = useState<EndUse[]>([]);

    const [stdEndUseNumber, setStdEndUseNumber] = useState("");

    const fetchStdEndUses = activeEndUse !== null || !isEmpty(selectedEndUses); // only fetch standard end uses when an end use is selected
    const standardizeDisabled = isEmpty(selectedEndUses) || stdEndUseNumber === "";

    const { data: endUses } = useGetEndUsesQuery({});

    const { data: stdEndUses, isLoading: isLoadingStdEndUses } = useGetStdEndUsesQuery({}, { skip: !fetchStdEndUses });

    const [updateEndUseStandardizing, updateEndUseStandardizingStatus] = useUpdateStandardizingForEndUseMutation();

    const filteredEndUses = useMemo(() => {
        let filteredEndUses: EndUse[] = [];

        if (endUses !== undefined) {
            filteredEndUses = getFilteredItems(endUses, showAll, search, "endUseName", "sugStdEndUseNumber");
        }

        return filteredEndUses;
    }, [showAll, search, endUses]);

    const stdEndUsesList = useMemo(() => {
        return (stdEndUses ?? []).map((endUse) => ({
            label: endUse.stdEndUseName,
            value: endUse.stdEndUseNumber,
        }));
    }, [stdEndUses]);

    const endUsesForStandardizing = usePagination(filteredEndUses, { pageSize, pageNumber });

    const allSelected = selectedEndUses.length === endUsesForStandardizing.items.length && !isEmpty(endUsesForStandardizing.items);

    const onSelectAll = () => {
        let newSelectedEndUses: EndUse[] = [];

        // Select all end uses
        if (endUses !== undefined && !allSelected) {
            newSelectedEndUses = endUsesForStandardizing.items.map((endUse) => endUse);
        }

        setSelectedEndUses(newSelectedEndUses);
    };

    const onPageChange = (pageNumber: number) => {
        setSelectedEndUses([]);

        onChange(pageNumber, "pageNumber");
    };

    const onEndUseClick = (endUse: EndUse) => {
        setActiveEndUse(endUse);

        setStdEndUseNumber(endUse.sugStdEndUseNumber);
    };

    const onEndUseChecked = (endUse: EndUse) => {
        let newSelectedEndUses: EndUse[] = [];

        // Uncheck end use
        if (selectedEndUses.find((eu) => eu.endUseNumber === endUse.endUseNumber)) {
            newSelectedEndUses = selectedEndUses.filter((eu) => eu.endUseNumber !== endUse.endUseNumber);
        }
        // Check end use
        else {
            newSelectedEndUses = [...selectedEndUses];

            newSelectedEndUses.push(endUse);
        }

        setSelectedEndUses(newSelectedEndUses);
    };

    const onSelectStandardEndUse = (value: string) => {
        setStdEndUseNumber(value);
    };

    const onStandardizeClick = async () => {
        // Do nothing if saving is in progress
        if (updateEndUseStandardizingStatus.isLoading) {
            return;
        }

        // To update standardizing for end use,
        // an end use has to be selected
        // and standard end use has to be selected
        if (!isEmpty(selectedEndUses) && stdEndUseNumber !== "") {
            const dateActivated = new Date(Date.now()).toISOString();

            const endUses = selectedEndUses.map((eu) => ({
                endUseNumber: eu.endUseNumber,
                stdEndUseNumber,
                dateActivated,
            }));
            try {
                await updateEndUseStandardizing({ endUses }).unwrap();
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className="flex-row fill-height">
            <div className="w-70 flex-grow pr-4">
                {endUses !== undefined && (
                    <Standardize
                        name={name}
                        fullName={fullName}
                        showAll={showAll}
                        hideInactive={hideInactive}
                        search={search}
                        allSelected={allSelected}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        tableComponent={
                            <EndUsesTable
                                activeEndUse={activeEndUse}
                                selectedEndUses={selectedEndUses}
                                standardizingList={endUsesForStandardizing.items}
                                onClick={onEndUseClick}
                                onChange={onEndUseChecked}
                            />
                        }
                        totalRows={endUsesForStandardizing.totalRows}
                        totalPages={endUsesForStandardizing.totalPages || 1} // 0 causes issues for IdsTablePagination
                        onSelectAll={onSelectAll}
                        onChange={onChange}
                        onPageChange={onPageChange}
                    />
                )}
            </div>
            <div className="vertical-divider" />
            {/* NOTE: Key here causes small freeze up and errors in console,
                      but otherwise dropdown default values do not get updated */}
            <div
                key={activeEndUse === null ? "end-use-none" : `end-use-${activeEndUse.endUseNumber}-${selectedEndUses.length}`}
                className="w-30 flex-grow pl-4"
            >
                {fetchStdEndUses && !isLoadingStdEndUses && (
                    <SuggestedStandard
                        id={id}
                        name={name}
                        standardComponent={
                            <StandardEndUse
                                stdEndUseNumber={stdEndUseNumber}
                                stdEndUsesList={stdEndUsesList}
                                onSelect={onSelectStandardEndUse}
                            />
                        }
                        standardizeDisabled={standardizeDisabled}
                        onStandardizeClick={onStandardizeClick}
                    />
                )}
            </div>
        </div>
    );
};
