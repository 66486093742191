import * as React from "react";
import ClassNames from "classnames";

import Label from "components/Label";

import "./style.scss";

const FieldGroup = (props: FieldGroupProps) => {
    const direction = props.direction || "column";

    const classNames = ClassNames("field-group", props.className, direction);

    return (
        <div className="field-group-container">
            {props.label && <Label required={props.required}>{props.label}</Label>}
            <div className={classNames}>{props.children}</div>
        </div>
    );
};

interface FieldGroupProps {
    className?: string;

    /**
     * Flex direction of field group.
     *
     * Default direction is 'column'.
     */
    direction?: "column" | "row";

    /**
     * Label for Field Group.
     */
    label?: string;

    required?: boolean;

    children: React.ReactNode;
}

export default FieldGroup;
