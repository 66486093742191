import { memo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { logout } from "store/login/actions";

import { IdsButton } from "@emergn-infinity/ids-react";

import { Avatar } from "components/Avatar";

import { logoutIcon } from "utils/icons";
import { getUserInitials } from "utils/user";

import { View } from "store/window/types";

import "./style.scss";

const WindowHeader = memo((props: WindowHeaderProps) => {
    const { view } = props;

    const dispatch = useAppDispatch();

    const { name, headerTitle, headerSubTitle } = view;

    // @ts-ignore
    const user = useAppSelector((state) => state.user);
    const { initials } = getUserInitials(user?.firstName, user?.lastName);

    const onLogout = useCallback(() => {
        // @ts-ignore
        dispatch(logout());
    }, [dispatch]);

    return (
        <div className="window-header">
            <div className="flex-row align-center window-header__body">
                {name === "Home" && <div className="window-header__logo" />}
                <div className="flex-row align-center flex-one">
                    <div className="window-header__title">{headerTitle}</div>
                    {headerSubTitle && <div className="window-header__subtitle">{headerSubTitle}</div>}
                </div>

                <div className="flex-row align-center">
                    {initials && <Avatar name={initials} />}
                    {onLogout && (
                        <IdsButton className="logout-button" data-testid="logout-btn" onClick={onLogout} title="Logout" padding="sm">
                            <FontAwesomeIcon icon={logoutIcon} size="lg" color="white" />
                        </IdsButton>
                    )}
                </div>
            </div>
        </div>
    );
});

interface WindowHeaderProps {
    /**
     * Current View.
     */
    view: View;

    /**
     * Split view's main name.
     *
     * NOTE: Used for split view.
     */
    completeName?: string;

    /**
     * View can be closed.
     */
    close?: boolean;

    /**
     * Split view functionality enabled for the View.
     */
    isSplitViewEnabled?: boolean;

    /**
     * Split view is active for the view.
     */
    isSplitViewActive?: boolean;

    /**
     * Function that closes a View.
     */
    onClose?: (name: string) => void;

    /**
     * Function that splits the View.
     */
    onSplit?: (name: string) => void;

    /**
     * Function that unsplits the View.
     */
    onRemoveSplit?: (name: string) => void;
}

export default WindowHeader;
