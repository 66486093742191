import { isEmpty, isNaN, isNil, isString } from "lodash";

export function formatAlgorithmText(text: string) {
    if (!isString(text)) {
        return "";
    }

    // Format subscripted text and handle case where there is no subscripted text
    let formattedText = text.replace(/<([^>_]*)(?:_([^>]*))?[^>]*>/g, "$1<sub>$2</sub>");

    // Remove any empty <sub></sub> tags
    formattedText = formattedText.replace(/<sub><\/sub>/g, "");

    return formattedText;
}

export function formatVariableText(text: string) {
    if (!isString(text)) {
        return "";
    }

    // Wrap variable in angle brackets and
    // format it as algorithm text
    const textToFormat = `<${text}>`;

    return formatAlgorithmText(textToFormat);
}

export const sanitizeAlgorithmInput = (value: string, inputRef: React.RefObject<HTMLInputElement>) => {
    if (!value) {
        if (inputRef.current) {
            inputRef.current.value = "";
        }

        return undefined;
    }

    // Substitute all endashes and emdashes with hyphens
    const result = value.replace(/–/g, "-").replace(/—/g, "-");

    if (isEmpty(result)) {
        if (inputRef.current) {
            inputRef.current.value = "";
        }

        return undefined;
    }

    if (inputRef.current) {
        inputRef.current.value = result;
    }

    return result;
};

export const sanitizeAndSetNumericInput = (
    value: string,
    inputRef: React.RefObject<HTMLInputElement>,
    params?: {
        allowDecimals?: boolean;
        maxLength?: number;
    },
) => {
    const { allowDecimals = true, maxLength } = params ?? {};

    if (!value) {
        if (inputRef.current) {
            inputRef.current.value = "";
        }

        return undefined;
    }

    // Remove non-numeric characters
    let result = allowDecimals ? value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1") : value.replace(/\D/g, "");

    // Truncate to maxLength
    if (maxLength && maxLength > 0 && result.length > maxLength) {
        result = result.substring(0, maxLength);
    }

    if (isEmpty(result)) {
        if (inputRef.current) {
            inputRef.current.value = "";
        }

        return undefined;
    }

    if (inputRef.current) {
        inputRef.current.value = result;
    }

    return Number(result);
};

/**
 * Find number in variable text and format
 * number to one decimal place, and add comma
 * if number is greater than 999.
 *
 * Example 1: 5.555 => 5.5
 *
 * Example 2: 1000 => 1,000
 *
 * @param text - variable text
 * @returns variable text with formatted number
 */
export function formatVariableNumber(text: string) {
    let result = "";
    const splitText = text.split(" ");

    for (const t of splitText) {
        let str = t;

        if (!isNaN(Number(t))) {
            str = Number(t).toLocaleString("en-US", { maximumFractionDigits: 1 });
        }

        result += str + " ";
    }

    return result;
}

export function formatNumber(value?: number | string) {
    if (isNil(value) || isEmpty(String(value ?? "").trim()) || isNaN(Number(value))) {
        return value;
    }

    return Number(value).toLocaleString("en-US");
}
