export const isInIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

/**
 * Check if element is visible.
 * @param {Element} element - The element to check.
 */
export const isVisible = (element) => {
    if (!element) {
        return false;
    }

    if (element.offsetParent === null) {
        return false;
    }

    if (element.style.display === "none") {
        return false;
    }

    if (element.style.visibility === "hidden") {
        return false;
    }

    if (element.style.opacity === "0") {
        return false;
    }

    return true;
};

/**
 * Do nothing on browser back button click.
 */
export const disableBrowserNavigation = () => {
    window.history.pushState(null, "", window.location.href);

    window.onpopstate = function () {
        window.history.go(1);
    };
};
