import { isEmpty } from "lodash";

import { IdsDropdown } from "@emergn-infinity/ids-react";

// NOTE: If stdEndUsesList on initial render in an empty array
// initialSelectedItems will be set, but will not show up
// in the dropdown field.
export const StandardEndUse: React.FC<{
    stdEndUseNumber: string;
    stdEndUsesList: {
        label: string;
        value: string;
    }[];
    onSelect: (value: string) => void;
}> = ({ stdEndUseNumber, stdEndUsesList, onSelect }) => {
    return (
        <IdsDropdown
            idValue="select-standard-end-use"
            isSearchable
            items={stdEndUsesList}
            placeholder="Select Standard End Use"
            initialSelectedItems={isEmpty(stdEndUseNumber) ? null : [stdEndUseNumber]}
            changeHandler={onSelect}
            clearHandler={() => onSelect("")}
        />
    );
};
