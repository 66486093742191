import { IdsContainer, IdsRow, IdsCol, IdsCard } from "@emergn-infinity/ids-react";

export const MeasureCharacterization = () => {
    return (
        <IdsContainer fullHeight>
            <IdsRow>
                <IdsCol xs="3">
                    <IdsCard customClasses="fill-height">
                        <div slot="slot1">Content</div>
                    </IdsCard>
                </IdsCol>
                <IdsCol>
                    <IdsCard customClasses="fill-height">
                        <div slot="slot1" className="fill-height">
                            Measure Characterization
                        </div>
                    </IdsCard>
                </IdsCol>
            </IdsRow>
        </IdsContainer>
    );
};
