import { IdsTable, IdsTableRow, IdsTableCell, IdsCheckbox, IdsText } from "@emergn-infinity/ids-react";

import { MeasureStandardizingListItem } from "types";

const getMeasureMappings = (a: string, b?: string, c?: string, d?: string, e?: string) => {
    const mappings = [a];

    b && mappings.push(b);
    c && mappings.push(c);
    d && mappings.push(d);
    e && mappings.push(e);

    return mappings.join(", ");
};

export const MeasuresTable: React.FC<{
    activeMeasure: MeasureStandardizingListItem | null;
    selectedMeasures: MeasureStandardizingListItem[];
    standardizingList: MeasureStandardizingListItem[];
    onClick: (measure: MeasureStandardizingListItem) => void;
    onChange: (measure: MeasureStandardizingListItem) => void;
}> = ({ activeMeasure, selectedMeasures, standardizingList, onClick, onChange }) => {
    return (
        <IdsTable spacing="lg">
            {standardizingList.map((measure) => (
                <IdsTableRow key={`${measure.measureNumber}`}>
                    <IdsTableCell
                        style={{
                            backgroundColor:
                                activeMeasure?.measureNumber === measure.measureNumber
                                    ? "var(--ids-table-cell-body-background-color-clear-hover)"
                                    : undefined,
                        }}
                        onClick={() => onClick(measure)}
                    >
                        <div className="flex-row align-center">
                            <div>
                                <IdsCheckbox
                                    idValue={measure.measureNumber}
                                    defaultChecked={selectedMeasures.find((m) => m.measureNumber === measure.measureNumber) !== undefined}
                                    changeHandler={() => onChange(measure)}
                                />
                            </div>
                            <div className="pl-4">
                                <IdsText weight="bold">{`${measure.trmFamiliarName} > ${measure.measureName}`}</IdsText>
                                <IdsText>
                                    {`Maps to: ${
                                        measure.sugMeasureNameA
                                            ? getMeasureMappings(
                                                  measure.sugMeasureNameA,
                                                  measure.sugMeasureNameB,
                                                  measure.sugMeasureNameC,
                                                  measure.sugMeasureNameD,
                                                  measure.sugMeasureNameE
                                              )
                                            : ""
                                    }`}
                                </IdsText>
                                {measure.measureDesc && <div className="text-italic">{measure.measureDesc}</div>}
                            </div>
                        </div>
                    </IdsTableCell>
                </IdsTableRow>
            ))}
        </IdsTable>
    );
};
