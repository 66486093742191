import queryString from "query-string";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { isEmpty, isNil } from "lodash";

import { AppDispatch, RootState } from "store";

import { USER_SAVE } from "./actionTypes";
import { logout } from "./login/actions";

import { createUser, saveUser } from "utils/user";

import {
    ApiResponse,
    AlgorithmUpdateModel,
    Algorithm,
    RelevantAlgorithm,
    SavingsBenchmark,
    AlgorithmStandardizing,
    AlgorithmStandardizingUpdateModel,
    CreateAssumptionApiResponse,
    AssumptionUpdateModel,
    Assumption,
    AssumptionUpdateEquationOrNameModel,
    AssumptionListItem,
    AssumptionStandardizing,
    AssumptionStandardizingUpdateModel,
    CreateContactModel,
    CreateContactApiResponse,
    Contact,
    CostsListItem,
    CostUpdateModel,
    Cost,
    EndUse,
    EndUseStandardizingUpdateModel,
    EulUpdateModel,
    Eul,
    FuelConversionListItem,
    FuelTypeListItem,
    MeasureLife,
    MeasureCost,
    MeasureAlgorithm,
    MeasureVariable,
    AlgorithmAssumptionsResponse,
    LookupUpdateModel,
    LookupsUpdateModel,
    LookupType,
    Lookup,
    MeasureUpdateModel,
    Measure,
    MeasureListItem,
    MeasureStandardizing,
    MeasureStandardizingUpdateModel,
    PowerBiReportResponse,
    PublisherTrms,
    PublisherDetails,
    PublisherDetailsUpdateModel,
    Sector,
    SectorListItem,
    SectorStandardizingUpdateModel,
    StdEndUse,
    CreateStdMeasure,
    CreateStdMeasureApiResponse,
    StdMeasure,
    StdPermutation,
    StdSector,
    CreateStdVariable,
    CreateStdVariableApiResponse,
    StdVariable,
    StdVintage,
    TrmListItem,
    TrmDetails,
    TrmDetailsUpdateModel,
    TrmStatusUpdateModel,
    CreateTrmLinkModel,
    CreateTrmLinkApiResponse,
    TrmLink,
    UpdateTrmLinkModel,
    Vintage,
    VintageStandardizingUpdateModel,
    SecurityRights,
    User,
} from "types";

export const api = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: (build) => ({
        //
        // TRM Api
        //

        // Algorithms

        createAlgorithm: build.mutation<{ algorithmNumber: string } & ApiResponse, { algorithm: AlgorithmUpdateModel }>({
            query: ({ algorithm }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms`,
                method: "POST",
                body: algorithm,
            }),
            invalidatesTags: [{ type: "Algorithms" as never, id: "LIST" }],
        }),
        cloneAlgorithm: build.mutation<ApiResponse, { algorithmNumber: string }>({
            query: ({ algorithmNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms/${algorithmNumber}`,
                method: "POST",
            }),
            invalidatesTags: [{ type: "Algorithms" as never, id: "LIST" }],
        }),
        getAlgorithms: build.query<
            Algorithm[],
            {
                fuelConvertNumber?: string;
                sectorNumber?: string;
                endUseNumber?: string;
                measureNumber?: string;
                vintageNumber?: string;
                algVerified?: boolean;
            }
        >({
            query: ({
                fuelConvertNumber = "",
                sectorNumber = "",
                endUseNumber = "",
                measureNumber = "",
                vintageNumber = "",
                algVerified = true,
            }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms` +
                    getQueryString({
                        fuelConvertNumber,
                        sectorNumber,
                        endUseNumber,
                        measureNumber,
                        vintageNumber,
                        algVerified: algVerified.toString(),
                    }),
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "Algorithms" as never, id: "LIST" }],
        }),
        updateAlgorithm: build.mutation<ApiResponse, { algorithmNumber: string; algorithm: AlgorithmUpdateModel }>({
            query: ({ algorithmNumber, algorithm }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms/${algorithmNumber}`,
                method: "PUT",
                body: algorithm,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Algorithms" as never, id: arg.algorithmNumber },
                { type: "Algorithms" as never, id: "LIST" },
                { type: "Assumptions" as never, id: "LIST" },
            ],
        }),
        deleteAlgorithm: build.mutation<ApiResponse, { algorithmNumber: string }>({
            query: ({ algorithmNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms/${algorithmNumber}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Algorithms" as never, id: arg.algorithmNumber },
                { type: "Algorithms" as never, id: "LIST" },
            ],
        }),
        getAlgorithm: build.query<Algorithm, { algorithmNumber: string }>({
            query: ({ algorithmNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms/${algorithmNumber}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "Algorithms" as never, id: arg.algorithmNumber }],
        }),
        getRelevantAlgorithms: build.query<
            RelevantAlgorithm[],
            { trmNumber?: string; measureNumber?: string; sectorNumber?: string; vintageNumber?: string; fuelTypeNumber?: string }
        >({
            query: ({ trmNumber = "", measureNumber = "", sectorNumber = "", vintageNumber = "", fuelTypeNumber = "" }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms/relevant` +
                    getQueryString({ trmNumber, measureNumber, sectorNumber, vintageNumber, fuelTypeNumber }),
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "Algorithms" as never, id: "LIST" }],
        }),
        getSavingsBenchmarks: build.query<SavingsBenchmark[], { relevantAlgorithmNumbers: string[] }>({
            query: ({ relevantAlgorithmNumbers }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms/savingsbenchmarks` +
                    getQueryString({ relevantAlgorithmNumbers }),
                method: "GET",
            }),
            providesTags: () => [{ type: "SavingsBenchmarks" as never, id: "LIST" }],
        }),
        getAlgorithmsForStandardizing: build.query<
            AlgorithmStandardizing,
            {
                includeSuggested: boolean;
                hideInactive: boolean;
                pageSize: number;
                pageNumber: number;
                totalPages: number;
                algorithmNameSearch?: string;
            }
        >({
            query: ({ includeSuggested, hideInactive, pageSize, pageNumber, totalPages, algorithmNameSearch = "" }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms/standardizings` +
                    getQueryString({
                        includeSuggested: includeSuggested.toString(),
                        hideInactive: hideInactive.toString(),
                        pageSize: pageSize.toString(),
                        pageNumber: pageNumber.toString(),
                        totalPages: totalPages.toString(),
                        measureNameSearch: algorithmNameSearch,
                    }),
                method: "GET",
            }),
            providesTags: () => [{ type: "AlgorithmsForStandardizing" as never, id: "LIST" }],
        }),
        updateStandardizingForAlgorithm: build.mutation<ApiResponse, { algorithms: AlgorithmStandardizingUpdateModel[] }>({
            query: ({ algorithms }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms/standardizings`,
                method: "PATCH",
                body: algorithms,
            }),
            invalidatesTags: () => [{ type: "AlgorithmsForStandardizing" as never, id: "LIST" }],
        }),

        // Assumptions

        createAssumption: build.mutation<CreateAssumptionApiResponse, { assumption: AssumptionUpdateModel }>({
            query: ({ assumption }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/assumptions`,
                method: "POST",
                body: assumption,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Assumptions" as never, id: "LIST" },
                { type: "Algorithms" as never, id: arg.assumption.algorithmNumber },
                { type: "Algorithms" as never, id: "LIST" },
            ],
        }),
        getAssumptions: build.query<Assumption[], { algorithmNumber: string }>({
            query: ({ algorithmNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/assumptions` + getQueryString({ algorithmNumber }),
                method: "GET",
            }),
            providesTags: () => [{ type: "Assumptions" as never, id: "LIST" }],
        }),
        updateAssumption: build.mutation<
            ApiResponse,
            { assumptionNumber: string; assumption: AssumptionUpdateModel; clearAlgorithm?: boolean }
        >({
            query: ({ assumptionNumber, assumption }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/assumptions/${assumptionNumber}`,
                method: "PUT",
                body: assumption,
            }),
            invalidatesTags: (result, error, arg) => {
                // Clear Assumptions always
                const tags = [
                    { type: "Assumptions" as never, id: arg.assumptionNumber },
                    { type: "Assumptions" as never, id: "LIST" },
                ];

                // Clear Algorithms only if flag is set to true
                if (arg.clearAlgorithm) {
                    tags.push({ type: "Algorithms" as never, id: arg.assumption.algorithmNumber });
                    tags.push({ type: "Algorithms" as never, id: "LIST" });
                }

                return tags;
            },
        }),
        updateAssumptionEquationOrName: build.mutation<
            ApiResponse,
            { assumptionNumber: string; assumption: AssumptionUpdateEquationOrNameModel }
        >({
            query: ({ assumptionNumber, assumption }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/assumptions/${assumptionNumber}`,
                method: "PATCH",
                body: assumption,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Assumptions" as never, id: arg.assumptionNumber },
                { type: "Assumptions" as never, id: "LIST" },
            ],
        }),
        deleteAssumption: build.mutation<ApiResponse, { assumptionNumber: string; algorithmNumber: string }>({
            query: ({ assumptionNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/assumptions/${assumptionNumber}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Assumptions" as never, id: arg.assumptionNumber },
                { type: "Assumptions" as never, id: "LIST" },
                { type: "Algorithms" as never, id: arg.algorithmNumber },
                { type: "Algorithms" as never, id: "LIST" },
            ],
        }),
        getAssumption: build.query<Assumption, { assumptionNumber: string }>({
            query: ({ assumptionNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/assumptions/${assumptionNumber}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "Assumptions" as never, id: arg.assumptionNumber }],
        }),
        getAssumptionsByStdVariable: build.query<AssumptionListItem[], { stdVariableNumber: string }>({
            query: ({ stdVariableNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/stdvariables/${stdVariableNumber}/assumptions`,
                method: "GET",
            }),
        }),
        getAssumptionsForStandardizing: build.query<
            AssumptionStandardizing,
            {
                includeSuggested: boolean;
                hideInactive: boolean;
                pageSize: number;
                pageNumber: number;
                totalPages: number;
                variableDescSearch?: string;
            }
        >({
            query: ({ includeSuggested, hideInactive, pageSize, pageNumber, totalPages, variableDescSearch = "" }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/assumptions/standardizings` +
                    getQueryString({
                        includeSuggested: includeSuggested.toString(),
                        hideInactive: hideInactive.toString(),
                        pageSize: pageSize.toString(),
                        pageNumber: pageNumber.toString(),
                        totalPages: totalPages.toString(),
                        variableDescSearch: variableDescSearch,
                    }),
                method: "GET",
            }),
            providesTags: () => [{ type: "AssumptionsForStandardizing" as never, id: "LIST" }],
        }),
        updateStandardizingForAssumption: build.mutation<ApiResponse, { assumptions: AssumptionStandardizingUpdateModel[] }>({
            query: ({ assumptions }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/assumptions/standardizings`,
                method: "PATCH",
                body: assumptions,
            }),
            invalidatesTags: () => [{ type: "AssumptionsForStandardizing" as never, id: "LIST" }],
        }),

        // Contacts

        createContact: build.mutation<CreateContactApiResponse, { contact: CreateContactModel }>({
            query: ({ contact }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/contacts`,
                method: "POST",
                body: contact,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Contacts" as never, id: arg.contact.publisherNumber }],
        }),
        getContacts: build.query<Contact[], { publisherNumber?: string }>({
            query: ({ publisherNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/contacts` + getQueryString({ publisherNumber }),
                method: "GET",
            }),
            providesTags: (result, error, arg) =>
                result ? [{ type: "Contacts" as never, id: arg.publisherNumber }] : [{ type: "Contacts" as never, id: "LIST" }],
        }),
        updateContact: build.mutation<ApiResponse, { contactNumber: string; contact: CreateContactModel }>({
            query: ({ contactNumber, contact }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/contacts/${contactNumber}`,
                method: "PUT",
                body: contact,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Contacts" as never, id: arg.contact.publisherNumber }],
        }),
        deleteContact: build.mutation<ApiResponse, { publisherNumber: string; contactNumber: string }>({
            query: ({ contactNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/contacts/${contactNumber}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Contacts" as never, id: arg.publisherNumber }],
        }),

        // Costs

        getCosts: build.query<CostsListItem[], { costVerified?: boolean }>({
            query: ({ costVerified = false }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/costs` +
                    getQueryString({ costVerified: costVerified.toString() }),
                method: "GET",
            }),
            providesTags: () => [{ type: "Costs" as never, id: "LIST" }],
        }),
        createCost: build.mutation<ApiResponse, { cost: CostUpdateModel }>({
            query: ({ cost }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/costs`,
                method: "POST",
                body: cost,
            }),
            invalidatesTags: [{ type: "Costs" as never, id: "LIST" }],
        }),
        getCost: build.query<Cost, { costNumber: string }>({
            query: ({ costNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/costs/${costNumber}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "Costs" as never, id: arg.costNumber }],
        }),
        updateCost: build.mutation<ApiResponse, { cost: CostUpdateModel; costNumber: string }>({
            query: ({ cost, costNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/costs/${costNumber}`,
                method: "PUT",
                body: cost,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Costs" as never, id: arg.costNumber },
                { type: "Costs" as never, id: "LIST" },
            ],
        }),
        deleteCost: build.mutation<ApiResponse, { costNumber: string }>({
            query: ({ costNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/costs/${costNumber}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Costs" as never, id: arg.costNumber },
                { type: "Costs" as never, id: "LIST" },
            ],
        }),

        // End Uses

        getEndUses: build.query<EndUse[], { trmNumber?: string; endUseVerified?: boolean; hideInactive?: boolean }>({
            query: ({ trmNumber = "", endUseVerified, hideInactive = false }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/enduses` +
                    getQueryString({
                        trmNumber,
                        endUseVerified: isNil(endUseVerified) ? undefined : endUseVerified.toString(),
                        hideInactive: hideInactive.toString(),
                    }),
                method: "GET",
            }),
            providesTags: () => [{ type: "EndUses" as never, id: "LIST" }],
        }),
        updateStandardizingForEndUse: build.mutation<ApiResponse, { endUses: EndUseStandardizingUpdateModel[] }>({
            query: ({ endUses }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/enduses/standardizings`,
                method: "PUT",
                body: endUses,
            }),
            invalidatesTags: () => [{ type: "EndUses" as never, id: "LIST" }],
        }),

        // EULs

        createEul: build.mutation<ApiResponse, { eul: EulUpdateModel }>({
            query: ({ eul }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/euls`,
                method: "POST",
                body: eul,
            }),
            invalidatesTags: [{ type: "Euls" as never, id: "LIST" }],
        }),
        getEuls: build.query<Eul[], { measureNumber: string; eulVerified?: boolean }>({
            query: ({ measureNumber, eulVerified = false }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/euls` +
                    getQueryString({ measureNumber, eulVerified: eulVerified.toString() }),
                method: "GET",
            }),
        }),
        updateEul: build.mutation<ApiResponse, { eulNumber: string; eul: EulUpdateModel }>({
            query: ({ eulNumber, eul }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/euls/${eulNumber}`,
                method: "PUT",
                body: eul,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Euls" as never, id: "LIST" },
                { type: "Euls" as never, id: arg.eulNumber },
            ],
        }),
        deleteEul: build.mutation<ApiResponse, { eulNumber: string }>({
            query: ({ eulNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/euls/${eulNumber}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Euls" as never, id: "LIST" },
                { type: "Euls" as never, id: arg.eulNumber },
            ],
        }),
        getEul: build.query<Eul, { eulNumber: string }>({
            query: ({ eulNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/euls/${eulNumber}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "Euls" as never, id: arg.eulNumber }],
        }),
        getEulsForBenchmark: build.query<
            Eul[],
            {
                trmNumber?: string;
                measureNumber?: string;
                sectorNumber?: string;
                vintageNumber?: string;
                fuelTypeNumber?: string;
            }
        >({
            query: ({ trmNumber = "", measureNumber = "", sectorNumber = "", vintageNumber = "", fuelTypeNumber = "" }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/euls` +
                    getQueryString({ trmNumber, measureNumber, sectorNumber, vintageNumber, fuelTypeNumber }),
                method: "GET",
            }),
        }),

        // Fuel Conversions

        getFuelConversions: build.query<FuelConversionListItem[], { fuelTypeNumber?: string } | void>({
            query: (arg) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/fuelconversions${
                    arg?.fuelTypeNumber ? getQueryString({ fuelTypeNumber: arg.fuelTypeNumber }) : ""
                }`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "FuelConversions" as never, id: arg?.fuelTypeNumber }],
        }),

        // Fuel Types

        getFuelTypes: build.query<FuelTypeListItem[], void>({
            query: () => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/fueltypes`,
                method: "GET",
            }),
        }),
        getFuelTypesByMeasure: build.query<FuelTypeListItem[], { measureNumber: string }>({
            query: ({ measureNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/fueltypes/measures/${measureNumber}`,
                method: "GET",
            }),
        }),

        // Grid

        gridMeasureLives: build.query<MeasureLife[], { measureNumber: string }>({
            query: ({ measureNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/grids/measures/${measureNumber}/life`,
                method: "GET",
            }),
            providesTags: () => [{ type: "Euls" as never, id: "LIST" }],
        }),
        gridMeasureCosts: build.query<MeasureCost[], { measureNumber: string }>({
            query: ({ measureNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/grids/measures/${measureNumber}/costs`,
                method: "GET",
            }),
            providesTags: () => [{ type: "Costs" as never, id: "LIST" }],
        }),
        gridMeasureAlgorithms: build.query<MeasureAlgorithm[], { measureNumber: string }>({
            query: ({ measureNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/grids/measures/${measureNumber}/algorithms`,
                method: "GET",
            }),
            providesTags: () => [{ type: "Algorithms" as never, id: "LIST" }],
        }),
        gridMeasureVariables: build.query<MeasureVariable[], { algorithmNumber: string }>({
            query: ({ algorithmNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/grids/measures/algorithms/${algorithmNumber}/assumptions`,
                method: "GET",
            }),
            providesTags: () => [{ type: "Assumptions" as never, id: "LIST" }],
        }),
        getAlgorithmAssumptions: build.query<AlgorithmAssumptionsResponse, { algorithmNumber: string }>({
            query: ({ algorithmNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/algorithms/${algorithmNumber}/assumptions`,
                method: "GET",
            }),
            providesTags: () => [{ type: "Assumptions" as never, id: "LIST" }],
        }),

        // Lookups

        createLookup: build.mutation<ApiResponse, { lookup: LookupUpdateModel }>({
            query: ({ lookup }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/lookups`,
                method: "POST",
                body: lookup,
            }),
            invalidatesTags: [{ type: "Lookups" as never, id: "LIST" }],
        }),
        getLookups: build.query<Lookup[], { trmNumber: string; lookupType: LookupType; lookupByPremise?: string }>({
            query: ({ trmNumber, lookupType, lookupByPremise = "" }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/lookups` +
                    getQueryString({ trmNumber, lookupType, lookupByPremise }),
                method: "GET",
            }),
            providesTags: () => [{ type: "Lookups" as never, id: "LIST" }],
        }),
        createLookups: build.mutation<ApiResponse, { lookups: LookupsUpdateModel }>({
            query: ({ lookups }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/lookups/bulk`,
                method: "POST",
                body: lookups,
            }),
            invalidatesTags: [{ type: "Lookups" as never, id: "LIST" }],
        }),
        updateLookup: build.mutation<ApiResponse, { lookupNumber: string; lookup: LookupUpdateModel }>({
            query: ({ lookupNumber, lookup }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/lookups/${lookupNumber}`,
                method: "PUT",
                body: lookup,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Lookups" as never, id: "LIST" },
                { type: "Lookups" as never, id: arg.lookupNumber },
            ],
        }),
        deleteLookup: build.mutation<ApiResponse, { lookupNumber: string }>({
            query: ({ lookupNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/lookups/${lookupNumber}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Lookups" as never, id: "LIST" },
                { type: "Lookups" as never, id: arg.lookupNumber },
            ],
        }),
        getLookup: build.query<Lookup, { lookupNumber: string }>({
            query: ({ lookupNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/lookups/${lookupNumber}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "Lookups" as never, id: arg.lookupNumber }],
        }),
        getLookupByPremises: build.query<string[], { trmNumber: string; lookupType: LookupType }>({
            query: ({ trmNumber, lookupType }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/lookups/LookupByPremises` +
                    getQueryString({ trmNumber, lookupType }),
                method: "GET",
            }),
            providesTags: () => [{ type: "Lookups" as never, id: "LIST" }],
        }),

        // Measures

        updateMeasure: build.mutation<ApiResponse, { measureNumber: string; measure: MeasureUpdateModel }>({
            query: ({ measureNumber, measure }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/measures/${measureNumber}`,
                method: "PUT",
                body: measure,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Measures" as never, id: arg.measureNumber }],
        }),
        deleteMeasure: build.mutation<ApiResponse, { measureNumber: string }>({
            query: ({ measureNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/measures/${measureNumber}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Measures" as never, id: arg.measureNumber }],
        }),
        getMeasure: build.query<Measure, { measureNumber: string }>({
            query: ({ measureNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/measures/${measureNumber}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) =>
                result ? [{ type: "Measures" as never, id: arg.measureNumber }] : [{ type: "Measures" as never, id: "LIST" }],
        }),
        getMeasuresByTrm: build.query<MeasureListItem[], { trmNumber: string; measureRelated?: boolean; measureVerified?: boolean }>({
            query: ({ trmNumber, measureRelated, measureVerified = true }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/measures/trms/${trmNumber}` +
                    getQueryString({
                        measureRelated: isNil(measureRelated) ? undefined : measureRelated.toString(),
                        measureVerified: measureVerified.toString(),
                    }),
                method: "GET",
            }),
        }),
        getMeasuresForStandardizing: build.query<
            MeasureStandardizing,
            {
                includeSuggested: boolean;
                hideInactive: boolean;
                pageSize: number;
                pageNumber: number;
                totalPages: number;
                measureNameSearch?: string;
            }
        >({
            query: ({ includeSuggested, hideInactive, pageSize, pageNumber, totalPages, measureNameSearch = "" }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/measures/standardizings` +
                    getQueryString({
                        includeSuggested: includeSuggested.toString(),
                        hideInactive: hideInactive.toString(),
                        pageSize: pageSize.toString(),
                        pageNumber: pageNumber.toString(),
                        totalPages: totalPages.toString(),
                        measureNameSearch,
                    }),
                method: "GET",
            }),
            providesTags: () => [{ type: "MeasuresForStandardizing" as never, id: "LIST" }],
        }),
        updateStandardizingForMeasure: build.mutation<ApiResponse, { measures: MeasureStandardizingUpdateModel[] }>({
            query: ({ measures }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/measures/standardizings`,
                method: "PATCH",
                body: measures,
            }),
            invalidatesTags: () => [{ type: "MeasuresForStandardizing" as never, id: "LIST" }],
        }),

        // PowerBI

        getPowerBiReport: build.query<
            PowerBiReportResponse,
            { reportId: string; entityId?: string; reportType?: number; datasetId?: string; isPaginated?: boolean }
        >({
            query: ({ reportId, entityId, reportType, datasetId, isPaginated = false }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/system/api/v1/powerbi/${reportId}`,
                method: "GET",
                params: {
                    isPaginated: String(isPaginated),
                    entityId,
                    reportType,
                    datasetId,
                },
            }),
            providesTags: (result, error, arg) => [{ type: "PowerBiReports" as never, id: arg.reportId }],
        }),

        // Publishers

        getPublisherTrms: build.query<PublisherTrms[], { publisherNumber: string }>({
            query: ({ publisherNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/publishers/${publisherNumber}/trms`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "PublisherTrms" as never, id: arg.publisherNumber }],
        }),
        getPublisherDetails: build.query<PublisherDetails, { publisherNumber: string }>({
            query: ({ publisherNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/publishers/${publisherNumber}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "PublisherDetails" as never, id: arg.publisherNumber }],
        }),
        updatePublisherDetails: build.mutation<ApiResponse, { publisherNumber: string; publisherDetails: PublisherDetailsUpdateModel }>({
            query: ({ publisherNumber, publisherDetails }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/publishers/${publisherNumber}`,
                method: "PUT",
                body: publisherDetails,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "PublisherDetails" as never, id: arg.publisherNumber }],
        }),

        // Sectors

        getSectors: build.query<Sector[], { trmNumber?: string; isVerified?: boolean }>({
            query: ({ trmNumber = "", isVerified }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/sectors` +
                    getQueryString({ trmNumber, isVerified: isNil(isVerified) ? undefined : isVerified.toString() }),
                method: "GET",
            }),
            providesTags: () => [{ type: "Sectors" as never, id: "LIST" }],
        }),
        getSectorsByMeasure: build.query<SectorListItem[], { measureNumber: string }>({
            query: ({ measureNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/sectors/measures/${measureNumber}`,
                method: "GET",
            }),
        }),
        updateStandardizingForSector: build.mutation<ApiResponse, { sectors: SectorStandardizingUpdateModel[] }>({
            query: ({ sectors }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/sectors/standardizings`,
                method: "PUT",
                body: sectors,
            }),
            invalidatesTags: () => [{ type: "Sectors" as never, id: "LIST" }],
        }),

        // Standard End Uses

        getStdEndUses: build.query<StdEndUse[], { isWeatherDep?: boolean; recsDesignation?: string; cbecsDesignations?: string }>({
            query: ({ isWeatherDep = false, recsDesignation = "", cbecsDesignations = "" }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/stdenduses` +
                    getQueryString({ isWeatherDep: isWeatherDep.toString(), recsDesignation, cbecsDesignations }),
                method: "GET",
            }),
        }),

        // Standard Measures

        createStdMeasure: build.mutation<CreateStdMeasureApiResponse, { stdMeasure: CreateStdMeasure }>({
            query: ({ stdMeasure }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/stdmeasures`,
                method: "POST",
                body: stdMeasure,
            }),
            invalidatesTags: [{ type: "StdMeasures" as never, id: "LIST" }],
        }),
        getStdMeasures: build.query<StdMeasure[], void>({
            query: () => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/stdmeasures`,
                method: "GET",
            }),
            providesTags: () => [{ type: "StdMeasures" as never, id: "LIST" }],
        }),

        // Standard Premutations

        getStdPermutations: build.query<
            StdPermutation[],
            {
                fuelTypeNumber?: string;
                stdSectorNumber?: string;
                stdEndUseNumber?: string;
                stdMeasureNumber?: string;
                stdVintageNumber?: string;
            }
        >({
            query: ({ fuelTypeNumber = "", stdSectorNumber = "", stdEndUseNumber = "", stdMeasureNumber = "", stdVintageNumber = "" }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/stdpermutations` +
                    getQueryString({ fuelTypeNumber, stdSectorNumber, stdEndUseNumber, stdMeasureNumber, stdVintageNumber }),
                method: "GET",
            }),
        }),

        // Standard Sectors

        getStdSectors: build.query<StdSector[], void>({
            query: () => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/stdsectors`,
                method: "GET",
            }),
        }),

        // Standard Variables

        createStdVariable: build.mutation<CreateStdVariableApiResponse, { stdVariable: CreateStdVariable }>({
            query: ({ stdVariable }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/stdvariables`,
                method: "POST",
                body: stdVariable,
            }),
            invalidatesTags: [{ type: "StdVariables" as never, id: "LIST" }],
        }),
        getStdVariables: build.query<StdVariable[], void>({
            query: () => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/stdvariables`,
                method: "GET",
            }),
            providesTags: () => [{ type: "StdVariables" as never, id: "LIST" }],
        }),

        // Standard Vintages

        getStdVintages: build.query<StdVintage[], void>({
            query: () => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/stdvintages`,
                method: "GET",
            }),
        }),

        // TRM

        getTrms: build.query<TrmListItem[], { status?: boolean }>({
            query: ({ status = false }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms` + getQueryString({ status: status.toString() }),
                method: "GET",
            }),
            providesTags: () => [{ type: "Trms" as never, id: "LIST" }],
        }),
        getTrmDetails: build.query<TrmDetails, { trmNumber: string }>({
            query: ({ trmNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/${trmNumber}`,
                method: "GET",
            }),
            providesTags: (result, error, arg) => [{ type: "TrmDetails" as never, id: arg.trmNumber }],
        }),
        updateTrmDetails: build.mutation<ApiResponse, { trmNumber: string; trmDetails: TrmDetailsUpdateModel }>({
            query: ({ trmNumber, trmDetails }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/${trmNumber}`,
                method: "PUT",
                body: trmDetails,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "TrmDetails" as never, id: arg.trmNumber }],
        }),
        updateTrmStatus: build.mutation<
            ApiResponse,
            { activeTrmNumber: string; currentTrmNumber: string; publisherNumber: string; trmStatus: TrmStatusUpdateModel }
        >({
            query: ({ activeTrmNumber, trmStatus }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/${activeTrmNumber}/status`,
                method: "PUT",
                body: trmStatus,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Trms" as never, id: "LIST" },
                { type: "TrmDetails" as never, id: arg.currentTrmNumber },
                { type: "PublisherTrms" as never, id: arg.publisherNumber },
            ],
        }),
        createTrmLink: build.mutation<CreateTrmLinkApiResponse, { trmLink: CreateTrmLinkModel }>({
            query: ({ trmLink }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/links`,
                method: "POST",
                body: trmLink,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "TrmLinks" as never, id: arg.trmLink.trmNumber }],
        }),
        getTrmLinks: build.query<TrmLink[], { trmNumber?: string }>({
            query: ({ trmNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/links` + getQueryString({ trmNumber }),
                method: "GET",
            }),
            providesTags: (result, error, arg) =>
                result ? [{ type: "TrmLinks" as never, id: arg.trmNumber }] : [{ type: "TrmLinks" as never, id: "LIST" }],
        }),
        updateTrmLink: build.mutation<ApiResponse, { trmNumber: string; linkNumber: string; trmLink: UpdateTrmLinkModel }>({
            query: ({ linkNumber, trmLink }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/links/${linkNumber}`,
                method: "PUT",
                body: trmLink,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "TrmLinks" as never, id: arg.trmNumber }],
        }),
        deleteTrmLink: build.mutation<ApiResponse, { trmNumber: string; linkNumber: string }>({
            query: ({ linkNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/trms/links/${linkNumber}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [{ type: "TrmLinks" as never, id: arg.trmNumber }],
        }),

        // Vintages

        getVintages: build.query<Vintage[], { trmNumber?: string; vintageVerified?: boolean; hideInactive?: boolean }>({
            query: ({ trmNumber = "", vintageVerified, hideInactive = false }) => ({
                url:
                    `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/vintages` +
                    getQueryString({
                        trmNumber,
                        vintageVerified: isNil(vintageVerified) ? undefined : vintageVerified.toString(),
                        hideInactive: hideInactive.toString(),
                    }),
                method: "GET",
            }),
            providesTags: () => [{ type: "Vintages" as never, id: "LIST" }],
        }),
        getVintagesByMeasure: build.query<Vintage[], { measureNumber: string }>({
            query: ({ measureNumber }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/vintages/measures/${measureNumber}`,
                method: "GET",
            }),
        }),
        updateStandardizingForVintage: build.mutation<ApiResponse, { vintages: VintageStandardizingUpdateModel[] }>({
            query: ({ vintages }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/trm/api/v1/vintages/standardizings`,
                method: "PUT",
                body: vintages,
            }),
            invalidatesTags: () => [{ type: "Vintages" as never, id: "LIST" }],
        }),

        // Zip Codes

        //
        // TRM System Api
        //

        getSecurityRights: build.query<SecurityRights[], { accessToken: string }>({
            query: ({ accessToken }) => ({
                url: `${process.env.REACT_APP_TRM_API_BASE_URL}/system/api/v1/securityrights`,
                method: "GET",
                headers: { authorization: `Bearer ${accessToken}` },
            }),
        }),

        //
        // Vision Api
        //

        login: build.mutation({
            query: (params) => ({
                url: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/trmtokens`,
                method: "POST",
                body: params,
            }),
        }),
        logout: build.mutation({
            query: (params) => ({
                url: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/trmtokens`,
                method: "DELETE",
                body: params,
            }),
        }),
        login2fa: build.query<ApiResponse, { userName: string }>({
            query: ({ userName }) => ({
                url: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/users/${userName}/twofactor`,
                method: "GET",
            }),
        }),
        refreshUser: build.mutation<User, { accessToken: string; refreshToken: string }>({
            query: ({ accessToken, refreshToken }) => ({
                url: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/trmtokens`,
                method: "PUT",
                body: { accessToken, refreshToken },
            }),
        }),
        setupAuthenticator: build.mutation<ApiResponse, { authCode: string }>({
            query: (params) => ({
                url: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/users/authcode`,
                method: "POST",
                body: params,
            }),
        }),
        deleteQrCode: build.mutation<ApiResponse, { userNumber: string }>({
            query: ({ userNumber }) => ({
                url: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/users/${userNumber}/qrcode`,
                method: "DELETE",
            }),
        }),
    }),
});

export const baseQuery = fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).user?.accessToken;
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

async function baseQueryWithReauth(args, api, extraOptions) {
    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
        // Use both the accessToken and refreshToken when making the refresh request
        const { accessToken, refreshToken, rights } = api.getState().user;

        const refreshResult = await baseQuery(
            {
                url: `${process.env.REACT_APP_VDSM_API_BASE_URL}/Authentication/api/v1/trmtokens`,
                method: "PUT",
                body: { accessToken, refreshToken },
            },
            api,
            extraOptions,
        );

        if (refreshResult?.data) {
            const user = createUser({
                ...(refreshResult.data as User),
                rights,
            });

            saveUser(user);

            api.dispatch({
                type: USER_SAVE,
                user,
            });

            // Retry the original query with the new token
            result = await baseQuery(args, api, extraOptions);
        } else {
            // Handle the case where the refresh token is also invalid or expired
            api.dispatch(logout());
        }
    }

    // show toast on error
    if (result?.error && result?.error?.status !== 401) {
        toast.error((result?.error.data as ApiResponse)?.responseMessage ?? `Status Code: ${result.error.status}`);
    }

    // show toast on failure with 200 status
    if (!result?.error && (result.data as ApiResponse)?.responseStatus === "failure" && (result.data as ApiResponse)?.responseMessage) {
        toast.error((result.data as ApiResponse).responseMessage);
    }

    // show toast for POST/PUT/DELETE success
    if (
        !result?.error &&
        result.meta?.request.method !== "GET" &&
        (result.data as ApiResponse)?.responseMessage &&
        (result.data as ApiResponse)?.responseStatus !== "failure"
    ) {
        toast.success((result.data as ApiResponse).responseMessage);
    }

    return result;
}

export const refreshUser = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const { accessToken, refreshToken, rights } = getState().user ?? {};

    if (accessToken && refreshToken) {
        const refreshResult = await dispatch(api.endpoints.refreshUser.initiate({ accessToken, refreshToken }));

        if (refreshResult.data) {
            let userRights = rights;

            if (isEmpty(userRights)) {
                // Get user rights
                const rightsResponse = await dispatch(
                    endpoints.getSecurityRights.initiate({ accessToken: refreshResult.data.accessToken }),
                );

                // Throw if error
                if (rightsResponse.error && "message" in rightsResponse.error) {
                    throw new Error(rightsResponse.error?.message);
                }

                userRights = rightsResponse.data;
            }

            const user = createUser({
                ...refreshResult.data,
                rights: userRights,
            });

            saveUser(user);

            dispatch({
                type: USER_SAVE,
                user,
            });
        } else {
            // Handle the case where the refresh token is also invalid or expired
            dispatch(logout());
        }
    }
};

export function getQueryString(queryParams: { [key: string]: string | string[] | undefined }, options?: queryString.StringifyOptions) {
    const keys = Object.keys(queryParams ?? {});

    if (!keys.length) {
        return "";
    }

    return "?" + queryString.stringify(queryParams, options);
}

export const {
    useCreateAlgorithmMutation,
    useCloneAlgorithmMutation,
    useGetAlgorithmsQuery,
    useUpdateAlgorithmMutation,
    useDeleteAlgorithmMutation,
    useGetAlgorithmQuery,
    useGetRelevantAlgorithmsQuery,
    useGetSavingsBenchmarksQuery,
    useGetAlgorithmsForStandardizingQuery,
    useUpdateStandardizingForAlgorithmMutation,

    useCreateAssumptionMutation,
    useGetAssumptionsQuery,
    useUpdateAssumptionMutation,
    useUpdateAssumptionEquationOrNameMutation,
    useDeleteAssumptionMutation,
    useGetAssumptionQuery,
    useGetAssumptionsByStdVariableQuery,
    useGetAssumptionsForStandardizingQuery,
    useUpdateStandardizingForAssumptionMutation,

    useCreateContactMutation,
    useGetContactsQuery,
    useUpdateContactMutation,
    useDeleteContactMutation,

    useGetCostsQuery,
    useCreateCostMutation,
    useGetCostQuery,
    useUpdateCostMutation,
    useDeleteCostMutation,

    useGetEndUsesQuery,
    useUpdateStandardizingForEndUseMutation,

    useCreateEulMutation,
    useGetEulsQuery,
    useUpdateEulMutation,
    useDeleteEulMutation,
    useGetEulQuery,
    useGetEulsForBenchmarkQuery,

    useGetFuelConversionsQuery,

    useGetFuelTypesQuery,
    useGetFuelTypesByMeasureQuery,

    useGridMeasureLivesQuery,
    useGridMeasureCostsQuery,
    useGridMeasureAlgorithmsQuery,
    useGridMeasureVariablesQuery,
    useGetAlgorithmAssumptionsQuery,

    useCreateLookupMutation,
    useGetLookupsQuery,
    useCreateLookupsMutation,
    useUpdateLookupMutation,
    useDeleteLookupMutation,
    useGetLookupQuery,
    useGetLookupByPremisesQuery,

    useUpdateMeasureMutation,
    useDeleteMeasureMutation,
    useGetMeasureQuery,
    useGetMeasuresByTrmQuery,
    useGetMeasuresForStandardizingQuery,
    useUpdateStandardizingForMeasureMutation,

    useGetPowerBiReportQuery,

    useGetPublisherTrmsQuery,
    useGetPublisherDetailsQuery,
    useUpdatePublisherDetailsMutation,

    useGetSectorsQuery,
    useGetSectorsByMeasureQuery,
    useUpdateStandardizingForSectorMutation,

    useGetStdEndUsesQuery,

    useCreateStdMeasureMutation,
    useGetStdMeasuresQuery,

    useGetStdPermutationsQuery,

    useGetStdSectorsQuery,

    useCreateStdVariableMutation,
    useGetStdVariablesQuery,

    useGetStdVintagesQuery,

    useGetTrmsQuery,
    useGetTrmDetailsQuery,
    useUpdateTrmDetailsMutation,
    useUpdateTrmStatusMutation,
    useCreateTrmLinkMutation,
    useGetTrmLinksQuery,
    useUpdateTrmLinkMutation,
    useDeleteTrmLinkMutation,

    useGetVintagesQuery,
    useGetVintagesByMeasureQuery,
    useUpdateStandardizingForVintageMutation,

    useLoginMutation,
    useLogoutMutation,
    useSetupAuthenticatorMutation,
    useDeleteQrCodeMutation,

    endpoints,
} = api;
