import { IdsTabs, IdsTabItem, IdsTabPanel } from "@emergn-infinity/ids-react";

import { MeasureLives } from "./MeasureLives";
import { MeasureCosts } from "./MeasureCosts";
import { SavingsCalculations } from "./SavingsCalculations";

export const MainPanel = () => {
    return (
        <IdsTabs customClasses="flex-column" fullHeight>
            <IdsTabItem slot="header" idValue="b-eul" label="EUL" isActive={true} />
            <IdsTabItem slot="header" idValue="b-cost" label="Costs" />
            <IdsTabItem slot="header" idValue="b-savings" label="Savings Calculations" />
            <IdsTabPanel slot="panel" idValue="b-eul" style={{ backgroundColor: "inherit" }}>
                <MeasureLives />
            </IdsTabPanel>
            <IdsTabPanel slot="panel" idValue="b-cost" style={{ backgroundColor: "inherit" }}>
                <MeasureCosts />
            </IdsTabPanel>
            <IdsTabPanel slot="panel" idValue="b-savings" style={{ backgroundColor: "inherit" }}>
                <SavingsCalculations />
            </IdsTabPanel>
        </IdsTabs>
    );
};
