import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGetEndUsesQuery, useGetMeasuresByTrmQuery, useGetSectorsQuery, useGetTrmsQuery, useGetVintagesQuery } from "store/apiSlice";

import { useAppDispatch, useAppSelector } from "store/hooks";

import { selectorChange } from "./exploreTrmsSlice";

import {
    IdsCol,
    IdsCard,
    IdsDropdown,
    IdsTable,
    IdsTableRow,
    IdsTableCell,
    IdsText,
    IdsTabs,
    IdsTabItem,
    IdsTag,
} from "@emergn-infinity/ids-react";

import { CustomRadio } from "components/CustomRadio";

import { TrmFilters } from "./utils";
import { TrmRights, MeasuresRights } from "utils/constants";
import { fileCheckIcon, filesIcon, circleCheckmarkIcon, circleCloseIcon } from "utils/icons";
import { hasAllRights } from "utils/user";

export const Sidebar: React.FC<{
    selectedTrm: string;
    selectedMeasure: string;
    onTrmSelect: (trmNumber: string) => void;
    onMeasureSelect: (measureNumber: string) => void;
    onMeasureClear: () => void;
}> = ({ selectedTrm, selectedMeasure, onTrmSelect, onMeasureSelect, onMeasureClear }) => {
    const dispatch = useAppDispatch();
    const { filter } = useAppSelector((state) => state.exploreTrms);

    const [selectedTab, setSelectedTab] = useState<TrmTabsType>(TrmTabs.Sectors);

    const showTrmDetails = !isEmpty(selectedTrm);

    const { data: trms, isLoading: isLoadingTrms } = useGetTrmsQuery(
        { status: filter === TrmFilters.Active },
        {
            refetchOnMountOrArgChange: true,
        },
    );

    // measureRelated true - show only measures with related data
    // measureRelated false - show only measures without related data
    // measureRelated undefined - show (all) measures with or without related data
    const { data: measures, isLoading: isLoadingMeasures } = useGetMeasuresByTrmQuery(
        { trmNumber: selectedTrm, measureRelated: filter === TrmFilters.Active ? true : undefined },
        { skip: isEmpty(selectedTrm) },
    );
    const measureList = useMemo(
        () =>
            (measures ?? []).map((measure) => ({
                value: measure.measureNumber,
                label: measure.measureName,
            })),
        [measures],
    );

    // External users with readOnly rights can see only verified sectors, end uses and vintages
    const { data: sectors, isError: isGetSectorsError } = useGetSectorsQuery(
        { trmNumber: selectedTrm },
        {
            skip: isEmpty(selectedTrm) || selectedTab !== TrmTabs.Sectors,
        },
    );

    const { data: endUses, isError: isGetEndUsesError } = useGetEndUsesQuery(
        { trmNumber: selectedTrm },
        {
            skip: isEmpty(selectedTrm) || selectedTab !== TrmTabs.EndUses,
        },
    );

    const { data: vintages, isError: isGetVintagesError } = useGetVintagesQuery(
        { trmNumber: selectedTrm },
        {
            skip: isEmpty(selectedTrm) || selectedTab !== TrmTabs.Vintages,
        },
    );

    const tableData = useMemo(() => {
        if (showTrmDetails) {
            switch (selectedTab) {
                case TrmTabs.Sectors:
                    return (isGetSectorsError ? [] : sectors)?.map((sector) => ({
                        name: sector.sectorName,
                        verified: sector.secVerified,
                    }));
                case TrmTabs.EndUses:
                    return (isGetEndUsesError ? [] : endUses)?.map((endUse) => ({
                        name: endUse.endUseName,
                        verified: endUse.endUseVerified,
                    }));
                case TrmTabs.Vintages:
                    return (isGetVintagesError ? [] : vintages)?.map((vintage) => ({
                        name: vintage.vintageName,
                        description: vintage.vintageDef,
                        verified: vintage.vintageVerified,
                    }));
                default:
                    return [];
            }
        }

        return [];
    }, [showTrmDetails, selectedTab, isGetSectorsError, sectors, isGetEndUsesError, endUses, isGetVintagesError, vintages]);

    const trmList = useMemo(
        () =>
            (trms ?? []).map((trm) => ({
                value: trm.trmNumber,
                label: trm.trmFamiliarName,
            })),
        [trms],
    );

    const tableCellName = useMemo(() => {
        switch (selectedTab) {
            case TrmTabs.Sectors:
                return "Sector";
            case TrmTabs.EndUses:
                return "End Use";
            case TrmTabs.Vintages:
                return "Vintage";
            default:
                return "Sector";
        }
    }, [selectedTab]);

    const tableTagVariant = useMemo(() => {
        switch (selectedTab) {
            case TrmTabs.Sectors:
                return "brand-c";
            case TrmTabs.EndUses:
                return "brand-d";
            case TrmTabs.Vintages:
                return undefined;
            default:
                return undefined;
        }
    }, [selectedTab]);

    const onStatusChange = (id: string) => {
        dispatch(selectorChange({ selector: "filter", value: id }));
        onTrmSelect("");
    };

    return (
        <IdsCol xs="3">
            <IdsCard customClasses="fill-height with-scroll">
                <div slot="slot1" className="flex-column gap-4 fill-height">
                    {/* Reset dropdown when status changes */}
                    <div key={`trm-dropdown-${filter}`} className="flex-column gap-2">
                        <IdsText customClasses="sidebar-title" weight="bold">
                            Select TRM & Measure
                        </IdsText>
                        {hasAllRights([TrmRights, MeasuresRights]) && (
                            <div className="flex-row gap-2 py-1">
                                <CustomRadio
                                    className="fill-width"
                                    id={TrmFilters.Active}
                                    name="trms-and-measures-filter"
                                    label="Show Active"
                                    defaultChecked={filter === TrmFilters.Active}
                                    icon={fileCheckIcon}
                                    onClick={() => onStatusChange(TrmFilters.Active)}
                                />
                                <CustomRadio
                                    className="fill-width"
                                    id={TrmFilters.All}
                                    name="trms-and-measures-filter"
                                    label="Show All"
                                    defaultChecked={filter === TrmFilters.All}
                                    icon={filesIcon}
                                    onClick={() => onStatusChange(TrmFilters.All)}
                                />
                            </div>
                        )}
                        <IdsDropdown
                            idValue="select-trm"
                            isSearchable={!isLoadingTrms} // A hack to properly preselect the dropdown value
                            items={trmList}
                            initialSelectedItems={selectedTrm ? [selectedTrm] : []}
                            placeholder={isLoadingTrms ? "Loading" : "Type to Search"}
                            changeHandler={onTrmSelect}
                            clearHandler={() => onTrmSelect("")}
                        />
                        <IdsDropdown
                            key={`measure-dropdown-${selectedTrm}`}
                            idValue="select-measure"
                            isSearchable={!isLoadingMeasures} // A hack to properly preselect the dropdown value
                            isDisabled={isEmpty(selectedTrm)}
                            items={measureList}
                            initialSelectedItems={selectedMeasure ? [selectedMeasure] : []}
                            placeholder={isLoadingMeasures ? "Loading" : "Type to Search"}
                            changeHandler={onMeasureSelect}
                            clearHandler={onMeasureClear}
                        />
                    </div>
                    {showTrmDetails && (
                        <div className="flex-one-in-column flex-column gap-2 border">
                            <IdsTabs behavior="fill-container" customClasses="flex-column min-h-0 no-shrink">
                                <IdsTabItem
                                    slot="header"
                                    idValue={TrmTabs.Sectors}
                                    label="Sectors"
                                    isActive={selectedTab === TrmTabs.Sectors}
                                    onClick={() => setSelectedTab(TrmTabs.Sectors)}
                                />
                                <IdsTabItem
                                    slot="header"
                                    idValue={TrmTabs.EndUses}
                                    label="End Uses"
                                    isActive={selectedTab === TrmTabs.EndUses}
                                    onClick={() => setSelectedTab(TrmTabs.EndUses)}
                                />
                                <IdsTabItem
                                    slot="header"
                                    idValue={TrmTabs.Vintages}
                                    label="Vintages"
                                    isActive={selectedTab === TrmTabs.Vintages}
                                    onClick={() => setSelectedTab(TrmTabs.Vintages)}
                                />
                            </IdsTabs>
                            <div className="px-3 with-scroll">
                                <IdsTable spacing="sm">
                                    <IdsTableRow rowType="table-heading-row">
                                        <IdsTableCell cellType="table-heading-cell" heading={tableCellName} />
                                        <IdsTableCell
                                            cellType="table-heading-cell"
                                            heading="Verified"
                                            style={{ minWidth: 0, width: "4rem" }}
                                        />
                                    </IdsTableRow>
                                    {tableData?.map((row: TableRow) => (
                                        <IdsTableRow key={`row-${row.name}`}>
                                            <IdsTableCell>
                                                <div>
                                                    <IdsTag variant={tableTagVariant} size="sm">
                                                        <>{row.name}</>
                                                    </IdsTag>
                                                    {row.description && <IdsText size="md">{row.description}</IdsText>}
                                                </div>
                                            </IdsTableCell>
                                            <IdsTableCell
                                                style={{ minWidth: 0, width: "4rem", textAlign: "center", verticalAlign: "middle" }}
                                            >
                                                {row.verified ? (
                                                    <FontAwesomeIcon
                                                        icon={circleCheckmarkIcon}
                                                        color="var(--ids-core-color-brand-e-600)"
                                                        title="Is verified"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={circleCloseIcon}
                                                        color="var(--ids-core-color-system-a-600)"
                                                        title="Is NOT verified"
                                                    />
                                                )}
                                            </IdsTableCell>
                                        </IdsTableRow>
                                    ))}
                                </IdsTable>
                            </div>
                        </div>
                    )}
                </div>
            </IdsCard>
        </IdsCol>
    );
};

export const TrmTabs = {
    Sectors: "trm-sectors",
    EndUses: "trm-end-uses",
    Vintages: "trm-vintages",
} as const;

export type TrmTabsType = (typeof TrmTabs)[keyof typeof TrmTabs];

export type TableRow = {
    name: string;
    description?: string;
    verified: boolean;
};
