import { isEmpty } from "lodash";
import { useCallback, useRef, useState } from "react";

import { IdsText, IdsButtonGroup, IdsButton, IdsFieldWrapper, IdsTextInput } from "@emergn-infinity/ids-react";

import { sanitizeAndSetNumericInput } from "utils/string";

export const ContactEditCard: React.FC<{
    firstName?: string;
    lastName?: string;
    company?: string;
    title?: string;
    phone?: string;
    extension?: string;
    email?: string;
    isLoading: boolean;
    onSave: (firstName: string, lastName: string, company: string, title: string, phone: string, extension: string, email: string) => void;
    onCancel: () => void;
}> = (props) => {
    const [firstName, setFirstName] = useState(props.firstName ?? "");
    const [lastName, setLastName] = useState(props.lastName ?? "");
    const [company, setCompany] = useState(props.company ?? "");
    const [title, setTitle] = useState(props.title ?? "");
    const [phone, setPhone] = useState(props.phone ?? "");
    const [extension, setExtension] = useState(props.extension ?? "");
    const [email, setEmail] = useState(props.email ?? "");
    const [errors, setErrors] = useState<ContactErrors>({});

    const extensionInputRef = useRef<HTMLInputElement>(null);

    const onChange = (value: string, name: string) => {
        if (name === "firstName") {
            setFirstName(value);
        } else if (name === "lastName") {
            setLastName(value);
        } else if (name === "company") {
            setCompany(value);
        } else if (name === "title") {
            setTitle(value);
        } else if (name === "phone") {
            setPhone(value);
        } else if (name === "extension") {
            const sanitizedValue = sanitizeAndSetNumericInput(value, extensionInputRef, {
                maxLength: 3,
            });

            setExtension(sanitizedValue?.toString() ?? "");
        } else if (name === "email") {
            setEmail(value);
        }

        if (name === "phone" || name === "email") {
            setErrors({
                ...errors,
                phone: undefined,
                email: undefined,
            });
        } else {
            setErrors({
                ...errors,
                [name]: undefined,
            });
        }
    };

    const onClearAll = useCallback(() => {
        setFirstName("");
        setLastName("");
        setCompany("");
        setTitle("");
        setPhone("");
        setExtension("");
        setEmail("");
    }, []);

    const onCancelClick = () => {
        setErrors({});

        props.onCancel();
    };

    const onSaveClick = () => {
        if (isEmpty(firstName) || isEmpty(lastName) || (isEmpty(phone) && isEmpty(email))) {
            setErrors({
                firstName: isEmpty(firstName) ? "First name is required" : undefined,
                lastName: isEmpty(lastName) ? "Last name is required" : undefined,
                phone: isEmpty(phone) && isEmpty(email) ? "Phone number or email is required" : undefined,
                email: isEmpty(phone) && isEmpty(email) ? "Phone number or email is required" : undefined,
            });

            return;
        }

        props.onSave(firstName, lastName, company, title, phone, extension, email);
    };

    return (
        <div className="flex-column border rounded-edges-rounder p-3">
            <div className="flex-row align-center justify-space-between pb-2">
                <IdsText weight="bold">New contact</IdsText>
                <IdsButton variant="tertiary" clickHandler={onClearAll}>
                    Clear all
                </IdsButton>
            </div>
            <div className="flex-column pb-2">
                <div className="flex-row gap-3">
                    <IdsFieldWrapper
                        customClasses="fill-width"
                        htmlFor="first-name"
                        wrapperLabel="First name"
                        isInvalid={!isEmpty(errors.firstName)}
                        helperInvalidText={errors.firstName}
                        isRequired
                    >
                        <IdsTextInput
                            idValue="first-name"
                            defaultValue={firstName}
                            changeHandler={(value) => onChange(value, "firstName")}
                        />
                    </IdsFieldWrapper>
                    <IdsFieldWrapper
                        customClasses="fill-width"
                        htmlFor="last-name"
                        wrapperLabel="Last name"
                        isInvalid={!isEmpty(errors.lastName)}
                        helperInvalidText={errors.lastName}
                        isRequired
                    >
                        <IdsTextInput idValue="last-name" defaultValue={lastName} changeHandler={(value) => onChange(value, "lastName")} />
                    </IdsFieldWrapper>
                </div>
                <div className="flex-row gap-3">
                    <IdsFieldWrapper customClasses="fill-width" htmlFor="company" wrapperLabel="Company">
                        <IdsTextInput idValue="company" defaultValue={company} changeHandler={(value) => onChange(value, "company")} />
                    </IdsFieldWrapper>
                    <IdsFieldWrapper customClasses="fill-width" htmlFor="title" wrapperLabel="Title">
                        <IdsTextInput idValue="title" defaultValue={title} changeHandler={(value) => onChange(value, "title")} />
                    </IdsFieldWrapper>
                </div>
                <div className="flex-row gap-3">
                    <IdsFieldWrapper
                        customClasses="fill-width"
                        htmlFor="phone-number"
                        wrapperLabel="Phone number"
                        isInvalid={!isEmpty(errors.phone)}
                        helperInvalidText={errors.phone}
                        isRequired
                    >
                        <IdsTextInput idValue="phone-number" defaultValue={phone} changeHandler={(value) => onChange(value, "phone")} />
                    </IdsFieldWrapper>
                    <IdsFieldWrapper customClasses="fill-width" htmlFor="extension" wrapperLabel="Extension">
                        <IdsTextInput
                            innerRef={extensionInputRef}
                            idValue="extension"
                            defaultValue={extension}
                            changeHandler={(value) => onChange(value, "extension")}
                        />
                    </IdsFieldWrapper>
                </div>
                <IdsFieldWrapper
                    customClasses="fill-width"
                    htmlFor="email"
                    wrapperLabel="Email"
                    isInvalid={!isEmpty(errors.email)}
                    helperInvalidText={errors.email}
                    isRequired
                >
                    <IdsTextInput idValue="email" defaultValue={email} changeHandler={(value) => onChange(value, "email")} />
                </IdsFieldWrapper>
            </div>
            <IdsButtonGroup spaceBetween="lg">
                <IdsButton variant="secondary" clickHandler={onCancelClick}>
                    Cancel
                </IdsButton>
                <IdsButton clickHandler={onSaveClick}>
                    <>{props.isLoading ? "Saving..." : "Save contact"}</>
                </IdsButton>
            </IdsButtonGroup>
        </div>
    );
};

export type ContactErrors = {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
};
