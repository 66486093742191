import { IdsTable, IdsTableRow, IdsTableCell, IdsCheckbox, IdsText } from "@emergn-infinity/ids-react";

import { Sector } from "types";

export const SectorsTable: React.FC<{
    activeSector: Sector | null;
    selectedSectors: Sector[];
    standardizingList: Sector[];
    onClick: (sector: Sector) => void;
    onChange: (sector: Sector) => void;
}> = ({ activeSector, selectedSectors, standardizingList, onClick, onChange }) => {
    return (
        <IdsTable spacing="lg">
            {standardizingList.map((sector) => (
                <IdsTableRow key={`${sector.sectorNumber}`}>
                    <IdsTableCell
                        style={{
                            backgroundColor:
                                activeSector?.sectorNumber === sector.sectorNumber
                                    ? "var(--ids-table-cell-body-background-color-clear-hover)"
                                    : undefined,
                        }}
                        onClick={() => onClick(sector)}
                    >
                        <div className="flex-row align-center">
                            <div>
                                <IdsCheckbox
                                    idValue={sector.sectorNumber}
                                    defaultChecked={selectedSectors.find((s) => s.sectorNumber === sector.sectorNumber) !== undefined}
                                    changeHandler={() => onChange(sector)}
                                />
                            </div>
                            <div className="pl-4">
                                <IdsText weight="bold">{`${sector.trmFamiliarName} > ${sector.sectorName}`}</IdsText>
                                <IdsText>{`Maps to: ${sector.sugStdSector ? sector.sugStdSector : ""}`}</IdsText>
                            </div>
                        </div>
                    </IdsTableCell>
                </IdsTableRow>
            ))}
        </IdsTable>
    );
};
