import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IdsButtonGroup, IdsText } from "@emergn-infinity/ids-react";

import { ContactBlock } from "./ContactBlock";

import { IconButton } from "components/IconButton";

import { getPhoneNumber } from "pages/ExploreTrms/utils";
import { deleteIcon, editIcon, userIcon, phoneIcon, emailIcon } from "utils/icons";

export const ContactCard: React.FC<{
    firstName: string;
    lastName: string;
    company?: string;
    title?: string;
    phone?: string;
    extension?: string;
    email?: string;
    onDelete?: () => void;
    onEdit?: () => void;
}> = ({ firstName, lastName, company, title, phone, extension, email, onDelete, onEdit }) => {
    const phoneNumber = getPhoneNumber(phone, extension);

    return (
        <div className="flex-column border rounded-edges-rounder gap-3 p-3">
            <div className="flex-row align-center">
                <div className="flex-column fill-width min-w-0 pr-2">
                    <ContactBlock firstName={firstName} lastName={lastName} company={company} bold />
                </div>
                {onDelete && onEdit && (
                    <div className="pl-2" style={{ borderLeft: "1px solid var(--theme-base-border)" }}>
                        <IdsButtonGroup customClasses="flex-no-wrap">
                            <IconButton icon={deleteIcon} size="lg" onClick={onDelete} />
                            <IconButton icon={editIcon} size="lg" onClick={onEdit} />
                        </IdsButtonGroup>
                    </div>
                )}
            </div>
            <div className="flex-column gap-2">
                {title && (
                    <div className="flex-row align-center gap-2">
                        <FontAwesomeIcon icon={userIcon} color="var(--ids-semantic-ink-color-brand-b-subtlest)" />
                        <IdsText style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>{title}</IdsText>
                    </div>
                )}
                {phoneNumber && (
                    <div className="flex-row align-center gap-2">
                        <FontAwesomeIcon icon={phoneIcon} color="var(--ids-semantic-ink-color-brand-b-subtlest)" />
                        <IdsText style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>{phoneNumber}</IdsText>
                    </div>
                )}
                {email && (
                    <div className="flex-row align-center gap-2">
                        <FontAwesomeIcon icon={emailIcon} color="var(--ids-semantic-ink-color-brand-b-subtlest)" />
                        <IdsText style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>{email}</IdsText>
                    </div>
                )}
            </div>
        </div>
    );
};
