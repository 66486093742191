import { isEmpty } from "lodash";

import { useGetCostQuery, useGetLookupQuery } from "store/apiSlice";
import { formatNumber } from "utils/string";

export const CostCell: React.FC<{
    costNumber: string;
    cost?: string;
    lookupNumber?: string;
}> = ({ costNumber, cost, lookupNumber }) => {
    const { data: costItem } = useGetCostQuery({ costNumber }, { skip: !lookupNumber });
    const { data: lookupItem, isFetching: isFetchingLookupItem } = useGetLookupQuery(
        { lookupNumber: lookupNumber! },
        { skip: !lookupNumber }
    );

    let value = formatNumber(cost ?? "");

    if (!isEmpty(lookupNumber)) {
        if (isFetchingLookupItem) {
            value = "";
        } else {
            value = `$${formatNumber(lookupItem?.lookupValue ?? "")}${costItem?.costUnit ? ` per ${costItem.costUnit}` : ""}`;
        }
    }

    return <span>{value}</span>;
};
