const defaultColors = [
    "#2ecc71", // emerald
    "#3498db", // peter river
    "#8e44ad", // wisteria
    "#e67e22", // carrot
    "#e74c3c", // alizarin
    "#1abc9c", // turquoise
    "#2296e6", // blue
];

function sumChars(str: string) {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i);
    }

    return sum;
}

export const Avatar: React.FC<{
    name: string;
    size?: number;
    firstNameInitial?: boolean;
}> = ({ name, size = 32, firstNameInitial }) => {
    // Pick a deterministic color from the list
    const index = sumChars(name) % defaultColors.length;
    const backgroundColor = defaultColors[index];

    const style: React.CSSProperties = {
        borderRadius: `${size / 2}px`,
        backgroundColor,
        width: `${size}px`,
        height: `${size}px`,
        fontFamily: "var(--ids-semantic-font-font-family-label)",
        fontSize: `${size * 0.4}px`,
        lineHeight: `${size}px`,
        textAlign: "center",
    };

    const tempName = name.replace(/\s/g, "");
    const avatarName = firstNameInitial ? tempName[0] : tempName;

    return (
        <div aria-label={name} style={style}>
            {avatarName}
        </div>
    );
};
