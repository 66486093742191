import { isEmpty } from "lodash";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { selectorChange } from "pages/ExploreTrms/exploreTrmsSlice";

import { IdsContainer, IdsRow, IdsCol, IdsText } from "@emergn-infinity/ids-react";

import { Sidebar } from "./Sidebar";
import { TrmDetailsTile } from "./TrmDetails/TrmDetailsTile";
import { MeasureLivesTile } from "./MeasureLives/MeasureLivesTile";
import { MeasureCostsTile } from "./MeasureCosts/MeasureCostsTile";
import { AlgorithmsTile } from "./Algorithms/AlgorithmsTile";
import { VariablesTile } from "./Variables/VariablesTile";
import { SavingsCalculationTile } from "./SavingsCalculationTile";
import { MeasureDetailsTile } from "./MeasureDetailsTile";

import { bookOpenIcon } from "utils/icons";

import { AlgorithmVariable, MeasureAlgorithm, MeasureVariable } from "types";
import { copyVariablesToClipboard } from "./utils";

export const ExploreTrms = () => {
    const dispatch = useAppDispatch();

    const { selectedTrm, selectedMeasure } = useAppSelector((state) => state.exploreTrms);

    const [selectedAlgorithm, setSelectedAlgorithm] = useState<MeasureAlgorithm>();
    const [algorithmVariables, setAlgorithmVariables] = useState<AlgorithmVariable[]>([]);

    const showMainContent = !isEmpty(selectedTrm) && !isEmpty(selectedMeasure);

    const onTrmSelect = (trmNumber: string) => {
        dispatch(selectorChange({ selector: "trm", value: trmNumber }));

        setSelectedAlgorithm(undefined);
        setAlgorithmVariables([]);
    };

    const onMeasureSelect = (measureNumber: string) => {
        dispatch(selectorChange({ selector: "measure", value: measureNumber }));

        setSelectedAlgorithm(undefined);
        setAlgorithmVariables([]);
    };

    const onMeasureClear = () => {
        dispatch(selectorChange({ selector: "measure", value: "" }));

        setSelectedAlgorithm(undefined);
        setAlgorithmVariables([]);
    };

    const onAlgorithmSelect = (algorithm: MeasureAlgorithm) => {
        setSelectedAlgorithm(algorithm);
        setAlgorithmVariables([]);
    };

    const onVariableChange = (variable: AlgorithmVariable) => {
        setAlgorithmVariables((prev) => {
            const index = prev.findIndex((v) => v.name === variable.name);
            if (index === -1) {
                return [...prev, variable];
            }

            return prev.map((v, i) => (i === index ? variable : v));
        });
    };

    const onExportVariables = async (variables: MeasureVariable[]) => {
        await copyVariablesToClipboard(variables, algorithmVariables);
    };

    return (
        <IdsContainer fullHeight customClasses="p-0">
            <IdsRow noGutters>
                <Sidebar
                    selectedTrm={selectedTrm}
                    selectedMeasure={selectedMeasure}
                    onTrmSelect={onTrmSelect}
                    onMeasureSelect={onMeasureSelect}
                    onMeasureClear={onMeasureClear}
                />
                <IdsCol xs="9" customClasses="with-scroll">
                    {showMainContent ? (
                        <div key={`${selectedTrm}-${selectedMeasure}`} className="flex-column gap-4 p-4">
                            <TrmDetailsTile selectedTrm={selectedTrm} selectedMeasure={selectedMeasure} />
                            <MeasureDetailsTile selectedMeasure={selectedMeasure} />
                            <MeasureLivesTile selectedMeasure={selectedMeasure} />
                            <MeasureCostsTile selectedMeasure={selectedMeasure} />
                            <AlgorithmsTile
                                selectedMeasure={selectedMeasure}
                                selectedAlgorithmNumber={selectedAlgorithm?.algorithmNumber}
                                onAlgorithmSelect={onAlgorithmSelect}
                            />
                            <VariablesTile
                                selectedMeasure={selectedMeasure}
                                algorithm={selectedAlgorithm}
                                allVariablesSet={algorithmVariables.every((variable) => !isEmpty(variable.value?.toString()))}
                                onVariableChange={onVariableChange}
                                onExport={onExportVariables}
                            />
                            <SavingsCalculationTile algorithm={selectedAlgorithm} variables={algorithmVariables} />
                        </div>
                    ) : (
                        <div className="flex-column align-center justify-center fill-height gap-2">
                            <FontAwesomeIcon icon={bookOpenIcon} color="var(--ids-semantic-ink-color-neutral-subtlest)" size="2xl" />
                            <IdsText
                                size="md"
                                weight="bold"
                                style={{
                                    color: "var(--ids-semantic-ink-color-neutral-subtlest)",
                                }}
                            >
                                Select TRM and Measure
                            </IdsText>
                        </div>
                    )}
                </IdsCol>
            </IdsRow>
        </IdsContainer>
    );
};
