import { get } from "lodash";

import { availableResources } from "./configureResources";

import { IndexSignature } from "types";

export const getKey = (id?: string | number) => `${id ? `-${id}` : ""}`;

export const getResourceUrl = (resourceName?: string, pathParams: IndexSignature<string | number | undefined> = {}) => {
    let url = null;

    const resourceUrl = availableResources[resourceName];

    if (resourceUrl) {
        if (Object.keys(pathParams).length) {
            url = Object.keys(pathParams).reduce((path, paramKey) => path.replace(`{${paramKey}}`, `${pathParams[paramKey]}`), resourceUrl);
        } else {
            url = resourceUrl;
        }
    }

    return url;
};

export const getResourceKey = ({ resourceId, key }: { resourceId?: string; key?: string | RegExp }) => {
    return key ? key : resourceId;
};

export const getResourceState = <T>(getState: Function, resourceName: string, params?: IndexSignature<string | number>): T[] => {
    let itemsByIdKey = resourceName;

    if (params) {
        Object.keys(params).forEach((key) => {
            itemsByIdKey += `-${params[key]}`;
        });
    }

    return (
        get(getState(), `resources.${resourceName}.itemsById[${itemsByIdKey}].data.model`) ||
        get(getState(), `resources.${resourceName}.itemsById[${itemsByIdKey}].data`) ||
        []
    );
};

export const loadStateFromStorage = (key: string, fallbackState: any) => {
    try {
        const serializedState = sessionStorage.getItem(key);
        if (serializedState) {
            return JSON.parse(serializedState);
        }
    } catch (error) {
        console.error("Could not load state", error);
    }

    return fallbackState;
};
