export const TourImage: React.FC<{
    imageUrl: string;
}> = ({ imageUrl }) => {
    return (
        <div
            className="fill-height"
            style={{
                backgroundImage: `url("${imageUrl}")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
        />
    );
};
