import { useMemo } from "react";

export const usePagination = <T>(listItems: T[] = [], { pageSize, pageNumber }: { pageSize: number; pageNumber: number }) => {
    const [totalRows, totalPages] = useMemo(() => {
        const totalRows = listItems.length;
        const totalPages = Math.ceil(listItems.length / pageSize);

        return [totalRows, totalPages];
    }, [listItems, pageSize]);

    const items = listItems.slice((pageNumber - 1) * pageSize, (pageNumber - 1) * pageSize + pageSize);

    return { totalRows, totalPages, items };
};
