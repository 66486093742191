import { WindowContainerType, View } from "store/window/types";

const storage = sessionStorage;
const WINDOW_STATE_KEY = "window-state";

export const EMPTY_SPLIT_VIEW_NAME = "SplitView";
export const EMPTY_SPLIT_VIEW_TITLE = " ";

export const windowContainerTypes: {
    Root: WindowContainerType;
    Login: WindowContainerType;
    Home: WindowContainerType;
} = {
    Root: "root-window",
    Login: "login-window",
    Home: "home-window",
};

export const clearBrowserUrl = () => {
    window.history.pushState("", "", "/");
};

export const getWindowState = () => {
    const item = storage.getItem(WINDOW_STATE_KEY);

    if (item !== null) {
        return JSON.parse(item);
    } else {
        return {};
    }
};

export const setWindowState = (state: any) => {
    if (state !== null) {
        storage.setItem(WINDOW_STATE_KEY, JSON.stringify(state));
    }
};

export const deleteWindowState = () => {
    storage.removeItem(WINDOW_STATE_KEY);
};

export const getLoginWindowParams = (): View => {
    return {
        containerName: windowContainerTypes.Login,
        name: "Login",
        component: "Login",
        showHeader: false,
        showTabs: false,
        activate: true,
    };
};
