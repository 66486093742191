import { isEmpty } from "lodash";

import { IdsDropdown } from "@emergn-infinity/ids-react";

// NOTE: If stdVariablesList on initial render in an empty array
// initialSelectedItems will be set, but will not show up
// in the dropdown field.
export const StandardVariable: React.FC<{
    stdVariableNumber: string;
    stdVariablesList: {
        label: string;
        value: string;
    }[];
    onSelect: (value: string) => void;
}> = ({ stdVariableNumber, stdVariablesList, onSelect }) => {
    return (
        <IdsDropdown
            idValue="select-standard-variable"
            isSearchable
            items={stdVariablesList}
            placeholder="Select Standard Variable"
            initialSelectedItems={isEmpty(stdVariableNumber) ? null : [stdVariableNumber]}
            changeHandler={onSelect}
            clearHandler={() => onSelect("")}
        />
    );
};
