import { memo } from "react";

import { useAppSelector } from "store/hooks";

import Window from "./Window";
import WindowTabs from "./WindowTabs";

import { useViews } from "utils/useViews";
import { windowContainerTypes } from "utils/window";

import { WindowContainerType } from "store/window/types";

const WindowsContainer = memo((props: WindowsContainerProps) => {
    const {
        containerName,

        onOpen,
        onClose,

        onSplit,
        onRemoveSplit,
        onClearSplit,

        onActivate,
        onSync,
    } = props;

    const tour = useAppSelector((state) => state.tour);

    const views = useViews({ containerName });

    const isSplitViewActive = views.filter((view) => view.active)[0] && views.filter((view) => view.active)[0].isSplitViewActive;

    return (
        <div className="flex-column flex-one-in-row no-scroll">
            <div className="flex-row flex-one no-scroll h-0">
                {views.map((view) => (
                    <Window
                        key={view.name}
                        containerName={containerName}
                        name={view.name}
                        isSplitViewActive={isSplitViewActive}
                        onOpen={onOpen}
                        onClose={onClose}
                        onSplit={onSplit}
                        onRemoveSplit={onRemoveSplit}
                        onClearSplit={onClearSplit}
                    />
                ))}
            </div>
            <WindowTabs containerName={containerName} onClose={onClose} onActivate={onActivate} onSync={onSync} />
            {containerName !== windowContainerTypes.Login && tour.enabled && <div className="bg-overlay" style={{ zIndex: 10 }} />}
        </div>
    );
});

interface WindowsContainerProps {
    /**
     * View container type.
     */
    containerName: WindowContainerType;

    /**
     * Function that opens a new View.
     */
    onOpen: (params: any) => void;

    /**
     * Function that closes a View.
     */
    onClose: (name: string) => void;

    /**
     * Function that splits the View.
     */
    onSplit: (name: string) => void;

    /**
     * Function that unsplits the View.
     */
    onRemoveSplit: (name: string) => void;

    /**
     * Function that clears the chosen side
     * of the Split View to blank View.
     */
    onClearSplit: (name: string, side: "left" | "right") => void;

    /**
     * Function that activates a View.
     */
    onActivate: (name: string) => void;

    /**
     * Function that enables/disables
     * sync scrolling for Split View.
     */
    onSync: (name: string) => void;
}

export default WindowsContainer;
