import Login from "./Login";
import ResetPassword from "./Login/ResetPassword";
import ChangePassword from "./Login/ChangePassword";
import ForgotPassword from "./Login/ForgotPassword";
import QrCode from "./Login/QrCode";
import SamlView from "./Login/SamlView";

import Home from "./Home";

import { IndexSignature } from "types";

export const availableViews: IndexSignature<any> = {
    Login,
    ResetPassword,
    ChangePassword,
    ForgotPassword,
    QrCode,
    SamlView,

    Home,
};
