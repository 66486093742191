import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

import { IdsText, IdsButtonGroup, IdsButton, IdsFieldWrapper, IdsTextInput } from "@emergn-infinity/ids-react";

export const LinkEditCard: React.FC<{
    linkName?: string;
    urlLink?: string;
    isLoading: boolean;
    onSave: (linkName: string, urlLink: string) => void;
    onCancel: () => void;
}> = (props) => {
    const [linkName, setLinkName] = useState(props.linkName ?? "");
    const [urlLink, setUrlLink] = useState(props.urlLink ?? "");
    const [errors, setErrors] = useState<LinkErrors>({});

    const onChange = (value: string, name: string) => {
        if (name === "linkName") {
            setLinkName(value);
        } else if (name === "urlLink") {
            setUrlLink(value);
        }

        setErrors({
            ...errors,
            [name]: undefined,
        });
    };

    const onClearAll = useCallback(() => {
        setLinkName("");
        setUrlLink("");
    }, []);

    const onCancelClick = () => {
        setErrors({});

        props.onCancel();
    };

    const onSaveClick = () => {
        if (isEmpty(linkName) || isEmpty(urlLink)) {
            setErrors({
                linkName: isEmpty(linkName) ? "Name is required" : undefined,
                urlLink: isEmpty(urlLink) ? "URL is required" : undefined,
            });

            return;
        }

        props.onSave(linkName, urlLink);
    };

    return (
        <div className="flex-column border rounded-edges-rounder p-3">
            <div className="flex-row align-center justify-space-between pb-2">
                <IdsText weight="bold">New link</IdsText>
                <IdsButton variant="tertiary" clickHandler={onClearAll}>
                    Clear all
                </IdsButton>
            </div>
            <div className="flex-column pb-2">
                <IdsFieldWrapper
                    customClasses="fill-width"
                    htmlFor="name"
                    wrapperLabel="Name"
                    isInvalid={!isEmpty(errors.linkName)}
                    helperInvalidText={errors.linkName}
                    isRequired
                >
                    <IdsTextInput idValue="name" defaultValue={linkName} changeHandler={(value) => onChange(value, "linkName")} />
                </IdsFieldWrapper>
                <IdsFieldWrapper
                    customClasses="fill-width"
                    htmlFor="url"
                    wrapperLabel="URL"
                    isInvalid={!isEmpty(errors.urlLink)}
                    helperInvalidText={errors.urlLink}
                    isRequired
                >
                    <IdsTextInput idValue="url" defaultValue={urlLink} changeHandler={(value) => onChange(value, "urlLink")} />
                </IdsFieldWrapper>
            </div>
            <IdsButtonGroup spaceBetween="lg">
                <IdsButton variant="secondary" clickHandler={onCancelClick}>
                    Cancel
                </IdsButton>
                <IdsButton clickHandler={onSaveClick}>
                    <>{props.isLoading ? "Saving..." : "Save link"}</>
                </IdsButton>
            </IdsButtonGroup>
        </div>
    );
};

export type LinkErrors = {
    linkName?: string;
    urlLink?: string;
};
