import * as actionTypes from "store/actionTypes";

const initialState = {
    isLoading: false,
    errorMessage: null,
    infoMessage: null,
    logOutPending: false,
    twoFactorPending: false,
    twoFactorRequired: false,
    twoFactorQrCodeExists: false,
    repeatActions: [],
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_RESET:
            state = initialState;
            break;

        case actionTypes.UPDATE_PASSWORD_REQUEST:
        case actionTypes.FORGOT_PASSWORD_REQUEST:
            state = {
                ...state,
                isLoading: true,
                infoMessage: null,
                errorMessage: null,
            };

            break;

        case actionTypes.LOGIN_USER_2FA_REQUEST:
            state = {
                ...state,
                twoFactorPending: true,
                infoMessage: null,
                errorMessage: null,
            };

            break;

        case actionTypes.LOGIN_RESPONSE:
        case actionTypes.UPDATE_PASSWORD_RESPONSE:
        case actionTypes.UPDATE_PASSWORD_ERROR_CLEAR:
            state = {
                ...state,
                isLoading: false,
                infoMessage: null,
                errorMessage: null,
            };

            break;

        case actionTypes.FORGOT_PASSWORD_RESPONSE:
            state = {
                ...state,
                isLoading: false,
                infoMessage: action.message,
                errorMessage: null,
            };

            break;

        case actionTypes.LOGIN_USER_2FA_RESPONSE:
            state = {
                ...state,
                twoFactorRequired: action.data.twoFactorRequired,
                twoFactorQrCodeExists: action.data.qrCodeExists,
                twoFactorPending: false,
                infoMessage: null,
                errorMessage: null,
            };

            break;

        case actionTypes.LOGIN_ERROR:
        case actionTypes.UPDATE_PASSWORD_ERROR:
        case actionTypes.FORGOT_PASSWORD_ERROR:
            state = {
                ...state,
                isLoading: false,
                infoMessage: null,
                errorMessage: action.message,
            };

            break;

        case actionTypes.LOGIN_USER_2FA_ERROR:
            state = {
                ...state,
                twoFactorPending: false,
                infoMessage: null,
                errorMessage: "Two factor authentication check failed. Please try again later!",
            };

            break;

        default:
            break;
    }

    return state;
};
