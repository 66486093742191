import { SET_SERVER_TIMEZONE_OFFSET } from "store/actionTypes";

import { getInitialServerTimezoneOffset } from "utils/date";

const initialState = {
    serverTimezoneOffset: null,
};

export const reducer = (state, action) => {
    state = state ?? {
        ...initialState,
        serverTimezoneOffset: getInitialServerTimezoneOffset(),
    };

    switch (action.type) {
        case SET_SERVER_TIMEZONE_OFFSET:
            state = {
                ...state,
                serverTimezoneOffset: action.serverTimezoneOffset,
            };
            break;
        default:
            break;
    }

    return state;
};
