import React, { useCallback, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { IdsList } from "@emergn-infinity/ids-react";

import { MenuButton } from "./MenuButton";
import { MenuItem } from "./MenuItem";
import { SubMenu } from "./SubMenu";

import { useClickAway } from "utils/useClickAway";

import "./style.scss";

export const Menu: React.FC<{
    active?: boolean;
    labelValue: string;
    label?: string;
    items?: MenuItemType[];
    onClose?: () => void;
}> = ({ active, labelValue, label, items, onClose }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);

    const onClickAway = useCallback(() => {
        setMenuOpen(false);

        onClose?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const menuRef = useClickAway<HTMLDivElement>(onClickAway);

    const onButtonClick = () => {
        setMenuOpen(!menuOpen);
        setSubMenuOpen(false);

        if (menuOpen) {
            onClose?.();
        }
    };

    const onCloseMenu = () => {
        setMenuOpen(false);

        onClose?.();
    };

    return (
        <div ref={menuRef} className="menu">
            <MenuButton active={active} labelValue={labelValue} label={label} isOpen={menuOpen} onClick={onButtonClick} />
            <div className="menu-list-container">
                <IdsList customClasses="border gap-0 pt-3 pb-0 px-0 menu-list-component" isOpen={menuOpen}>
                    {items?.map((item) => (
                        <React.Fragment key={`menu-item-${item.itemValue}`}>
                            <MenuItem {...item} isOpen={subMenuOpen} onClick={item.subItems ? () => setSubMenuOpen(true) : undefined} />
                            {item.subItems && (
                                <div className="sub-menu-list-container">
                                    <SubMenu
                                        isOpen={subMenuOpen}
                                        items={item.subItems}
                                        heading={item.subHeading}
                                        actions={item.subActions}
                                        onClose={onCloseMenu}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </IdsList>
            </div>
        </div>
    );
};

export type SubAction = {
    variant: "primary" | "secondary" | "tertiary";
    label: string;
    onClick?: () => void;
};

export type MenuItemType = {
    variant?: "option" | "divider" | "action";
    heading?: string;
    iconRight?: boolean;
    customIconRight?: React.ReactElement;
    iconLeft?: IconProp;
    iconLeftStyle?: React.CSSProperties;
    actionDisabled?: boolean;
    itemValue?: string;
    subHeading?: string;
    subActions?: SubAction[];
    subItems?: React.ReactElement[];
};
