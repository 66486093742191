import { useEffect, useMemo } from "react";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { windowContainerReset, windowAdd } from "store/window/actions";

import Windows from "./Windows";

import { clientRoute } from "utils/constants";
import { isAuthenticated, isQrCode, isStatusExpired } from "utils/user";
import { windowContainerTypes, getLoginWindowParams } from "utils/window";
import { isInIframe } from "utils/dom";

// Set default theme class
document.body.className = "theme-default";

const WindowManager = () => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user);

    // Variables

    const isUserAuthenticated = isAuthenticated(user);
    const isUserQrCode = isQrCode(user);
    const isUserExpired = isStatusExpired(user);

    const containerName = isUserAuthenticated ? windowContainerTypes.Root : windowContainerTypes.Login;

    const isResetPasswordRoute = window.location.pathname.split("/")[1] === clientRoute.resetPassword;
    const isSamlRoute = [clientRoute.saml, clientRoute.sso].includes(window.location.pathname.split("/")[1]);

    const initialViews = useMemo(() => {
        let initialViews = [getLoginWindowParams()];

        if (isUserAuthenticated) {
            initialViews = [
                {
                    containerName: windowContainerTypes.Root,
                    name: "Home",
                    headerTitle: "TRMulator",
                    component: "Home",
                    showTabs: false,
                    showHeader: !isInIframe(),
                },
            ];
        }

        return initialViews;
    }, [isUserAuthenticated]);

    useEffect(() => {
        if (!isUserAuthenticated && !isUserQrCode && !isUserExpired && !isResetPasswordRoute && !isSamlRoute) {
            dispatch(windowContainerReset({ containerName }));
            dispatch(windowAdd(initialViews[0]));
        }
    }, [isUserAuthenticated, isUserQrCode, isUserExpired, containerName, isResetPasswordRoute, isSamlRoute, initialViews, dispatch]);

    return <Windows containerName={containerName} initialViews={initialViews} persist={isUserAuthenticated} />;
};

export default WindowManager;
