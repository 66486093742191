import { memo, useCallback, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { RESET_PASSWORD_ERROR, RESET_PASSWORD_ERROR_CLEAR } from "store/actionTypes";
import * as loginActions from "store/login/actions";

import { IdsButton } from "@emergn-infinity/ids-react";

import IconLoading from "components/IconLoading";
import Input from "components/Input";
import ErrorMsg from "components/StatusMsg/ErrorMsg";
import Captcha from "components/Captcha";
import FieldGroup from "components/FieldGroup";

import FormWrap from "pages/Login/FormWrap";

import { clearBrowserUrl } from "utils/window";
import { openWindowLogin } from "store/window/actions";

import "./style.scss";

const ResetPassword = memo(() => {
    const dispatch = useAppDispatch();

    // @ts-ignore
    // TODO: Does not exist
    const resetPassword = useAppSelector((state) => state.resetPassword);

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [recaptcha, setRecaptcha] = useState<string | null>(null);

    const captchaRef = useRef<ReCAPTCHA>(null);

    const canSubmit = newPassword.trim().length > 0 && confirmPassword.trim().length > 0 && recaptcha !== null;

    const isValid = useCallback(() => {
        const isValid = canSubmit && newPassword.trim() === confirmPassword.trim();

        if (!isValid) {
            dispatch({
                type: RESET_PASSWORD_ERROR,
                message: "Passwords do not match",
            });
        }

        return isValid;
    }, [newPassword, confirmPassword, canSubmit, dispatch]);

    const onChange = useCallback(
        (event, handler) => {
            dispatch({
                type: RESET_PASSWORD_ERROR_CLEAR,
            });

            handler(event.target.value);
        },
        [dispatch],
    );

    const onSubmit = useCallback(
        (event) => {
            event.preventDefault();

            if (isValid()) {
                const pathParts = window.location.pathname.split("/");

                if (pathParts.length > 3) {
                    const userNumber = pathParts[2];
                    const temporaryPassword = pathParts[3];

                    dispatch(
                        // @ts-ignore
                        loginActions.resetPassword({
                            userNumber,
                            temporaryPassword,
                            newPassword,
                            recaptcha,
                            authCode: "",
                        }),
                    );

                    captchaRef.current && captchaRef.current.reset();
                } else {
                    // @ts-ignore
                    dispatch(openWindowLogin());
                }
            }
        },
        [newPassword, recaptcha, isValid, dispatch],
    );

    const onRedirect = useCallback(() => {
        clearBrowserUrl();
        // @ts-ignore
        dispatch(loginActions.resetLogin());
    }, [dispatch]);

    return (
        <FormWrap>
            <form className="flex-column reset-password-form" onSubmit={onSubmit}>
                {resetPassword.isLoading ? (
                    <div className="flex-column flex-one align-center justify-center">
                        <IconLoading />
                    </div>
                ) : (
                    <>
                        {resetPassword.message && (
                            <div className="reset-password-form__error-message">
                                <ErrorMsg largeMsg message={resetPassword.message} />
                            </div>
                        )}
                        <div className="text-center reset-password-form__header">Reset password for your account</div>
                        <FieldGroup className="reset-password-form__inputs">
                            <Input
                                label="New password"
                                type="password"
                                placeholder="Enter your new password"
                                required
                                value={newPassword}
                                onChange={(event) => onChange(event, setNewPassword)}
                            />
                            <Input
                                label="Confirm password"
                                type="password"
                                placeholder="Confirm your new password"
                                required
                                value={confirmPassword}
                                onChange={(event) => onChange(event, setConfirmPassword)}
                            />
                            <div className="flex-column align-center">
                                <Captcha captchaRef={captchaRef} onChange={(token) => setRecaptcha(token)} />
                            </div>
                        </FieldGroup>
                    </>
                )}
                <div className="flex-row align-center justify-space-between">
                    <span className="reset-password-form__redirect" onClick={onRedirect}>
                        Return to login page
                    </span>
                    <IdsButton
                        type="submit"
                        variant="primary"
                        isDisabled={resetPassword.isLoading || !canSubmit}
                        isLoading={resetPassword.isLoading}
                    >
                        <>{resetPassword.isLoading ? "Loading..." : "Save New Password"}</>
                    </IdsButton>
                </div>
            </form>
        </FormWrap>
    );
});

export default ResetPassword;
