import { isEmpty, isNil } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGetLookupsQuery, useCreateLookupsMutation } from "store/apiSlice";

import {
    IdsFieldWrapper,
    IdsButton,
    IdsButtonGroup,
    IdsTextInput,
    IdsTable,
    IdsTableRow,
    IdsTableCell,
    IdsText,
    IdsModal,
} from "@emergn-infinity/ids-react";

import { EditFormTags } from "./EditFormTags";

import { IconButton } from "components/IconButton";

import { arrowLeftIcon, deleteIcon, addIcon, exportIcon } from "utils/icons";
import { sanitizeAndSetNumericInput } from "utils/string";

import { LookupType, Measure, LookupEntry } from "types";
import { copyLookupsToClipboard } from "./utils";

const LOOKUPS_MODAL_STYLE = {
    width: "640px",
    maxWidth: "100%",
};

export const ManageLookupsModal: React.FC<{
    measure: Measure;
    lookupType: LookupType;
    title: string;
    lookupByPremise?: string;
    sector?: string;
    endUse?: string;
    vintage?: string;
    modalStyle?: React.CSSProperties;
    onClose: () => void;
}> = ({ measure, lookupType, title, lookupByPremise, sector, endUse, vintage, modalStyle, onClose }) => {
    const readonlyMode = !isEmpty(lookupByPremise);

    const { isSaving, hasErrors, lookups, setLookups, tableName, setTableName, saveLookups } = useLookupsManagement({
        trmNumber: measure.trmNumber,
        lookupType,
        lookupByPremise,
        onSaved: onClose,
    });

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {title}
                </IdsText>
            </div>
            <div slot="main" style={modalStyle ?? LOOKUPS_MODAL_STYLE}>
                <EditFormTags
                    trmName={measure.trmFamiliarName}
                    measureName={measure.measureName}
                    sector={sector}
                    endUse={endUse}
                    vintage={vintage}
                />
                <ManageLookups
                    lookups={lookups}
                    tableName={tableName}
                    hasErrors={hasErrors}
                    readonlyMode={readonlyMode}
                    setLookups={setLookups}
                    setTableName={setTableName}
                />
            </div>
            <div slot="footer" className="flex-row justify-space-between">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        <>
                            {readonlyMode ? (
                                <div className="flex-row gap-2 align-center">
                                    <FontAwesomeIcon icon={arrowLeftIcon} fixedWidth />
                                    Back
                                </div>
                            ) : (
                                "Cancel"
                            )}
                        </>
                    </IdsButton>
                    {!readonlyMode && (
                        <IdsButton variant="primary" clickHandler={saveLookups}>
                            <>{isSaving ? "Saving..." : "Save lookup table"}</>
                        </IdsButton>
                    )}
                </IdsButtonGroup>
                <IdsButton
                    customClasses="justify-end"
                    variant="secondary"
                    clickHandler={() => copyLookupsToClipboard(lookups)}
                    title="Copy lookups to clipboard"
                >
                    <div className="flex-row gap-2 align-center">
                        <FontAwesomeIcon icon={exportIcon} fixedWidth />
                        Export
                    </div>
                </IdsButton>
            </div>
        </IdsModal>
    );
};

const ManageLookups: React.FC<{
    lookups: LookupEntry[];
    tableName: string;
    hasErrors: boolean;
    readonlyMode: boolean;
    setLookups: (lookups: LookupEntry[]) => void;
    setTableName: (tableName: string) => void;
}> = ({ lookups, tableName, hasErrors, readonlyMode, setLookups, setTableName }) => {
    const onAddLine = () => {
        setLookups([...lookups, { lookupCriteria: "", lookupValue: undefined! }]);

        // focus the added line
        setTimeout(() => {
            const criteriaInputs = document.querySelectorAll(".lookup-criteria");
            if (criteriaInputs.length > 0) {
                criteriaInputs[criteriaInputs.length - 1].querySelector("input")?.focus();
            }
        }, 100);
    };

    // list of refs to input elements
    const inputRefs = useMemo(() => lookups.map(() => React.createRef<HTMLInputElement>()), [lookups]);

    const onCriteriaChange = (index: number, value: string) => {
        setLookups(lookups.map((item, i) => (i === index ? { ...item, lookupCriteria: value } : item)));
    };

    const onValueChange = (index: number, value: string) => {
        setLookups(
            lookups.map((item, i) => (i === index ? { ...item, lookupValue: sanitizeAndSetNumericInput(value, inputRefs[index])! } : item)),
        );
    };

    const onCriteriaPaste = (index: number) => (event: React.ClipboardEvent<HTMLIdsTextInputElement>) => {
        const lookupTable = event.clipboardData.getData("text/plain");

        const newLookups = lookupTable
            .split("\n")
            .map((row) => row.split("\t"))
            .map((rowData) => ({
                lookupCriteria: (rowData[0] ?? "").replace("\r", ""),
                lookupValue: isFinite(Number(rowData[1])) ? Number(rowData[1]) : undefined!,
            }))
            // Remove invalid values
            .filter((item) => !isEmpty(item.lookupCriteria) || !isNil(item.lookupValue));

        if (newLookups.length === 0 || (newLookups.length === 1 && isNil(newLookups[0].lookupValue))) {
            return;
        }

        event.preventDefault();

        const allLookups = [...lookups, ...newLookups];
        // Remove the empty item at index
        allLookups.splice(index, 1);

        setLookups(allLookups);

        // focus last input field
        setTimeout(() => {
            const inputs = document.querySelectorAll(".lookup-value");
            if (inputs.length > 0) {
                inputs[inputs.length - 1].querySelector("input")?.focus();
            }
        }, 100);
    };

    return (
        <>
            <IdsFieldWrapper
                wrapperLabel="Lookup table name"
                isRequired
                isInvalid={hasErrors && isEmpty(tableName)}
                isReadonly={readonlyMode}
            >
                <IdsTextInput defaultValue={tableName} changeHandler={setTableName} />
            </IdsFieldWrapper>
            <IdsTable spacing="sm">
                <IdsTableRow rowType="table-heading-row">
                    <IdsTableCell cellType="table-heading-cell" heading="" style={{ minWidth: 0, width: "2rem" }} />
                    <IdsTableCell cellType="table-heading-cell" heading="Criteria" style={{ minWidth: 0, width: "auto" }} />
                    <IdsTableCell cellType="table-heading-cell" heading="Value" style={{ minWidth: 0, width: "6rem" }} />
                    {!readonlyMode && <IdsTableCell cellType="table-heading-cell" heading="" style={{ minWidth: 0, width: "0" }} />}
                </IdsTableRow>
                {lookups.map((item, index) => (
                    <IdsTableRow key={`criteria-${index}`} rowType="table-body-row">
                        <IdsTableCell style={{ minWidth: 0 }}>{index + 1}.</IdsTableCell>
                        <IdsTableCell style={{ minWidth: 0 }}>
                            <IdsTextInput
                                customClasses="lookup-criteria"
                                defaultValue={item.lookupCriteria}
                                changeHandler={(value) => onCriteriaChange(index, value)}
                                isInvalid={hasErrors && isEmpty(item.lookupCriteria)}
                                isReadonly={readonlyMode}
                                onPaste={onCriteriaPaste(index)}
                            />
                        </IdsTableCell>
                        <IdsTableCell style={{ minWidth: 0 }}>
                            <IdsTextInput
                                customClasses="lookup-value"
                                innerRef={inputRefs[index]}
                                defaultValue={String(item.lookupValue ?? "")}
                                changeHandler={(value) => onValueChange(index, value)}
                                isInvalid={hasErrors && !item.lookupValue}
                                isReadonly={readonlyMode}
                            />
                        </IdsTableCell>
                        {!readonlyMode && (
                            <IdsTableCell style={{ minWidth: 0 }}>
                                <IdsButtonGroup position="right">
                                    <IconButton
                                        icon={deleteIcon}
                                        size="lg"
                                        title="Remove"
                                        onClick={() => setLookups(lookups.filter((_, i) => i !== index))}
                                    />
                                </IdsButtonGroup>
                            </IdsTableCell>
                        )}
                    </IdsTableRow>
                ))}
            </IdsTable>
            <IdsButtonGroup position={readonlyMode ? "right" : "justify"} customClasses="py-1">
                {!readonlyMode && (
                    <IdsButton padding="sm" variant="tertiary" clickHandler={onAddLine}>
                        <div className="flex-row gap-2 align-center">
                            <FontAwesomeIcon icon={addIcon} fixedWidth />
                            Add line
                        </div>
                    </IdsButton>
                )}
                <IdsText size="md" weight="light" component="span">
                    {lookups.length} lookups
                </IdsText>
            </IdsButtonGroup>
        </>
    );
};

const useLookupsManagement = ({
    trmNumber,
    lookupType,
    lookupByPremise,
    onSaved,
}: {
    trmNumber: string;
    lookupType: LookupType;
    lookupByPremise?: string;
    onSaved: () => void;
}) => {
    const { data: lookupsData, isLoading: isLoadingLookupsData } = useGetLookupsQuery(
        { trmNumber, lookupType, lookupByPremise },
        { skip: isEmpty(lookupByPremise) },
    );

    const [hasErrors, setHasErrors] = useState(false);
    const [lookups, setLookups] = useState<LookupEntry[]>([]);
    const [tableName, setTableName] = useState(lookupByPremise ?? "");

    const [create, createStatus] = useCreateLookupsMutation();

    // If read-only mode, fill with lookups
    if (!isEmpty(lookupByPremise) && lookupsData !== undefined && !isEmpty(lookupsData) && !isLoadingLookupsData && isEmpty(lookups)) {
        const newLookups = lookupsData.map((lookup) => ({ lookupCriteria: lookup.lookupCriteria, lookupValue: lookup.lookupValue }));

        setLookups(newLookups);
    }

    const saveLookups = useCallback(async () => {
        if (createStatus.isLoading || lookups.length === 0) {
            return;
        }

        // Set error if table name or any lookup criteria or value is empty
        if (isEmpty(tableName) || lookups.some((item) => isEmpty(item.lookupCriteria) || isEmpty(String(item.lookupValue)))) {
            setHasErrors(true);
            return;
        }

        try {
            await create({
                lookups: {
                    trmNumber,
                    lookupByPremise: tableName,
                    lookupType,
                    lookupVerified: true,
                    entries: lookups,
                },
            }).unwrap();

            onSaved();
        } catch (error) {
            console.error(error);
        }
    }, [create, createStatus.isLoading, trmNumber, tableName, lookupType, lookups, onSaved]);

    return {
        isSaving: createStatus.isLoading,
        hasErrors,
        lookups,
        tableName,
        setLookups,
        setTableName,
        saveLookups,
    };
};
