import { useLayoutEffect, useEffect, useRef } from "react";

/**
 * https://usehooks.com/useclickaway
 *
 * Detects clicks outside of specific component.
 * @param handler function to run when clicked outside of element
 * @returns ref element that needs to be connected to the component
 */
export function useClickAway<T>(handler: (event?: MouseEvent | TouchEvent) => void) {
    const ref = useRef<T>(null);
    const handlerRef = useRef(handler);

    useLayoutEffect(() => {
        handlerRef.current = handler;
    });

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            const element = ref.current as HTMLElement;

            if (element && !element.contains(event.target as Node)) {
                handlerRef.current(event);
            }
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, []);

    return ref;
}
