import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IdsButton } from "@emergn-infinity/ids-react";

import { MeasureLivesGrid } from "components/Grids/MeasureLivesGrid";
import { Tile } from "components/Tile";

import { MeasureLifeEdit } from "./MeasureLifeEdit";
import { MeasureLifeDelete } from "./MeasureLifeDelete";

import { Tabs } from "pages/Home/utils";

import { useMeasureDetails } from "../utils";

import { MeasureLivesRights } from "utils/constants";
import { addIcon } from "utils/icons";
import { hasRights } from "utils/user";

export const MeasureLivesTile: React.FC<{
    selectedMeasure: string;
}> = ({ selectedMeasure }) => {
    const [isAdding, setIsAdding] = useState(false);
    const [eulNumberForEdit, setEulNumberForEdit] = useState<string>();
    const [eulNumberForDelete, setEulNumberForDelete] = useState<string>();

    const measure = useMeasureDetails(selectedMeasure);

    const editRights = hasRights(MeasureLivesRights);

    return (
        <Tile
            title="Measure Lives"
            action={
                editRights && (
                    <IdsButton padding="sm" variant="secondary" clickHandler={() => setIsAdding(true)}>
                        <div className="flex-row gap-2 align-center">
                            <FontAwesomeIcon icon={addIcon} fixedWidth />
                            Add Measure Life
                        </div>
                    </IdsButton>
                )
            }
        >
            <div className="p-3">
                {eulNumberForEdit && measure && (
                    <MeasureLifeEdit eulNumber={eulNumberForEdit} measure={measure} onClose={() => setEulNumberForEdit(undefined)} />
                )}
                {eulNumberForDelete && measure && (
                    <MeasureLifeDelete eulNumber={eulNumberForDelete} measure={measure} onClose={() => setEulNumberForDelete(undefined)} />
                )}
                {isAdding && measure && <MeasureLifeEdit measure={measure} onClose={() => setIsAdding(false)} />}
                <MeasureLivesGrid
                    gridType={Tabs.ExploreTrms.id}
                    styles={{
                        sector: {
                            minWidth: 0,
                        },
                        vintage: {
                            minWidth: 0,
                        },
                        eul: {
                            minWidth: 0,
                        },
                        rul: {
                            minWidth: 0,
                        },
                    }}
                    measureNumber={measure?.measureNumber}
                    editRights={editRights}
                    onEdit={setEulNumberForEdit}
                    onDelete={setEulNumberForDelete}
                />
            </div>
        </Tile>
    );
};
