import { isNil } from "lodash";

import * as actionTypes from "store/actionTypes";
import { endpoints } from "store/apiSlice";
import { availableResources } from "store/configureResources";
import { getResourceUrl } from "store/utils";

import { setServerTimezoneOffset } from "store/system/actions";
import { windowActivate, windowRemove } from "store/window/actions";

import { createUser, getUserNameFromToken, saveUser } from "utils/user";
import { clearBrowserUrl, deleteWindowState, getLoginWindowParams, windowContainerTypes } from "utils/window";

async function updateUser(action, userName, errorActionType, dispatch) {
    const { data } = action;
    const { serverTimezoneOffset, responseMessage } = data;

    const user = createUser({
        ...data,
        userName,
    });

    if (!isNil(serverTimezoneOffset)) {
        dispatch(setServerTimezoneOffset({ serverTimezoneOffset }));
    }

    switch (user.userStatus) {
        case "active":
            saveUser(user);

            dispatch({
                type: actionTypes.USER_SAVE,
                user,
            });

            break;

        case "expired":
            dispatch({
                type: actionTypes.USER_SAVE,
                user,
            });

            break;

        default:
            dispatch({
                type: actionTypes.USER_SAVE,
                user,
            });

            dispatch({
                type: errorActionType,
                message: responseMessage,
            });
            break;
    }
}

export const login =
    ({ userName, password, authCode, oneTimeCode }) =>
    async (dispatch) => {
        try {
            const loginResponse = await dispatch(endpoints.login.initiate({ userName, password, authCode, oneTimeCode }));

            dispatch({
                type: actionTypes.LOGIN_RESPONSE,
            });

            // Throw if failure
            if (loginResponse.data.responseStatus === "failure") {
                throw new Error(loginResponse.data.responseMessage);
            }

            // Get user rights
            const rightsResponse = await dispatch(endpoints.getSecurityRights.initiate({ accessToken: loginResponse.data.accessToken }));

            // Throw if error
            if (rightsResponse.error) {
                throw new Error(rightsResponse.error?.message);
            }

            // Add user rights to the response
            const response = {
                data: {
                    ...loginResponse.data,
                    rights: rightsResponse.data,
                },
            };

            updateUser(response, userName, actionTypes.LOGIN_ERROR, dispatch);
        } catch (error) {
            dispatch({
                type: actionTypes.LOGIN_ERROR,
                message: error.message,
            });
        }
    };

export const loginUser2fa =
    ({ userName }) =>
    async (dispatch, getState) => {
        if (getState().login.isLoading) {
            return;
        }

        dispatch({
            type: actionTypes.LOGIN_USER_2FA_REQUEST,
        });

        const response = await dispatch(endpoints.login2fa.initiate({ userName }));

        if (response.error) {
            dispatch({
                type: actionTypes.LOGIN_USER_2FA_ERROR,
                message: response.error?.message,
            });

            return;
        }

        dispatch({
            type: actionTypes.LOGIN_USER_2FA_RESPONSE,
            data: response.data,
        });
    };

export const loginClearQrCode = () => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const user = {
        ...getState().user,
        qrCode: undefined,
    };

    saveUser(user);

    dispatch({
        type: actionTypes.USER_SAVE,
        user,
    });
};

export const logout = () => (dispatch, getState) => {
    const { accessToken, refreshToken } = getState().user;
    dispatch(endpoints.logout.initiate({ accessToken, refreshToken }));
    dispatch(resetApp());
};

export const updatePassword = (params) => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const { oldPassword, newPassword, authCode } = params;
    const { name: userName, accessToken } = getState().user;

    dispatch({
        type: actionTypes.API_PUT,
        url: availableResources.password,
        body: JSON.stringify({
            userName,
            oldPassword,
            newPassword,
            accessToken,
            authCode,
        }),
        actionTypes: {
            pending: actionTypes.UPDATE_PASSWORD_REQUEST,
            error: actionTypes.UPDATE_PASSWORD_ERROR,
        },
        onSuccess: (action) => {
            const { data } = action;
            const { responseStatus } = data;

            if (responseStatus === "loginfailed") {
                dispatch({
                    type: actionTypes.UPDATE_PASSWORD_ERROR,
                    message: data.responseMessage,
                });
            } else {
                dispatch({
                    type: actionTypes.UPDATE_PASSWORD_RESPONSE,
                });
                updateUser(action, userName, actionTypes.UPDATE_PASSWORD_ERROR, dispatch);
            }
        },
    });
};

export const forgotPassword = (params) => async (dispatch, getState) => {
    if (getState().login.isLoading) {
        return;
    }

    const { userName, recaptcha } = params;

    dispatch({
        type: actionTypes.API_POST,
        url: availableResources.password,
        body: JSON.stringify({
            userName: userName,
            recaptcha: recaptcha,
        }),
        actionTypes: {
            pending: actionTypes.FORGOT_PASSWORD_REQUEST,
            response: actionTypes.FORGOT_PASSWORD_RESPONSE,
            error: actionTypes.FORGOT_PASSWORD_ERROR,
        },
    });
};

export const resetPassword =
    ({ userNumber, temporaryPassword, newPassword, recaptcha, authCode }) =>
    async (dispatch, getState) => {
        if (getState().resetPassword.isLoading) {
            return;
        }

        dispatch({
            type: actionTypes.API_POST,
            url: getResourceUrl(availableResources.resetPassword, { userNumber }),
            body: JSON.stringify({ temporaryPassword, newPassword, recaptcha, authCode }),
            actionTypes: {
                pending: actionTypes.RESET_PASSWORD_REQUEST,
                error: actionTypes.RESET_PASSWORD_ERROR,
            },
            onSuccess: (action) => {
                const { data } = action;
                const { responseStatus } = data;

                if (responseStatus === "loginfailed") {
                    dispatch({
                        type: actionTypes.RESET_PASSWORD_ERROR,
                        message: data.responseMessage,
                    });
                } else {
                    dispatch({
                        type: actionTypes.RESET_PASSWORD_RESPONSE,
                    });

                    updateUser(action, getUserNameFromToken(data.accessToken), actionTypes.RESET_PASSWORD_ERROR, dispatch);

                    clearBrowserUrl();

                    dispatch(
                        windowRemove({
                            name: "Reset Password",
                            containerName: windowContainerTypes.Login,
                        })
                    );
                }
            },
        });
    };

export const resetLogin = () => (dispatch) => {
    dispatch({
        type: actionTypes.LOGIN_RESET,
    });

    dispatch(
        windowActivate({
            name: "Login",
            containerName: windowContainerTypes.Login,
        })
    );
};

export const resetApp = () => (dispatch) => {
    deleteWindowState();

    dispatch({
        type: actionTypes.USER_DELETE,
    });

    const { name, containerName } = getLoginWindowParams();

    dispatch(
        windowActivate({
            name,
            containerName,
        })
    );
};
