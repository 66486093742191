import cn from "classnames";

import { IdsText } from "@emergn-infinity/ids-react";

import { Avatar } from "components/Avatar";

export const ContactBlock: React.FC<{
    firstName: string;
    lastName: string;
    company?: string;
    direction?: "row" | "column";
    bold?: boolean;
}> = ({ firstName, lastName, company, direction = "row", bold }) => {
    return (
        <div
            className={cn({
                "flex-row align-center min-w-0": direction === "row",
                "flex-column": direction === "column",
            })}
        >
            <div>
                <Avatar name={firstName} size={40} firstNameInitial />
            </div>
            <div
                className={cn("flex-column fill-width", {
                    "min-w-0 px-2": direction === "row",
                    "py-2": direction === "column",
                })}
            >
                {company && (
                    <div className="flex-column fill-width no-overflow">
                        <IdsText size="md" style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                            <div className={cn({ "text-truncate": direction === "row" })} title={company}>
                                {company}
                            </div>
                        </IdsText>
                    </div>
                )}
                <IdsText weight={bold ? "bold" : undefined}>
                    <>{`${firstName} ${lastName}`}</>
                </IdsText>
            </div>
        </div>
    );
};
