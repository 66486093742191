import { isEmpty } from "lodash";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useCreateTrmLinkMutation, useDeleteTrmLinkMutation, useUpdateTrmLinkMutation } from "store/apiSlice";

import { IdsText, IdsButton } from "@emergn-infinity/ids-react";

import { NothingFoundBlock } from "components/NothingFoundBlock";

import { LinkCard } from "./LinkCard";
import { LinkEditCard } from "./LinkEditCard";
import { TrmDetailsDelete } from "./TrmDetailsDelete";

import { noLinksIcon, addIcon } from "utils/icons";

import { ApiResponse, TrmLink } from "types";

export const Links: React.FC<{
    trmNumber: string;
    trmFamiliarName: string;
    trmLinks: TrmLink[];
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
}> = ({ trmNumber, trmFamiliarName, trmLinks, isEditing, setIsEditing }) => {
    const [linkNumberForEdit, setLinkNumberForEdit] = useState("");
    const [linkForDelete, setLinkForDelete] = useState<TrmLink>();

    const [createTrmLink, createTrmLinkStatus] = useCreateTrmLinkMutation();
    const [updateTrmLink, updateTrmLinkStatus] = useUpdateTrmLinkMutation();
    const [deleteTrmLink, deleteTrmLinkStatus] = useDeleteTrmLinkMutation();

    const onCancel = () => {
        setIsEditing(false);
        setLinkNumberForEdit("");
    };

    const onSave = async (linkName: string, urlLink: string) => {
        // Do nothing if saving is in progress
        if (createTrmLinkStatus.isLoading || updateTrmLinkStatus.isLoading) {
            return;
        }

        try {
            let response: ApiResponse;

            const trmLinkModel = {
                linkName,
                urlLink,
                trmNumber,
            };

            if (linkNumberForEdit) {
                response = await updateTrmLink({ trmNumber, linkNumber: linkNumberForEdit, trmLink: trmLinkModel }).unwrap();
            } else {
                response = await createTrmLink({ trmLink: trmLinkModel }).unwrap();
            }

            if (response.responseStatus === "success") {
                setIsEditing(false);
                setLinkNumberForEdit("");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onEdit = (linkNumber: string) => {
        setIsEditing(true);
        setLinkNumberForEdit(linkNumber);
    };

    const onAddLink = () => {
        setIsEditing(true);
    };

    const onDelete = async (linkNumber: string) => {
        // Do nothing if deleting is in progress
        if (deleteTrmLinkStatus.isLoading) {
            return;
        }
        try {
            await deleteTrmLink({ trmNumber, linkNumber });

            setLinkForDelete(undefined);
        } catch (error) {
            console.error(error);
        }
    };

    if (linkForDelete) {
        return (
            <TrmDetailsDelete
                headerText="Delete Link"
                trmFamiliarName={trmFamiliarName}
                confirmationMessage="Are you sure you want to delete this link?"
                isLoading={deleteTrmLinkStatus.isLoading}
                onClose={() => setLinkForDelete(undefined)}
                onDelete={() => onDelete(linkForDelete.linkNumber)}
            >
                <LinkCard linkName={linkForDelete.linkName} urlLink={linkForDelete.urlLink} />
            </TrmDetailsDelete>
        );
    }

    return (
        <div className="flex-column fill-height">
            {isEmpty(trmLinks) ? (
                <NothingFoundBlock
                    title="No Links"
                    icon={noLinksIcon}
                    message="Added links will show here"
                    actionLabel="Add Link"
                    actionIcon={addIcon}
                    onActionClick={onAddLink}
                />
            ) : (
                <div className="flex-column gap-1">
                    <div className="flex-row align-self-end">
                        <IdsText>{trmLinks.length === 1 ? `${trmLinks.length} link` : `${trmLinks.length} links`}</IdsText>
                    </div>
                    <div className="flex-column gap-2">
                        {trmLinks.map((link) => (
                            <div key={link.linkNumber}>
                                {link.linkNumber === linkNumberForEdit ? (
                                    <LinkEditCard
                                        linkName={link.linkName}
                                        urlLink={link.urlLink}
                                        isLoading={createTrmLinkStatus.isLoading || updateTrmLinkStatus.isLoading}
                                        onCancel={onCancel}
                                        onSave={onSave}
                                    />
                                ) : (
                                    <LinkCard
                                        linkName={link.linkName}
                                        urlLink={link.urlLink}
                                        onDelete={!isEditing ? () => setLinkForDelete(link) : undefined}
                                        onEdit={!isEditing ? () => onEdit(link.linkNumber) : undefined}
                                    />
                                )}
                            </div>
                        ))}
                        {isEditing && !linkNumberForEdit && (
                            <LinkEditCard
                                isLoading={createTrmLinkStatus.isLoading || updateTrmLinkStatus.isLoading}
                                onCancel={onCancel}
                                onSave={onSave}
                            />
                        )}
                        {!isEditing && (
                            <div
                                className="rounded-edges-rounder"
                                style={{ border: "1px dashed var(--ids-semantic-border-color-neutral-faint)" }}
                            >
                                <IdsButton variant="tertiary" padding="lg" fullWidth clickHandler={onAddLink}>
                                    <div className="flex-row align-center gap-2">
                                        <FontAwesomeIcon icon={addIcon} size="lg" />
                                        Add Link
                                    </div>
                                </IdsButton>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
