import { isEmpty } from "lodash";

import { formatVariableText, formatVariableNumber } from "utils/string";

export const VariableText: React.FC<{
    text?: string;
    lookupValue?: string;
}> = ({ text, lookupValue }) => {
    let variable = text ?? "";

    const splitValue = variable.split(" = ");
    const formattedVariable = formatVariableText(splitValue[0]);

    if (!isEmpty(lookupValue)) {
        variable = `${formattedVariable} = ${lookupValue} ${splitValue[1]}`;
    } else {
        variable = `${formattedVariable} = ${splitValue[1]}`;
    }

    variable = formatVariableNumber(variable);

    return <div dangerouslySetInnerHTML={{ __html: variable }} />;
};
