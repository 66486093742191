import { memo, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { UPDATE_PASSWORD_ERROR, UPDATE_PASSWORD_ERROR_CLEAR } from "store/actionTypes";
import { resetLogin, updatePassword } from "store/login/actions";

import { IdsButton } from "@emergn-infinity/ids-react";

import IconLoading from "components/IconLoading";
import Input from "components/Input";
import FieldGroup from "components/FieldGroup";
import ErrorMsg from "components/StatusMsg/ErrorMsg";

import FormWrap from "pages/Login/FormWrap";

import "./style.scss";

const ERROR_PASSWORDS_DO_NOT_MATCH = "Passwords do not match";

const ChangePassword = memo(() => {
    const dispatch = useAppDispatch();

    // @ts-ignore
    const login = useAppSelector((state) => state.login);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeated, setNewPasswordRepeated] = useState("");
    const [authCode, setAuthCode] = useState("");

    const isErrorNewPassword = login.errorMessage === ERROR_PASSWORDS_DO_NOT_MATCH;

    const isValid = useCallback(() => {
        let isValid =
            oldPassword.trim().length > 0 &&
            newPassword.trim().length > 0 &&
            newPasswordRepeated.trim().length > 0 &&
            newPassword.trim() === newPasswordRepeated.trim();

        if (login.twoFactorQrCodeExists && login.twoFactorRequired) {
            isValid = isValid && authCode.trim().length > 0;
        }
        if (newPassword.trim().length > 0 && newPasswordRepeated.trim().length > 0 && newPassword.trim() !== newPasswordRepeated.trim()) {
            dispatch({
                type: UPDATE_PASSWORD_ERROR,
                message: ERROR_PASSWORDS_DO_NOT_MATCH,
            });
        }

        return isValid;
    }, [oldPassword, newPassword, newPasswordRepeated, authCode, login.twoFactorQrCodeExists, login.twoFactorRequired, dispatch]);

    const onChange = useCallback(
        (event, handler) => {
            dispatch({
                type: UPDATE_PASSWORD_ERROR_CLEAR,
            });

            handler(event.target.value);
        },
        [dispatch]
    );

    const onSubmit = useCallback(
        (event) => {
            event.preventDefault();

            if (isValid()) {
                dispatch(
                    updatePassword({
                        oldPassword,
                        newPassword,
                        newPasswordRepeated,
                        authCode,
                    })
                );

                dispatch(resetLogin());
            }
        },
        [oldPassword, newPassword, newPasswordRepeated, authCode, isValid, dispatch]
    );

    const onRedirect = useCallback(() => {
        // @ts-ignore
        dispatch(resetLogin());
    }, [dispatch]);

    return (
        <FormWrap>
            <form className="flex-column change-password-form" onSubmit={onSubmit}>
                {login.isLoading ? (
                    <div className="flex-column flex-one align-center justify-center">
                        <IconLoading />
                    </div>
                ) : (
                    <>
                        {login.errorMessage && (
                            <div className="change-password-form__error-message">
                                <ErrorMsg largeMsg message="The username/password combination you entered cannot be found" />
                            </div>
                        )}
                        <div className="text-center change-password-form__header">Change password</div>
                        <FieldGroup className="change-password-form__inputs">
                            <Input
                                label="Old password"
                                placeholder="Enter your old password"
                                required
                                value={oldPassword}
                                onChange={(event) => onChange(event, setOldPassword)}
                            />
                            <Input
                                label="New password"
                                placeholder="Enter your new password"
                                error={isErrorNewPassword}
                                required
                                value={newPassword}
                                onChange={(event) => onChange(event, setNewPassword)}
                            />
                            <Input
                                label="Confirm password"
                                type="password"
                                placeholder="Confirm your new password"
                                error={isErrorNewPassword}
                                required
                                value={newPasswordRepeated}
                                onChange={(event) => onChange(event, setNewPasswordRepeated)}
                            />
                            {login.twoFactorQrCodeExists && login.twoFactorRequired && (
                                <Input
                                    label="Authentication Code"
                                    placeholder="Enter authentication code"
                                    error={Boolean(login.errorMessage)}
                                    onChange={(event) => onChange(event, setAuthCode)}
                                />
                            )}
                        </FieldGroup>
                    </>
                )}
                <div className="flex-row align-center justify-space-between">
                    <span className="change-password-form__redirect" onClick={onRedirect}>
                        Return to login page
                    </span>
                    <IdsButton type="submit" variant="primary" isDisabled={login.isLoading || !isValid()} isLoading={login.isLoading}>
                        <>{login.isLoading ? "Loading..." : "Change password"}</>
                    </IdsButton>
                </div>
            </form>
        </FormWrap>
    );
});

export default ChangePassword;
