import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IdsText, IdsButton } from "@emergn-infinity/ids-react";

import { caretDownIcon } from "utils/icons";

export const MenuButton: React.FC<{
    active?: boolean;
    labelValue: string;
    label?: string;
    isOpen: boolean;
    onClick: () => void;
}> = ({ active, labelValue, label, isOpen, onClick }) => {
    return (
        <div className="flex-row align-center gap-2">
            {label && (
                <IdsText customClasses="menu-heading" size="md" weight="bold">
                    {label}
                </IdsText>
            )}
            <IdsButton
                customClasses={cn("menu-btn", { "menu-btn--active": isOpen })}
                color={active ? "system-success" : "neutral"}
                clickHandler={onClick}
            >
                <div className="flex-row align-center gap-2">
                    {labelValue}
                    <FontAwesomeIcon icon={caretDownIcon} />
                </div>
            </IdsButton>
        </div>
    );
};
