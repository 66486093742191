import { isEmpty, isNaN, map } from "lodash";
import { useState } from "react";
import cn from "classnames";

import { useUpdatePublisherDetailsMutation, useUpdateTrmDetailsMutation } from "store/apiSlice";

import { IdsModal, IdsText, IdsTabs, IdsTabItem, IdsTabPanel, IdsButtonGroup, IdsButton } from "@emergn-infinity/ids-react";

import { TrmInformation } from "./TrmInformation";
import { Links } from "./Links";
import { Contacts } from "./Contacts";

import { TrmEditTabs } from "pages/ExploreTrms/utils";

import type { TrmEditTab } from "./TrmDetailsTile";
import type { Contact, PublisherDetails, PublisherDetailsUpdateModel, TrmDetails, TrmDetailsUpdateModel, TrmLink } from "types";

const TRM_MODAL_STYLE = {
    width: "452px",
    maxWidth: "100%",
    height: "100%",
};

export const TrmEdit: React.FC<{
    trmDetails: TrmDetails;
    publisherDetails: PublisherDetails;
    trmLinks: TrmLink[];
    contacts: Contact[];
    tab: TrmEditTab;
    onClose: () => void;
}> = ({ trmDetails, publisherDetails, trmLinks, contacts, tab, onClose }) => {
    const [activeTab, setActiveTab] = useState(tab);
    const [isEditing, setIsEditing] = useState(false);

    const [updateTrmDetails, updateTrmDetailsStatus] = useUpdateTrmDetailsMutation();
    const [updatePublisherDetails, updatePublisherDetailsStatus] = useUpdatePublisherDetailsMutation();

    const onTabChange = (id: TrmEditTab) => {
        setActiveTab(id);
        setIsEditing(false);
    };

    const onSave = async () => {
        // Do nothing if saving is in progress
        if (updateTrmDetailsStatus.isLoading || updatePublisherDetailsStatus.isLoading) {
            return;
        }

        // NOTE: Currently works, but in the future if TRM Information tab's order
        // is changed, getElementById will return the wrong element
        // because for some reason <IdsTabs> renders the same content
        // for as many times as there are tabs
        const publisherName = (document.getElementById("publisher-name") as HTMLInputElement)?.value;
        const publisherURL = (document.getElementById("publisher-link") as HTMLInputElement)?.value;
        const preparedBy = (document.getElementById("authored-by") as HTMLInputElement)?.value;
        const applicableYear = Number((document.getElementById("applicable-year") as HTMLInputElement)?.value);
        const yearPrepared = Number((document.getElementById("year-authored") as HTMLInputElement)?.value);
        const defSummerPeak = (document.getElementById("summer-def") as HTMLInputElement)?.value;
        const defWinterPeak = (document.getElementById("winter-def") as HTMLInputElement)?.value;

        try {
            let publisherUpdateModel: PublisherDetailsUpdateModel | undefined;
            let trmUpdateModel: TrmDetailsUpdateModel | undefined;

            // Publisher name or URL must be a truthy value
            // in order to update publisher details
            if (
                (publisherName && publisherName !== publisherDetails.publisherName) ||
                (publisherURL && publisherURL !== publisherDetails.publisherURL)
            ) {
                publisherUpdateModel = {
                    publisherName,
                    jurisdictionLvl: publisherDetails.jurisdictionLvl,
                    publisherURL,
                };
            }

            // Authored by, applicable year, year prepared, summer peak definition or
            // winter peak definition must be a truthy value in order
            // to update TRM details
            if (
                (preparedBy && preparedBy !== trmDetails.preparedBy) ||
                (!isNaN(applicableYear) && applicableYear !== trmDetails.applicableYear) ||
                (!isNaN(yearPrepared) && yearPrepared !== trmDetails.yearPrepared) ||
                (defSummerPeak && defSummerPeak !== trmDetails.defSummerPeak) ||
                (defWinterPeak && defWinterPeak !== trmDetails.defWinterPeak)
            ) {
                trmUpdateModel = {
                    trmFamiliarName: trmDetails.trmFamiliarName,
                    trmName: trmDetails.trmName,
                    active: trmDetails.active,
                    availability: trmDetails.availability,
                    format: trmDetails.format,
                    preparedBy,
                    applicableYear: !isNaN(applicableYear) ? applicableYear : (trmDetails.applicableYear ?? undefined),
                    yearPrepared: !isNaN(yearPrepared) ? yearPrepared : trmDetails.yearPrepared,
                    defSummerPeak,
                    defWinterPeak,
                    publisherNumber: trmDetails.publisherNumber,
                };
            }

            // Update publisher details only when values have been changed
            if (publisherUpdateModel) {
                await updatePublisherDetails({
                    publisherNumber: trmDetails.publisherNumber,
                    publisherDetails: publisherUpdateModel,
                });
            }

            // Update TRM details only when values have been changed
            if (trmUpdateModel) {
                await updateTrmDetails({
                    trmNumber: trmDetails.trmNumber,
                    trmDetails: trmUpdateModel,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <IdsModal
            version={2}
            isOpen
            closeHandler={onClose}
            showCloseButton
            customClasses={cn("slideout", {
                // Set one of the child <div> element's height to 100%
                // to display empty state for the full height
                "empty-state":
                    (activeTab === TrmEditTabs.Links.id && isEmpty(trmLinks)) ||
                    (activeTab === TrmEditTabs.Contacts.id && isEmpty(contacts)),
            })}
        >
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Edit TRM Details
                </IdsText>
            </div>
            <div slot="main" style={TRM_MODAL_STYLE}>
                <IdsTabs customClasses="fill-height">
                    {map(TrmEditTabs, (tab) => (
                        <IdsTabItem
                            key={`tab-header-${tab.id}`}
                            slot="header"
                            idValue={tab.id}
                            label={tab.name}
                            isActive={activeTab === tab.id}
                            onClick={() => onTabChange(tab.id)}
                        />
                    ))}

                    {map(TrmEditTabs, (tab) => (
                        <IdsTabPanel
                            key={`tab-panel-${tab.id}`}
                            customClasses="fill-height pt-3 pb-4"
                            slot="panel"
                            idValue={tab.id}
                            // Horizontal paddings fix outline being out of bounds
                            style={{
                                backgroundColor: "inherit",
                                paddingRight: 3,
                                paddingLeft: 3,
                            }}
                        >
                            {activeTab === TrmEditTabs.TrmInformation.id && (
                                <TrmInformation trmDetails={trmDetails} publisherDetails={publisherDetails} />
                            )}
                            {activeTab === TrmEditTabs.Links.id && (
                                <Links
                                    trmNumber={trmDetails.trmNumber}
                                    trmFamiliarName={trmDetails.trmFamiliarName}
                                    trmLinks={trmLinks}
                                    isEditing={isEditing}
                                    setIsEditing={setIsEditing}
                                />
                            )}
                            {activeTab === TrmEditTabs.Contacts.id && (
                                <Contacts
                                    trmFamiliarName={trmDetails.trmFamiliarName}
                                    publisherNumber={trmDetails.publisherNumber}
                                    contacts={contacts}
                                    isEditing={isEditing}
                                    setIsEditing={setIsEditing}
                                />
                            )}
                        </IdsTabPanel>
                    ))}
                </IdsTabs>
            </div>
            <div slot="footer">
                {!isEditing && (
                    <IdsButtonGroup spaceBetween="md">
                        <IdsButton variant="secondary" clickHandler={onClose}>
                            Cancel
                        </IdsButton>
                        <IdsButton variant="primary" clickHandler={onSave}>
                            <>
                                {updateTrmDetailsStatus.isLoading || updatePublisherDetailsStatus.isLoading
                                    ? "Saving..."
                                    : "Save TRM Details"}
                            </>
                        </IdsButton>
                    </IdsButtonGroup>
                )}
            </div>
        </IdsModal>
    );
};
