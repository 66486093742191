import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useGetFuelTypesByMeasureQuery, useGetSectorsByMeasureQuery, useGetVintagesByMeasureQuery } from "store/apiSlice";

import { useAppDispatch } from "store/hooks";

import { selectorChange } from "pages/BenchmarkTrm/benchmarkTrmSlice";

import { IdsDropdown, IdsFieldWrapper, IdsText } from "@emergn-infinity/ids-react";

export const GridFilter: React.FC<{
    selectedMeasure: string;
    selectedSector?: string;
    selectedVintage?: string;
    selectedFuelType?: string;
    isSavingsCalculationsBenchmark?: boolean;
}> = ({ selectedMeasure, selectedSector, selectedVintage, selectedFuelType, isSavingsCalculationsBenchmark = false }) => {
    const dispatch = useAppDispatch();

    const { data: sectors, isLoading: isLoadingSectors } = useGetSectorsByMeasureQuery(
        { measureNumber: selectedMeasure },
        { skip: isEmpty(selectedMeasure) },
    );
    const sectorList = useMemo(() => {
        return (sectors ?? []).map((sector) => ({
            value: sector.sectorNumber,
            label: sector.sectorName,
        }));
    }, [sectors]);

    const { data: vintages, isLoading: isLoadingVintages } = useGetVintagesByMeasureQuery(
        { measureNumber: selectedMeasure },
        { skip: isEmpty(selectedMeasure) },
    );
    const vintageList = useMemo(() => {
        return (vintages ?? []).map((vintage) => ({
            value: vintage.vintageNumber,
            label: vintage.vintageName,
        }));
    }, [vintages]);

    const { data: fuelTypes, isLoading: isLoadingFuelTypes } = useGetFuelTypesByMeasureQuery(
        { measureNumber: selectedMeasure },
        { skip: isEmpty(selectedMeasure) || !isSavingsCalculationsBenchmark },
    );
    const fuelTypeList = useMemo(() => {
        return (fuelTypes ?? []).map((ft) => ({
            value: ft.fuelTypeNumber,
            label: ft.fuelType,
        }));
    }, [fuelTypes]);

    return (
        <div className="flex-row py-3 gap-2 align-center">
            <IdsText>Filter by:</IdsText>
            <IdsFieldWrapper
                key={`sector-${selectedMeasure}`}
                htmlFor="select-sector"
                isDisabled={isEmpty(selectedMeasure)}
                isInvalid={isSavingsCalculationsBenchmark && isEmpty(selectedSector) && !isEmpty(selectedMeasure)}
                clearMargin
            >
                <IdsDropdown
                    idValue="select-sector"
                    isSearchable={!isLoadingSectors} // A hack to properly preselect the dropdown value
                    items={sectorList}
                    initialSelectedItems={selectedSector ? [selectedSector] : []}
                    placeholder={isLoadingSectors ? "Loading" : "TRM Sector"}
                    changeHandler={(value) => dispatch(selectorChange({ selector: "sector", value }))}
                    clearHandler={() => dispatch(selectorChange({ selector: "sector", value: "" }))}
                />
            </IdsFieldWrapper>
            <IdsFieldWrapper
                key={`vintage-${selectedMeasure}`}
                htmlFor="select-vintage"
                isDisabled={isEmpty(selectedMeasure)}
                isInvalid={isSavingsCalculationsBenchmark && isEmpty(selectedVintage) && !isEmpty(selectedMeasure)}
                clearMargin
            >
                <IdsDropdown
                    idValue="select-vintage"
                    isSearchable={!isLoadingVintages} // A hack to properly preselect the dropdown value
                    items={vintageList}
                    initialSelectedItems={selectedVintage ? [selectedVintage] : []}
                    placeholder={isLoadingVintages ? "Loading" : "TRM Vintage"}
                    changeHandler={(value) => dispatch(selectorChange({ selector: "vintage", value }))}
                    clearHandler={() => dispatch(selectorChange({ selector: "vintage", value: "" }))}
                />
            </IdsFieldWrapper>
            {isSavingsCalculationsBenchmark && (
                <IdsFieldWrapper
                    key={`fuel-type-${selectedMeasure}`}
                    htmlFor="select-fuel-type"
                    isDisabled={isEmpty(selectedMeasure)}
                    isInvalid={isEmpty(selectedFuelType) && !isEmpty(selectedMeasure)}
                    clearMargin
                >
                    <IdsDropdown
                        idValue="select-fuel-type"
                        isSearchable={!isLoadingFuelTypes} // A hack to properly preselect the dropdown value
                        items={fuelTypeList}
                        initialSelectedItems={selectedFuelType ? [selectedFuelType] : []}
                        placeholder={isLoadingFuelTypes ? "Loading" : "TRM Fuel Type"}
                        changeHandler={(value) => dispatch(selectorChange({ selector: "fuelType", value }))}
                        clearHandler={() => dispatch(selectorChange({ selector: "fuelType", value: "" }))}
                    />
                </IdsFieldWrapper>
            )}
        </div>
    );
};
