import { IdsText } from "@emergn-infinity/ids-react";

export const MeasureDescription: React.FC<{
    measureDescription?: string;
}> = ({ measureDescription }) => {
    return (
        <>
            <IdsText weight="bold">Highlighted Measure Description:</IdsText>
            {measureDescription && <div>{measureDescription}</div>}
        </>
    );
};
