import type { Placement } from "@popperjs/core";
import { camelCase } from "lodash";
import { usePopper as useReactPopper } from "react-popper";

import { popperType, dropdownPopperOptions, tooltipPopperOptions } from "./utils";

export const usePopper = (props: UsePopperParams) => {
    let options: any = {};
    let placement = "";

    if (props.tooltipPlacement) {
        placement = camelCase(props.tooltipPlacement);
    }

    switch (props.popperType) {
        case popperType.dropdown:
            options = dropdownPopperOptions;

            break;
        case popperType.tooltip:
            options = tooltipPopperOptions[placement];

            if (props.arrowElement) {
                options = {
                    ...options,
                    modifiers: [
                        ...options.modifiers,
                        {
                            name: "arrow",
                            options: {
                                element: props.arrowElement,
                            },
                        },
                    ],
                };
            }

            break;
        default:
    }

    return useReactPopper(props.referenceElement, props.popperElement, options);
};

interface UsePopperParams {
    /**
     * Reference element for React Popper.
     */
    referenceElement?: Element | null;

    /**
     * Popper element for React Popper.
     */
    popperElement?: HTMLElement | null;

    /**
     * Arrow element for React Popper.
     */
    arrowElement?: HTMLElement | null;

    /**
     * Element for what Popper is used for.
     */
    popperType?: "dropdown" | "tooltip";

    /**
     * Tooltip placement related to the element.
     */
    tooltipPlacement?: Placement;
}
