import { isEmpty } from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QRCodeSVG } from "qrcode.react";

import { useDeleteQrCodeMutation, useSetupAuthenticatorMutation } from "store/apiSlice";
import { loginClearQrCode, logout } from "store/login/actions";
import { windowActivate } from "store/window/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { IdsButton } from "@emergn-infinity/ids-react";

import IconLoading from "components/IconLoading";
import Input from "components/Input";
import ErrorMsg from "components/StatusMsg/ErrorMsg";

import FormWrap from "pages/Login/FormWrap";

import { arrowRightIcon } from "utils/icons";
import { getLoginWindowParams } from "utils/window";

import "./style.scss";

const QrCode = memo(() => {
    const dispatch = useAppDispatch();

    const user = useAppSelector((state) => state.user);

    const [authCode, setAuthCode] = useState("");
    const [isError, setIsError] = useState(false);

    const isAuthCodeValid = authCode.trim().length > 0;

    const [setupAuthenticator] = useSetupAuthenticatorMutation();
    const [deleteQrCode] = useDeleteQrCodeMutation();

    const qrCode = useMemo(() => {
        let qrCode = "";

        if (user) {
            qrCode = `otpauth://totp/VisionDSM:${user.userName}?secret=${user.qrCode}&issuer=AEG`;
        }

        return qrCode;
    }, [user]);

    const onAuthCodeChange = useCallback((event) => {
        setAuthCode(event.target.value);
    }, []);

    const onActivateLogin = useCallback(() => {
        const { name, containerName } = getLoginWindowParams();

        dispatch(
            windowActivate({
                name,
                containerName,
            })
        );
    }, [dispatch]);

    const onCreateAuthCodeError = useCallback(() => {
        setIsError(true);
    }, []);

    const onCreateAuthCodeSuccess = useCallback(() => {
        dispatch(loginClearQrCode());
        onActivateLogin();
    }, [onActivateLogin, dispatch]);

    const onSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            try {
                await setupAuthenticator({ authCode }).unwrap();
                onCreateAuthCodeSuccess();
            } catch (error) {
                onCreateAuthCodeError();
            }
        },
        [authCode, onCreateAuthCodeError, onCreateAuthCodeSuccess, setupAuthenticator]
    );

    const onRedirect = useCallback(() => {
        try {
            if (user?.userNumber) {
                deleteQrCode({ userNumber: user?.userNumber }).unwrap();
            }

            onActivateLogin();
            dispatch(logout());
        } catch (error) {
            console.error(error);
        }
    }, [user?.userNumber, dispatch, onActivateLogin, deleteQrCode]);

    return (
        <FormWrap>
            <form className="flex-column qr-code-form" onSubmit={onSubmit}>
                {isEmpty(user) ? (
                    <div className="flex-column flex-one align-center justify-center">
                        <IconLoading />
                    </div>
                ) : (
                    <>
                        {isError && (
                            <div className="qr-code-form__error-message">
                                <ErrorMsg largeMsg message="Invalid Authentication Code" />
                            </div>
                        )}
                        <div className="text-center qr-code-form__header">Set up Authenticator</div>
                        <div className="flex-row align-center qr-code-form__two-fa-setup-steps">
                            <ul>
                                <li>
                                    Get the <b>Google Authenticator</b> or <b>Microsoft Authenticator</b> app from your application store
                                </li>
                                <li>
                                    Once the app is installed, select <b>Set Up Account</b> in the app
                                </li>
                                <li>
                                    Choose <b>Scan barcode</b>
                                </li>
                                <li>
                                    <div className="flex-row align-center">
                                        <span>Scan the barcode</span>
                                        <FontAwesomeIcon className="pl-2" icon={arrowRightIcon} />
                                    </div>
                                    {`to configure the authentication code you will use to log into the TRMulator system`}
                                </li>
                            </ul>
                            <div className="qr-code-form__qr-code-wrap">
                                <QRCodeSVG className="qr-code" value={qrCode} size={248} />
                            </div>
                        </div>
                        <div className="qr-code-form__inputs">
                            <Input
                                label="Authentication Code"
                                placeholder="Enter authentication code"
                                value={authCode}
                                onChange={onAuthCodeChange}
                            />
                        </div>
                        <div className="flex-row align-center justify-space-between">
                            <span className="qr-code-form__redirect" onClick={onRedirect}>
                                Return to login page
                            </span>
                            <IdsButton type="submit" variant="primary" isDisabled={!isAuthCodeValid}>
                                Continue
                            </IdsButton>
                        </div>
                    </>
                )}
            </form>
        </FormWrap>
    );
});

export default QrCode;
