import { syncSessionStorage } from "utils/store";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { disableBrowserNavigation } from "utils/dom";

import "assets/fontawesome/js/regular";
import "assets/fontawesome/js/solid";
import "assets/fontawesome/js/brands";
import "assets/fontawesome/js/light";
import "assets/fontawesome/js/fontawesome";

import "./style.scss";

syncSessionStorage();

/**
 * Delay the initialization of the app to allow the sessionStorage to be synced.
 * Otherwise the login screen is shown before the sessionStorage is synced.
 * Not happy with this solution, but it works for now.
 */
setTimeout(() => {
    disableBrowserNavigation();

    localStorage.setItem("ids_theme_name", "aeg");

    createRoot(document.getElementById("root")!).render(
        <StrictMode>
            <App />
        </StrictMode>,
    );
}, 100);
