import { isEmpty } from "lodash";
import { memo, useCallback, useState } from "react";

import { useAppDispatch } from "store/hooks";

import { loginUser2fa as onLogin2FA } from "store/login/actions";

import { IdsButton } from "@emergn-infinity/ids-react";

import IconLoading from "components/IconLoading";
import Input from "components/Input";
import ErrorMsg from "components/StatusMsg/ErrorMsg";
import FieldGroup from "components/FieldGroup";

import FormWrap from "pages/Login/FormWrap";

import { useAutoFillDetect } from "utils/useAutoFillDetect";

import { LoginFormProps } from "./types";

import "./style.scss";

const inputIds = ["username", "password"];
const userNameRegex = /^[a-zA-Z0-9]+$/;

const LoginForm = memo(
    ({
        isLoading,
        errorMessage,
        twoFactorPending,
        twoFactorRequired,
        twoFactorQrCodeExists,
        onSubmit,
        onForgotPassword,
    }: LoginFormProps) => {
        const dispatch = useAppDispatch();

        const [username, setUsername] = useState("");
        const [password, setPassword] = useState("");
        const [authCode, setAuthCode] = useState("");

        const [twoFactorCheck, setTwoFactorCheck] = useState(false);

        const [isAutoFilled] = useAutoFillDetect(inputIds);

        const isUsernameValid = username.trim().length > 0 && userNameRegex.test(username);
        const isPasswordValid = password.trim().length > 0;
        const isAuthCodeValid = twoFactorRequired && twoFactorQrCodeExists ? authCode.trim().length > 0 : true;

        let signInDisabled = true;

        // Input fields on WebKit browsers are empty on autofill
        if (isAutoFilled && isEmpty(username) && isEmpty(password)) {
            signInDisabled = false;
        } else if (!isLoading && !twoFactorPending && isUsernameValid && isPasswordValid && isAuthCodeValid) {
            signInDisabled = false;
        }

        const onChange = useCallback((event, name) => {
            switch (name) {
                case "username":
                    setUsername(event.target.value);
                    setTwoFactorCheck(false);

                    break;

                case "password":
                    setPassword(event.target.value);

                    break;

                case "authCode":
                    setAuthCode(event.target.value);

                    break;

                default:
            }
        }, []);

        const performTwoFactorCheck = useCallback(() => {
            if (!twoFactorCheck && isUsernameValid) {
                // @ts-ignore
                dispatch(onLogin2FA({ userName: username }));

                setTwoFactorCheck(true);
            }
        }, [username, twoFactorCheck, isUsernameValid, dispatch]);

        const handleForgotPassword = useCallback(() => {
            onForgotPassword(username);
        }, [username, onForgotPassword]);

        const handleSubmit = useCallback(
            (event) => {
                event.preventDefault();

                onSubmit({ userName: username, password, authCode });
            },
            [username, password, authCode, onSubmit],
        );

        return (
            <FormWrap>
                <form className="flex-column login-form" onSubmit={handleSubmit}>
                    {isLoading ? (
                        <div className="flex-column flex-one align-center justify-center">
                            <IconLoading />
                        </div>
                    ) : (
                        <>
                            {errorMessage && (
                                <div className="login-form__error-message">
                                    <ErrorMsg largeMsg message={errorMessage} />
                                </div>
                            )}
                            <div className="text-center login-form__header">Sign in to your account</div>
                            <FieldGroup className="login-form__inputs">
                                <Input
                                    label="Username"
                                    id={inputIds[0]}
                                    name="userName"
                                    placeholder="Enter your username"
                                    value={username}
                                    autoFocus={!twoFactorCheck}
                                    onChange={(event) => onChange(event, "username")}
                                    onBlur={performTwoFactorCheck}
                                />
                                <Input
                                    label="Password"
                                    id={inputIds[1]}
                                    type="password"
                                    placeholder="Enter your password"
                                    autoFocus={twoFactorCheck}
                                    value={password}
                                    onChange={(event) => onChange(event, "password")}
                                />
                                {twoFactorRequired && twoFactorQrCodeExists && (
                                    <Input
                                        label="Authentication Code"
                                        placeholder="Enter authentication code"
                                        value={authCode}
                                        onChange={(event) => onChange(event, "authCode")}
                                    />
                                )}
                            </FieldGroup>
                        </>
                    )}
                    <div className="flex-row align-center justify-space-between">
                        <span className="login-form__forgot-password" onClick={handleForgotPassword}>
                            Forgot password?
                        </span>
                        <div>
                            <IdsButton
                                type="submit"
                                variant="primary"
                                isDisabled={signInDisabled}
                                isLoading={isLoading || twoFactorPending}
                            >
                                <>{isLoading || twoFactorPending ? "Loading..." : "Sign In"}</>
                            </IdsButton>
                        </div>
                    </div>
                </form>
            </FormWrap>
        );
    },
);

export default LoginForm;
