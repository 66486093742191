import { useMemo } from "react";

import { useAppSelector } from "store/hooks";

import { UseViewsParams } from "./types";

export const useViews = ({ containerName }: UseViewsParams) => {
    const viewsContainer = useAppSelector((state) => state.window[containerName]);

    return useMemo(() => viewsContainer?.views || [], [viewsContainer]);
};
