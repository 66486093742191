import { IdsTable, IdsTableRow, IdsTableCell, IdsText, IdsIcon } from "@emergn-infinity/ids-react";

import { TrmAttributes } from "pages/StandardizeData/utils";

import { TrmAttributeType } from "pages/StandardizeData";

export const Sidebar: React.FC<{
    attribute: TrmAttributeType;
    onClick: (id: TrmAttributeType) => void;
}> = ({ attribute, onClick }) => {
    return (
        <IdsTable spacing="lg">
            {Object.keys(TrmAttributes).map((attr) => (
                <IdsTableRow key={`${TrmAttributes[attr].id}`}>
                    <IdsTableCell
                        style={{
                            backgroundColor:
                                TrmAttributes[attr].id === attribute
                                    ? "var(--ids-table-cell-body-background-color-clear-hover)"
                                    : undefined,
                        }}
                        onClick={() => onClick(TrmAttributes[attr].id)}
                    >
                        <div className="flex-row align-center justify-space-between">
                            <IdsText weight={TrmAttributes[attr].id === attribute ? "bold" : "regular"}>
                                {TrmAttributes[attr].fullName}
                            </IdsText>
                            {TrmAttributes[attr].id === attribute && <IdsIcon icon="ui-navigation-angle_right" size="sm" />}
                        </div>
                    </IdsTableCell>
                </IdsTableRow>
            ))}
        </IdsTable>
    );
};
